/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from 'graphql-request';
import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import { Subgraph } from 'src/services/subgraph';
import { PositionID, VaultID } from 'src/state/contracts';

const UnderwriterPositionsInVaultForUser = gql`
  query UnderwriterPositionsInVaultForUser($vaultId: ID!, $userId: String) {
    vault(id: $vaultId) {
      underwriterPositions(where: { user: $userId }) {
        positionID
      }
    }
  }
`;

const PolicyholderPositionsInVaultForUser = gql`
  query PolicyholderPositionsInVaultForUser($vaultId: ID!, $userId: String) {
    vault(id: $vaultId) {
      pools {
        policyHolderPositions(where: { user: $userId }) {
          positionID
        }
      }
    }
  }
`;

export class PositionService {
  private constructor(private readonly subgraph: Subgraph) {}

  static fromNetwork(network: Network) {
    return new PositionService(Subgraph.fromNetwork(network));
  }

  async findUnderwriterPositionsForUserInVault(
    user: Address,
    vault: Address
  ): Promise<PositionID[]> {
    const result = await this.subgraph.query(
      UnderwriterPositionsInVaultForUser,
      {
        vaultId: vault.toLowercaseString(),
        userId: user.toLowercaseString(),
      }
    );

    return (
      (result as any)?.vault?.underwriterPositions?.map?.(
        (position: any) => position?.positionID
      ) || []
    );
  }

  async findUnderwriterPositionsForUserInVaultBatch(
    user: Address,
    vaultIds: VaultID[]
  ): Promise<{ [vaultId: VaultID]: PositionID[] }> {
    const positionsForVault = await Promise.all(
      vaultIds.map((id) =>
        this.findUnderwriterPositionsForUserInVault(
          user,
          Address.fromString(id)
        )
      )
    );

    return vaultIds.reduce(
      (acc, id, i) => ({
        ...acc,
        [id]: positionsForVault[i],
      }),
      {} as ReturnType<typeof this.findUnderwriterPositionsForUserInVaultBatch>
    );
  }

  async findPolicyholderPositionsForUserInVault(
    user: Address,
    vault: Address
  ): Promise<PositionID[]> {
    const result = await this.subgraph.query(
      PolicyholderPositionsInVaultForUser,
      {
        vaultId: vault.toLowercaseString(),
        userId: user.toLowercaseString(),
      }
    );

    return (
      (result as any)?.vault?.pools?.flatMap?.((pool: any) =>
        pool.policyHolderPositions?.map?.(
          (position: any) => position?.positionID
        )
      ) || []
    );
  }

  async findPolicyholderPositionsForUserInVaultBatch(
    user: Address,
    vaultIds: VaultID[]
  ): Promise<{ [vaultId: VaultID]: PositionID[] }> {
    const positionsForVault = await Promise.all(
      vaultIds.map((id) =>
        this.findPolicyholderPositionsForUserInVault(
          user,
          Address.fromString(id)
        )
      )
    );

    return vaultIds.reduce(
      (acc, id, i) => ({
        ...acc,
        [id]: positionsForVault[i],
      }),
      {} as ReturnType<typeof this.findPolicyholderPositionsForUserInVaultBatch>
    );
  }
}
