export enum WalletType {
  metamask = 'metamask',
  coinbase = 'coinbase',
  walletconnect = 'walletconnect',
}

type WalletMetadata = {
  type: WalletType;
  displayName: string;
  iconSrc: string;
};

const Metamask: WalletMetadata = {
  displayName: 'Metamask',
  type: WalletType.metamask,
  iconSrc: '/wallet-images/metamask.png',
};

const Coinbase: WalletMetadata = {
  displayName: 'Coinbase Wallet',
  type: WalletType.coinbase,
  iconSrc: '/wallet-images/coinbase.svg',
};

const WalletConnect: WalletMetadata = {
  displayName: 'WalletConnect',
  type: WalletType.walletconnect,
  iconSrc: '/wallet-images/wallet-connect.svg',
};

export const SupportedWallets: WalletMetadata[] = [
  Metamask,
  Coinbase,
  WalletConnect,
];

export const SupportedWalletsMap: Record<WalletType, WalletMetadata> = {
  [WalletType.metamask]: Metamask,
  [WalletType.coinbase]: Coinbase,
  [WalletType.walletconnect]: WalletConnect,
};
