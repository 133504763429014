import {
  request,
  batchRequests,
  Variables,
  BatchRequestDocument,
} from 'graphql-request';
import { Network } from 'src/models/Network';

export class Subgraph {
  private constructor(
    private readonly url: string,
    public readonly network: Network
  ) {}

  static fromNetwork(network: Network): Subgraph {
    return new Subgraph(network.config.urlMap.subgraph, network);
  }

  async query<T, V extends Variables = Variables>(
    gqlQuery: string,
    variables: V
  ): Promise<T> {
    return request<T, V>(this.url, gqlQuery, variables);
  }

  async batchQuery<T, V extends Variables = Variables>(
    batchInput: BatchRequestDocument<V>[]
  ): Promise<T> {
    return batchRequests<T, V>(this.url, batchInput);
  }
}
