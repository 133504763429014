import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Progress: {
      baseStyle: {
        track: {
          bg: '#d2d2d250',
        },
        filledTrack: {
          bg: '#ffffff',
        },
      },
    },
  },
});
