/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from 'graphql-request';
import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { Subgraph } from 'src/services/subgraph';
import { PoolID } from 'src/state/contracts';

export type PoolSubgraphResult = {
  id: PoolID;
  vault: Address;
  protectedToken: Address;
  underwritingToken: Address;
  hacked: boolean;
  vaultExpiry: UnixTimestamp;
  tokenVersion: number;
};

const Pools = gql`
  query Pools {
    pools {
      id
      hackedTimestamp
      protectedToken {
        id
      }
      vault {
        id
        expireTimestamp
        underwritingToken {
          id
          tokenVersion
        }
      }
    }
  }
`;

export class PoolService {
  private constructor(private readonly subgraph: Subgraph) {}

  static fromNetwork(network: Network) {
    return new PoolService(Subgraph.fromNetwork(network));
  }

  async getPools(): Promise<PoolSubgraphResult[]> {
    const result = await this.subgraph.query(Pools, {});

    return (
      (result as any)?.pools.map(
        (pool: any) =>
          ({
            id: pool.id.split('-')[2],
            vault: Address.fromString(pool.vault.id),
            protectedToken: Address.fromString(pool.protectedToken.id),
            underwritingToken: Address.fromString(
              pool.vault.underwritingToken.id
            ),
            hacked: !!pool.hackedTimestamp,
            tokenVersion: pool.vault.underwritingToken.tokenVersion,
            vaultExpiry: UnixTimestamp.fromSeconds(pool.vault.expireTimestamp),
          } as PoolSubgraphResult)
      ) ?? []
    );
  }
}
