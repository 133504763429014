/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable camelcase */
import { BigNumberish, ethers } from 'ethers';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  Provider as MulticallProvider,
  Contract,
  ContractCall,
} from 'rh-ethers-multicall';
import { Network, NetworkID } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { Wallet } from 'src/models/Wallet';
import { startTokenSync, startVaultSync } from 'src/state/actions';
import {
  contractsState,
  Pool,
  PoolID,
  Position,
  PositionID,
  Storage,
  Token,
  TokenID,
  Vault,
  VaultID,
} from 'src/state/contracts';
import { RHVault__factory } from 'src/types/contracts/factories/RHVault__factory';
import { RHVault } from 'src/types/contracts/RHVault';
import { Optional } from 'src/types/Optional';
import { ABI } from 'src/abis';
import { resolveNetworkFromName } from 'src/utils/network';
import { logger } from 'src/utils/logger';
import { MiscRHError } from 'src/errors';
import { walletState } from 'src/state/wallet';
import { subgraphState } from 'src/state/subgraph';

function* getWallet() {
  const wallet: Optional<Wallet> = yield select((state) => state.wallet.value);
  return wallet;
}

function* getProvider(addressNetwork: Network) {
  // const wallet: Optional<Wallet> = yield call(getWallet);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const network: Network = yield select((state) => state.network.value);

  const provider: ethers.providers.Provider = // network.id === addressNetwork.id && wallet
    //   ? wallet.provider
    // : addressNetwork.defaultProvider
    addressNetwork.defaultProvider as ethers.providers.Provider;

  return provider;
}
function* getMulticallProvider(network: Network) {
  const provider: ethers.providers.Provider = yield call(getProvider, network);
  const multicallProvider: MulticallProvider = new MulticallProvider(
    provider,
    network.chainId
  );

  return multicallProvider;
}

function* getRHVaultContract(vaultAddress: NetworkAddress) {
  const provider: ethers.providers.Provider = yield call(
    getProvider,
    vaultAddress.network
  );
  return RHVault__factory.connect(vaultAddress.address.toString(), provider);
}

// function* getERC20Contract(tokenAddress: NetworkAddress) {
//   const provider: Provider = yield call(getProvider, tokenAddress.network);
//   return ERC20__factory.connect(tokenAddress.address.toString(), provider);
// }

// function* loadVault(vaultAddress: NetworkAddress) {
//   logger.debug('LOADING VAULT', {
//     vaultAddress,
//   });
//   const vaultContract: RHVault = yield getRHVaultContract(vaultAddress);
//   const vault: Vault = yield vaultContract.self();
//   logger.debug('LOADED VAULT', {
//     vault,
//   });
//   yield put(contractsState.loadVault({ vaultAddress, vault }));
// }

// function loadToken(tokenAddress: NetworkAddress) {}

// // function* loadPool(vaultContract: RHVault, poolId: PoolID) {
// //   const pool: Pool = yield vaultContract.pool(poolId);
// //   yield put(
// //     contractsState.loadPool({ vaultId: vaultContract.address, poolId, pool })
// //   );
// // }

// // function* loadPosition(vaultContract: RHVault, positionId: PositionID) {
// //   const position: Position = yield vaultContract.position(positionId);
// //   yield put(
// //     contractsState.loadPosition({
// //       vaultId: vaultContract.address,
// //       positionId,
// //       position,
// //     })
// //   );
// // }

// function* runJobEveryBlock(
//   // job: typeof loadVault | typeof loadPool | typeof loadPosition,
//   job: typeof loadVault,
//   ...args: unknown[]
// ) {
//   // @ts-ignore
//   yield call(job, ...args);
//   // @ts-ignore
//   yield throttle(10_000, newBlockAction.type, job, ...args);
// }

// function* vaultSyncWorker({
//   payload: { vaultAddress },
// }: ReturnType<typeof startVaultSync>) {
//   logger.info('Vault Sync Worker running', { vaultAddress });
//   yield race({
//     job: runJobEveryBlock(loadVault, vaultAddress),
//     stop: take([endVaultSync.type, contractsState.clearVault.type]),
//   });
// }

// function* tokenSyncWorker({
//   payload: { tokenAddress },
// }: ReturnType<typeof startTokenSync>) {
//   yield race({
//     job: runJobEveryBlock(load, poolId),
//     stop: take([endPoolSync.type, contractsState.clearPool.type]),
//   });
// }

// // function* poolSyncWorker({
// //   payload: { vaultId, poolId },
// // }: ReturnType<typeof startPoolSync>) {
// //   yield race({
// //     job: runJobEveryBlock(loadPool, poolId),
// //     stop: take([endPoolSync.type, contractsState.clearPool.type]),
// //   });
// // }

// // function* positionSyncWorker({
// //   payload: { vaultId, positionId },
// // }: ReturnType<typeof startPositionSync>) {
// //   yield race({
// //     job: runJobEveryBlock(vaultId, loadPosition, positionId),
// //     stop: take([endPositionSync.type, contractsState.clearPosition.type]),
// //   });
// // }

// export function* contractsSaga() {
//   logger.info('CONTRACTS SAGA RUNNING', {});
//   yield all([
//     takeLeading(startVaultSync.type, vaultSyncWorker),
//     takeLeading(startTokenSync.type, vaultSyncWorker),
//     // takeLeading(startPoolSync.type, poolSyncWorker),
//     // takeLeading(startPositionSync.type, positionSyncWorker),
//   ]);
// }

enum CallType {
  vaultSelf = 'vaultSelf',
  vaultPosition = 'vaultPosition',
  vaultPool = 'vaultPool',
  tokenBalanceOf = 'tokenBalanceOf',
}

function injectCallType<T>(
  addressCallData: [NetworkAddress, T, ContractCall][],
  callType: CallType
): [NetworkAddress, T, ContractCall, CallType][] {
  return addressCallData.map((data) => [...data, callType]);
}

function* getVaultRefreshCalls(networkId: NetworkID) {
  const vaults: Record<VaultID, Storage<Vault>> = yield select(
    (state) => state.contracts.vaults[networkId]
  );

  const vaultAddresses: NetworkAddress[] = Object.keys(vaults).map((vault) =>
    NetworkAddress.fromStrings(networkId, vault)
  );

  const vaultAddressCallPairs: [NetworkAddress, {}, ContractCall][] =
    vaultAddresses.map((vaultAddress) => [
      vaultAddress,
      {},
      new Contract(vaultAddress.address.toString(), ABI.RHVault).self(),
    ]);

  return vaultAddressCallPairs;
}

function* getPositionRefreshCalls(networkId: NetworkID) {
  const positionsByVault: Record<
    VaultID,
    Record<PositionID, Storage<Position>>
  > = yield select((state) => state.contracts.positions[networkId]);

  const addresses: [NetworkAddress, { positionId: PositionID }][] = Object.keys(
    positionsByVault
  ).flatMap((vault) =>
    Object.keys(positionsByVault[vault]).map(
      (positionId) =>
        [NetworkAddress.fromStrings(networkId, vault), { positionId }] as [
          NetworkAddress,
          { positionId: PositionID }
        ]
    )
  );

  const callData: [NetworkAddress, { positionId: PositionID }, ContractCall][] =
    addresses.map(([vaultAddress, { positionId }]) => [
      vaultAddress,
      { positionId },
      new Contract(vaultAddress.address.toString(), ABI.RHVault).position(
        positionId
      ),
    ]);

  return callData;
}

function* getPoolRefreshCalls(networkId: NetworkID) {
  const poolsByVault: Record<
    VaultID,
    Record<PoolID, Storage<Pool>>
  > = yield select((state) => state.contracts.pools[networkId]);

  const addresses: [NetworkAddress, { poolId: PoolID }][] = Object.keys(
    poolsByVault
  ).flatMap((vault) =>
    Object.keys(poolsByVault[vault]).map(
      (poolId) =>
        [NetworkAddress.fromStrings(networkId, vault), { poolId }] as [
          NetworkAddress,
          { poolId: PoolID }
        ]
    )
  );

  const callData: [NetworkAddress, { poolId: PoolID }, ContractCall][] =
    addresses.map(([vaultAddress, { poolId }]) => [
      vaultAddress,
      { poolId },
      new Contract(vaultAddress.address.toString(), ABI.RHVault).pool(poolId),
    ]);

  return callData;
}

function* getTokenBalanceRefreshCalls(wallet: Wallet, networkId: NetworkID) {
  const tokens: Record<TokenID, Storage<Token>> = yield select(
    (state) => state.contracts.tokens[networkId]
  );

  const tokenAddresses: NetworkAddress[] = Object.keys(tokens).map((token) =>
    NetworkAddress.fromStrings(networkId, token)
  );

  const tokenAddressCallPairs: [NetworkAddress, {}, ContractCall][] =
    tokenAddresses.map((tokenAddress) => [
      tokenAddress,
      {},
      new Contract(tokenAddress.address.toString(), ABI.ERC20).balanceOf(
        wallet.account.toString()
      ),
    ]);

  return tokenAddressCallPairs;
}

// TODO(jason): Refresh pools and positions
function* refreshWorker(networkId: NetworkID, loadedWallet?: Optional<Wallet>) {
  // TODO(jason): When switching networks, refresh worker breaks
  // Repro steps:
  // 1. Load into a vault page
  // 2. Clicked the max button in deposit
  // 3. Switched to ropsten in the app
  // 4. Connected wallet (wallet was set to mainnet before)
  // 5. Refresh job started failing network changed error or some shit
  const [provider, stateWallet]: [MulticallProvider, Optional<Wallet>] =
    yield all([
      call(getMulticallProvider, resolveNetworkFromName(networkId)),
      call(getWallet),
    ]);

  const wallet = loadedWallet || stateWallet;

  const vaultRefreshCallData: [NetworkAddress, {}, ContractCall][] = yield call(
    getVaultRefreshCalls,
    networkId
  );

  const tokenBalanceRefreshCallData: [NetworkAddress, {}, ContractCall][] =
    wallet ? yield call(getTokenBalanceRefreshCalls, wallet, networkId) : [];

  const positionRefreshCallData: [
    NetworkAddress,
    { positionId: PositionID },
    ContractCall
  ][] = wallet ? yield call(getPositionRefreshCalls, networkId) : [];

  const poolRefreshCallData: [
    NetworkAddress,
    { poolId: PoolID },
    ContractCall
  ][] = yield call(getPoolRefreshCalls, networkId);

  const callData: [NetworkAddress, any, ContractCall, CallType][] =
    injectCallType(vaultRefreshCallData, CallType.vaultSelf)
      .concat(
        injectCallType(tokenBalanceRefreshCallData, CallType.tokenBalanceOf)
      )
      .concat(injectCallType(positionRefreshCallData, CallType.vaultPosition))
      .concat(injectCallType(poolRefreshCallData, CallType.vaultPool));

  const calls = callData.map(([, , ethcall]) => ethcall);
  const callResults: (Vault | BigNumberish | Position | Pool)[] =
    yield provider.all(calls);

  for (let i = 0; i < callResults.length; i += 1) {
    const [address, other, , callType] = callData[i];
    if (callType === CallType.vaultSelf) {
      yield put(
        contractsState.loadVault({
          vaultAddress: address,
          vault: callResults[i] as Vault,
        })
      );
    } else if (callType === CallType.tokenBalanceOf) {
      yield put(
        contractsState.loadTokenBalance({
          tokenAddress: address,
          tokenBalance: (callResults[i] as BigNumberish).toString(),
        })
      );
    } else if (callType === CallType.vaultPosition) {
      yield put(
        contractsState.loadPosition({
          vaultAddress: address,
          positionId: other.positionId,
          position: callResults[i] as Position,
        })
      );
    } else if (callType === CallType.vaultPool) {
      yield put(
        contractsState.loadPool({
          vaultAddress: address,
          poolId: other.poolId,
          pool: callResults[i] as Pool,
        })
      );
    }
  }
}

function* loadVaultWorker({
  payload: { vaultAddress },
}: ReturnType<typeof startVaultSync>) {
  const vaultContract: RHVault = yield call(getRHVaultContract, vaultAddress);
  const vault: Vault = yield vaultContract.self();
  yield put(contractsState.loadVault({ vaultAddress, vault }));

  const pools: Pool[] = yield all(
    [...Array(vault.state.poolCount.toNumber()).keys()].map((i) =>
      vaultContract.pool(i + 1)
    )
  );

  yield all(
    pools.map((pool, i) =>
      put(
        contractsState.loadPool({
          vaultAddress,
          poolId: (i + 1).toString(),
          pool,
        })
      )
    )
  );
}

function* loadTokenWorker({
  payload: { tokenAddress },
}: ReturnType<typeof startTokenSync>) {
  const [provider, wallet]: [MulticallProvider, Optional<Wallet>] = yield all([
    call(getMulticallProvider, tokenAddress.network),
    call(getWallet),
  ]);

  const tokenContract = new Contract(
    tokenAddress.address.toString(),
    ABI.ERC20
  );

  try {
    const [name, symbol, decimals, balance]: [
      string,
      string,
      number,
      Optional<BigNumberish>
    ] = yield provider.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.decimals(),
      ...(wallet ? [tokenContract.balanceOf(wallet.account.toString())] : []),
    ]);

    yield put(
      contractsState.loadToken({
        tokenAddress,
        token: { name, symbol, decimals, balance },
      })
    );
  } catch (err) {
    logger.error(
      'TOKEN WORKER ERROR',
      new MiscRHError({
        error: err,
        tokenAddress: tokenAddress.address.toString(),
        tokenNetwork: tokenAddress.network.displayName,
      })
    );
  }
}

function* loadPositionWorker(
  vaultAddress: NetworkAddress,
  positionId: PositionID
) {
  const vaultContract: RHVault = yield call(getRHVaultContract, vaultAddress);
  const position: Position = yield vaultContract.position(positionId);
  yield put(
    contractsState.loadPosition({ vaultAddress, positionId, position })
  );
}

function* loadUnderwritingPositionsWorker({
  payload: { vaultAddress, positionIds },
}: ReturnType<typeof subgraphState.loadUnderwritingPositions>) {
  yield all(
    positionIds.map((positionId) =>
      call(loadPositionWorker, vaultAddress, positionId)
    )
  );
}

function* loadPolicyholderPositionsWorker({
  payload: { vaultAddress, positionIds },
}: ReturnType<typeof subgraphState.loadPolicyholderPositions>) {
  yield all(
    positionIds.map((positionId) =>
      call(loadPositionWorker, vaultAddress, positionId)
    )
  );
}

function* loadUnderwritingPositionsBatchWorker({
  payload: { networkId, vaultIdToPositionIdsMap },
}: ReturnType<typeof subgraphState.loadUnderwritingPositionsBatch>) {
  yield all(
    Object.keys(vaultIdToPositionIdsMap).map((vaultId) =>
      call(loadUnderwritingPositionsWorker, {
        payload: {
          vaultAddress: NetworkAddress.fromStrings(networkId, vaultId),
          positionIds: vaultIdToPositionIdsMap[vaultId],
        },
        type: subgraphState.loadUnderwritingPositions.type,
      })
    )
  );
}

function* loadPolicyholderPositionsBatchWorker({
  payload: { networkId, vaultIdToPositionIdsMap },
}: ReturnType<typeof subgraphState.loadPolicyholderPositionsBatch>) {
  yield all(
    Object.keys(vaultIdToPositionIdsMap).map((vaultId) =>
      call(loadPolicyholderPositionsWorker, {
        payload: {
          vaultAddress: NetworkAddress.fromStrings(networkId, vaultId),
          positionIds: vaultIdToPositionIdsMap[vaultId],
        },
        type: subgraphState.loadPolicyholderPositions.type,
      })
    )
  );
}

function* refreshEvery(ms: number, networkId: NetworkID) {
  while (true) {
    const {
      walletConnected,
      walletAccountUpdated,
    }: {
      walletConnected: ReturnType<typeof walletState.loadWallet>;
      walletAccountUpdated: ReturnType<typeof walletState.updateAccount>;
    } = yield race({
      delay: delay(ms),
      walletConnected: take(walletState.loadWallet),
      walletAccountUpdated: take(walletState.updateAccount),
    });

    const currentWallet: Optional<Wallet> = yield getWallet();
    const loadedWallet = walletConnected?.payload;
    const updatedWallet: Optional<Wallet> =
      walletAccountUpdated && currentWallet
        ? {
            ...currentWallet,
            account: walletAccountUpdated.payload,
          }
        : undefined;

    // If refresh fails, don't stop it (could be because of API key throttling)
    try {
      yield refreshWorker(networkId, loadedWallet || updatedWallet);
    } catch (err) {
      // TODO(jason): Figure out why this keeps returning undefined address errors (for now silence these errors)
      // TODO(jason): Handle this better and bubble failure up IF NOT API key throttling
      // logger.error((err as Error).message, new UncaughtError(err as Error));
    }
  }
}

function* loadWorker() {
  yield all([
    takeEvery(startVaultSync.type, loadVaultWorker),
    takeEvery(startTokenSync.type, loadTokenWorker),
    takeEvery(
      subgraphState.loadUnderwritingPositions.type,
      loadUnderwritingPositionsWorker
    ),
    takeEvery(
      subgraphState.loadUnderwritingPositionsBatch.type,
      loadUnderwritingPositionsBatchWorker
    ),
    takeEvery(
      subgraphState.loadPolicyholderPositions.type,
      loadPolicyholderPositionsWorker
    ),
    takeEvery(
      subgraphState.loadPolicyholderPositionsBatch.type,
      loadPolicyholderPositionsBatchWorker
    ),
  ]);
}

export function* contractsSaga() {
  yield all([
    loadWorker(),
    refreshEvery(10_000, NetworkID.mainnet),
    refreshEvery(10_000, NetworkID.ropsten),
    refreshEvery(10_000, NetworkID.arbitrum),
    refreshEvery(10_000, NetworkID.arbitrumRinkeby),
    refreshEvery(10_000, NetworkID.optimism),
    refreshEvery(10_000, NetworkID.optimismKovan),
    refreshEvery(10_000, NetworkID.polygon),
    refreshEvery(10_000, NetworkID.polygonMumbai),
    refreshEvery(10_000, NetworkID.avalanche),
    refreshEvery(10_000, NetworkID.avalancheFuji),
  ]);
}
