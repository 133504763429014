import { NetworkAddress } from 'src/models/NetworkAddress';

export function getTokenIcon(tokenNetworkAddress: NetworkAddress): string {
  const tokenAddress = tokenNetworkAddress.address;
  const tokenNetwork = tokenNetworkAddress.network;

  if (tokenNetwork.id === 'arbitrum') {
    // eslint-disable-next-line
    return `https://raw.githubusercontent.com/Risk-Harbor/assets/master/blockchains/arbitrum/assets/${tokenAddress}/logo.png`;
  }
  // eslint-disable-next-line
  return `https://raw.githubusercontent.com/Risk-Harbor/assets/master/blockchains/ethereum/assets/${tokenAddress}/logo.png`;
}
