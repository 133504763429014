import { useState } from 'react';
import { MiscRHError } from 'src/errors';
import { resolveValueOrGetter, ValueOrGetter } from 'src/types/ValueOrGetValue';
import { logger } from 'src/utils/logger';
import { serde } from 'src/utils/serde';

const RH_ID = `risk-harbor-item-${process.env.NODE_ENV}-`;

export function useLocalStorage<T>(
  key: string,
  defaultValue: ValueOrGetter<T>
): [T, (newValue: ValueOrGetter<T>) => void] {
  const globalKey = RH_ID + key;

  const [value, setValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(globalKey);
      if (item) {
        return serde.deserializeFromString(item);
      }
    } catch (err) {
      logger.error(
        `Error while trying to fetch local storage key ${globalKey}`,
        new MiscRHError(err)
      );
    }

    return resolveValueOrGetter(defaultValue);
  });

  const setLocalStorageValue = (newValue: ValueOrGetter<T>): void => {
    const newValueResolved = resolveValueOrGetter(newValue);

    try {
      window.localStorage.setItem(
        globalKey,
        serde.serializeToString(newValueResolved)
      );
      setValue(newValueResolved);
    } catch (err) {
      logger.error(
        `Error while trying to set local storage key ${globalKey}`,
        new MiscRHError({
          error: err,
          item: newValueResolved,
        })
      );
    }
  };

  return [value, setLocalStorageValue];
}
