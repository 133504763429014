import { SupportedWallets, WalletType } from 'src/config/wallets';
import { useDispatchableCallback } from 'src/hooks/useDispatchableCallback';
import { Wallet } from 'src/models/Wallet';
import { useAppSelector } from 'src/state';
import { connectWeb3Wallet, disconnectWallet } from 'src/state/actions';
import { Optional } from 'src/types/Optional';

type WalletConnector = typeof connectWeb3Wallet;

function getConnector(wallet: WalletType): WalletConnector {
  switch (wallet) {
    case WalletType.metamask:
    case WalletType.coinbase:
    case WalletType.walletconnect:
      return connectWeb3Wallet;
    default:
      throw new Error(`Unexpected wallet type ${wallet}`);
  }
}

export function useWallet(): {
  wallet: Optional<Wallet>;
  connect: (walletType: WalletType) => void;
  disconnect: () => void;
} {
  const wallet = useAppSelector((state) => state.wallet.value);
  const connect = useDispatchableCallback((walletType: WalletType) =>
    getConnector(walletType)(walletType)
  );
  const disconnect = useDispatchableCallback(disconnectWallet);

  return {
    wallet,
    connect,
    disconnect,
  };
}

export function useWalletOptions() {
  // Keeping this as a hook to decouple this hardcoded constant from view layer
  return SupportedWallets;
}
