import React from 'react';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { useSimulator } from 'src/viewmodels/simulator';
import Completed from 'src/assets/step-progress/completed.png';
import InProgress from 'src/assets/step-progress/inprogress.png';
import Remaining from 'src/assets/step-progress/remaining1.png';

const Text = styled.p`
  color: #fff;
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
  position: absolute;
  width: 8rem;
  margin: 0.3rem 0 0 -1.5rem;
  padding: 0.1rem;
  line-height: 1.2rem;
`;

const TextHead = styled.p`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  margin: 0 !important;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  text-align: center;

  @media only screen and (max-width: 796px) {
    font-size: var(--p2-font-size);
  }
`;

export const SimulatorHead: React.FC = () => {
  const { simulatorStage } = useSimulator();
  const getImage = (stage: number, accomplished: number): string => {
    if (stage < accomplished) {
      return Completed;
    }
    if (stage > accomplished) {
      return Remaining;
    }
    return InProgress;
  };
  return (
    <>
      <TextHead>Simulator </TextHead>
      <Box
        sx={{
          minWidth: '24rem',
          maxWidth: '58%',
          alignContent: 'center',
          alignItem: 'center',
          marginLeft: '21%',
          marginBottom: '7.5rem',
        }}
      >
        <ProgressBar
          percent={simulatorStage * 25}
          height={2}
          filledBackground="linear-gradient(to right, #568FFF, #20BFE2)"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <div>
                <img
                  alt="text"
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="30"
                  src={getImage(0, simulatorStage * 25)}
                />
                <Text>Create a pool</Text>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div>
                <img
                  alt="text"
                  style={{ filter: `grayscale(60%)` }}
                  width="30"
                  src={getImage(25, simulatorStage * 25)}
                />
                <Text>Underwrite protection</Text>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div>
                <img
                  alt="text"
                  style={{ filter: `grayscale(40%)` }}
                  width="30"
                  src={getImage(50, simulatorStage * 25)}
                />
                <Text>Purchase protection</Text>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div>
                <img
                  alt="text"
                  style={{ filter: `grayscale(20%)` }}
                  width="30"
                  src={getImage(75, simulatorStage * 25)}
                />
                <Text>Hack the pool</Text>
              </div>
            )}
          </Step>
          <Step transition="scale">
            {() => (
              <div>
                <img
                  alt="text"
                  style={{
                    marginRight: '30px',
                  }}
                  width="30"
                  src={getImage(100, simulatorStage * 25)}
                />
                <Text>File a claim</Text>
              </div>
            )}
          </Step>
        </ProgressBar>
      </Box>
    </>
  );
};
