import React from 'react';
import styled from 'styled-components';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { WalletProviderModal } from 'src/components/Modal/WalletProviderModal';
// import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
// import { Web3ReactProvider } from '@web3-react/core';

interface Props {
  type?: string;
}

const SButton = styled(Button)<{ isDisabled: boolean }>`
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: var(--h2-font-size) !important;
  text-align: center !important;
  font-weight: 600 !important;
  width: 100% !important;
  padding: 2rem 0rem !important;
  border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  color: var(--text-primary-white) !important;
  background-color: var(--components-primary-blue) !important;

  &:hover {
    background-color: var(--components-primary-blue) !important;
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none;
  }
`;

export const ButtonConnect: React.FC<Props> = ({ type }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const connectText = intl.formatMessage({
    id: 'connect-wallet-text',
    defaultMessage: 'Connect Wallet',
  });

  // const getLibrary = (pro: ExternalProvider) => {
  //   const library = new Web3Provider(pro);
  //   library.pollingInterval = 12000;
  //   return library;
  // };

  return (
    <>
      {/* <Web3ReactProvider getLibrary={getLibrary}> */}
      {type === 'orderbox' ? (
        <SButton onClick={onOpen}>{connectText}</SButton>
      ) : (
        <Button
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{ outline: 'none', boxShadow: 'none' }}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor="white"
          background="var(--components-primary-blue)"
          fontSize="var(--p-font-size)"
          onClick={onOpen}
        >
          {connectText}
        </Button>
      )}
      <WalletProviderModal isOpen={isOpen} onClose={onClose} />
      {/* </Web3ReactProvider> */}
    </>
  );
};

ButtonConnect.defaultProps = {
  type: '',
};
