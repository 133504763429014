import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from 'src/components/ScrollToTop';
import { Pool } from 'src/pages/Pool';
import { Vault } from 'src/pages/Vault';
import { HackSimulatorForm } from 'src/pages/Simulator';
import { HackPool } from 'src/pages/HackPool';

const SubPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

export const Menu: React.FC = () => {
  return (
    <>
      <SubPage>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Redirect to="/simulator" />
            </Route>
            <Route exact path="/pool/:network/:vaultId/:poolId">
              <Pool />
            </Route>
            <Route exact path="/vault/:network/:vaultId">
              <Vault />
            </Route>
            <Route exact path="/hack/pool/:network/:vaultId">
              <HackPool />
            </Route>
            <Route exact path="/simulator">
              <HackSimulatorForm />
            </Route>
            <Redirect from="*" to="/simulator" />
          </Switch>
        </ScrollToTop>
      </SubPage>
    </>
  );
};
