import { BigNumber, BigNumberish, ethers } from 'ethers';
import { Token } from 'src/models/Token';
import numeral from 'numeral';

export class TokenAmount {
  private static parseRegex =
    /^0*(?<integer>[1-9]\d*)?(?<decimalDelimiter>\.)?(?<decimal>(?:0*[1-9]+)*)0*$/;

  readonly token: Token;

  readonly amount: BigNumber;

  private readonly withDecimal: boolean;

  private constructor(token: Token, amount: BigNumber, withDecimal = false) {
    this.token = token;
    this.amount = amount;
    this.withDecimal = withDecimal;
  }

  static from(token: Token, amount: BigNumberish, withDecimals = false) {
    return new TokenAmount(token, BigNumber.from(amount), withDecimals);
  }

  static default() {
    return TokenAmount.from({ symbol: '', name: '', decimals: 0 }, 0);
  }

  static zero(token: Token, withDecimals = false) {
    return TokenAmount.from(token, 0, withDecimals);
  }

  static one(token: Token) {
    return TokenAmount.from(
      token,
      BigNumber.from(BigNumber.from(10).pow(token.decimals))
    );
  }

  toRawString() {
    return this.amount.toString();
  }

  toDecimalString() {
    const multiplier = BigNumber.from(10).pow(this.token.decimals);
    const integer = this.amount.div(multiplier);
    const decimals = this.amount.mod(multiplier);

    if (decimals.eq(0)) {
      return this.withDecimal ? `${integer.toString()}.` : integer.toString();
    }

    return ethers.utils.formatUnits(this.amount, this.token.decimals);
  }

  toBigNumber(): BigNumber {
    return this.amount;
  }

  toFormattedString(
    minDecimals: number,
    maxDecimals = this.token.decimals,
    commas = true
  ) {
    const placeHolder = '0';
    const remainingDec = maxDecimals - minDecimals;
    const value = ethers.utils.formatUnits(this.amount, this.token.decimals);
    const formattedValue = numeral(value).format(
      `0.${placeHolder.repeat(minDecimals)}[${placeHolder.repeat(
        remainingDec
      )}]${commas ? 'a' : ''}`
    );
    return formattedValue;
  }

  toSignifcantDigits(digits: number) {
    const ethersVal = ethers.utils.formatUnits(
      this.amount,
      this.token.decimals
    );
    const placeHolder = '0';
    let addedZeros = 0;
    if (digits > 3) {
      addedZeros = digits - 3;
    }
    const sigFigVal = numeral(Number(ethersVal).toPrecision(digits)).format(
      `0[.][00${placeHolder.repeat(addedZeros)}]a`
    );
    return sigFigVal;
  }

  static parseFromRawString(token: Token, amount: string) {
    const match = amount.match(TokenAmount.parseRegex);

    const hasDecimalPoint = !!match?.groups?.decimalDelimiter;

    if (
      !match ||
      !match.groups ||
      (!match.groups.integer && !match.groups.decimal)
    ) {
      return TokenAmount.zero(token, hasDecimalPoint);
    }

    const { integer, decimal } = match.groups;
    const parsedAmount = ethers.utils.parseUnits(
      `${integer || 0}.${decimal?.slice(0, token.decimals) || 0}`,
      token.decimals
    );

    return new TokenAmount(
      token,
      BigNumber.from(parsedAmount),
      hasDecimalPoint
    );
  }

  parseNewAmount(amount: string) {
    return TokenAmount.parseFromRawString(this.token, amount);
  }
}
