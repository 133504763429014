import { ContractTransaction } from 'ethers';
import { MiscRHError } from 'src/errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReplacedTxError(err: any): boolean {
  return !!err.replacement;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getReplacedTx(err: any): ContractTransaction {
  if (!isReplacedTxError(err)) {
    throw new MiscRHError("error isn't a replaced transaction error");
  }

  return err.replacement as ContractTransaction;
}
