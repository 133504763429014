import React, { useContext, useState } from 'react';
import { Button, Link, Spinner } from '@chakra-ui/react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { TokenAmountInput } from 'src/components/OrderBox/TokenAmountInput';
import { PoolContext } from 'src/contexts/PoolContext';
import { OrderButton } from 'src/components/OrderBox/OrderButton';
import { TokenAmount } from 'src/models/TokenAmount';
import { usePool } from 'src/viewmodels/pool';
import UseToaster from 'src/hooks/useToast';
import { minBigNum } from 'src/math/utils';
import { RHError } from 'src/errors';
import { getBlockExplorerUrl } from 'src/utils/network';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-blue);
  border-radius: 8px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const CancelButton = styled(Button)`
  border-radius: 8px;
  background-color: #df4863 !important;
  color: var(--text-primary-white) !important;
  font-size: var(--h2-font-size) !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding: 2rem 0rem !important;
  margin-top: 0.5rem;
  margin-bottom: var(--section-bm);

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-primary-white);

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  networkMismatch: boolean | null | undefined;
}

export const Claim: React.FC<Props> = ({ networkMismatch }) => {
  const intl = useIntl();
  const payoutText = intl.formatMessage({
    id: 'claim-payout-text',
    defaultMessage: 'PAYOUT',
  });
  const amtText = intl.formatMessage({
    id: 'claim-amt-text',
    defaultMessage: 'AMOUNT',
  });

  const poolAddress = useContext(PoolContext);
  const {
    pool = undefined,
    protectedToken = undefined,
    claims = undefined,
  } = usePool(poolAddress) ?? {};

  const [isInvalidAmount, setIsInvalidAMount] = useState<boolean>(false);
  const [tokenAmount, setTokenAmount] = useState<TokenAmount>(
    TokenAmount.default()
  );

  const toast = UseToaster();

  const onSuccess = (operation: string) => (txHash: string) => {
    toast.success({
      title: `${operation} Successful!`,
      message: (
        <Link
          href={`${
            getBlockExplorerUrl(poolAddress?.vaultAddress?.network) ||
            'https://etherscan.io'
          }/tx/${txHash}`}
          isExternal
        >
          View transaction
        </Link>
      ),
    });
  };
  const onFailure = (operation: string) => (error: RHError) => {
    toast.error({
      title: `${operation} Failed`,
      message: error.message,
    });
  };

  let maxAmount = TokenAmount.default();
  let protectedBalance = TokenAmount.default();
  if (protectedToken && claims) {
    protectedBalance = TokenAmount.from(
      protectedToken,
      protectedToken.balance || 0
    );
    maxAmount = minBigNum(protectedBalance, claims.totalProtectedAmount);
  }

  const onAmountChange = (amount: TokenAmount) => {
    setTokenAmount(amount);
    setIsInvalidAMount(amount.toBigNumber().gt(maxAmount.toBigNumber()));
  };

  const payoutAmount = claims?.computePayout(tokenAmount);
  const currClaimAmount = claims?.claimInProgress?.claimAmount;
  const currPayoutAmount = claims?.claimInProgress?.payoutAmount;

  return (
    <Container>
      {networkMismatch ? (
        <>
          <AmountBox style={{ marginBottom: 0 }}>
            <Text style={{ margin: 'auto' }}>
              Please switch to {poolAddress?.vaultAddress.network.displayName}
            </Text>
          </AmountBox>
        </>
      ) : (
        <>
          {claims?.currentAction ? (
            <>
              <VerifyContainer>
                <Spinner
                  boxSize={16}
                  color="var(--components-primary-blue)"
                  thickness="5px"
                  speed="0.95s"
                  mt="2rem"
                  mb="6rem"
                />
              </VerifyContainer>
              <OrderButton
                actionType="Claim"
                claimAction={claims.currentAction}
              />
            </>
          ) : (
            <>
              {claims?.currentStage === 'start_claim' && (
                <>
                  <TokenAmountInput
                    actionType="Claim"
                    token={protectedToken}
                    amount={tokenAmount}
                    maxAmount={maxAmount}
                    onChange={onAmountChange}
                  />
                  <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
                    <Text>{payoutText}</Text>
                    <AmountText>
                      {payoutAmount?.toFormattedString(1, 2, true)}{' '}
                      {pool?.underwritingToken.symbol}
                    </AmountText>
                  </AmountBox>
                  <OrderButton
                    actionType="Claim"
                    disabled={isInvalidAmount}
                    currentClaimStage={claims.currentStage}
                    onClick={() =>
                      claims?.startClaim(
                        tokenAmount,
                        onSuccess('Start Claim'),
                        onFailure('Start Claim')
                      )
                    }
                    zeroAmount={tokenAmount.toBigNumber().eq(0)}
                  />
                </>
              )}{' '}
              {claims?.currentStage === 'terminate_claim' && (
                <>
                  <AmountBox>
                    <Text>{amtText}</Text>
                    <AmountText>
                      {currClaimAmount?.toFormattedString(1, 2, true)}{' '}
                      {protectedToken?.symbol}
                    </AmountText>
                  </AmountBox>
                  <AmountBox>
                    <Text>{payoutText}</Text>
                    <AmountText>
                      {currPayoutAmount?.toFormattedString(1, 2, true)}{' '}
                      {pool?.underwritingToken.symbol}
                    </AmountText>
                  </AmountBox>
                  <CancelButton
                    isDisabled={claims.loading}
                    onClick={() =>
                      claims?.cancelClaim(
                        onSuccess('Cancel Claim'),
                        onFailure('Cancel Claim')
                      )
                    }
                  >
                    CANCEL CLAIM
                  </CancelButton>
                  <OrderButton
                    actionType="Claim"
                    currentClaimStage={claims.currentStage}
                    disabled={claims.loading}
                    onClick={() =>
                      claims?.finishClaim(
                        onSuccess('Finish Claim'),
                        onFailure('Finish Claim')
                      )
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
