import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useNetwork, useNetworkOptions } from 'src/viewmodels/network';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1.5rem;
  row-gap: 1.5rem;

  @media only screen and (max-width: 796px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const NetworkIcon = styled.img`
  width: 1.5rem;
  margin-right: 0.5rem;
  border-radius: 1rem;
`;

const Text = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
`;

const Content = styled(ModalContent)`
  max-width: 500px !important;
  background-color: var(--modal-blue) !important;

  @media only screen and (max-width: 796px) {
    max-width: 300px !important;
  }
`;

const NetworkButton = styled(Button)`
  color: white;
  padding: var(--button-py) var(--button-px) var(--button-py) var(--button-px);
  background: var(--background-blue) !important;
  font-size: var(--p-font-size);
  margin-right: 12px;

  &:hover {
    text-decoration: none !important;
    filter: brightness(85%);
  }

  &:active {
    color: transparent;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  @media only screen and (max-width: 960px) {
    margin-right: 0px;
  }
`;

export const NetworkModal: React.FC = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const supportedNetworks = useNetworkOptions();
  const { currentNetwork, updateNetwork } = useNetwork();
  const networkHeaderText = intl.formatMessage({
    id: 'network-modal-header-text',
    defaultMessage: 'Select a network',
  });

  return (
    <>
      <NetworkButton onClick={onOpen}>
        <NetworkIcon src={currentNetwork.imgSrc} alt="network icon" />
        <Text>{currentNetwork.displayName}</Text>
      </NetworkButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="var(--overlay-modal-black)" />
        <Content>
          <ModalHeader textColor="var(--text-primary-white)">
            {networkHeaderText}
          </ModalHeader>
          <ModalCloseButton
            style={{ color: 'white', marginTop: 10 }}
            _focus={{ outline: 'none' }}
            _hover={{ filter: 'brightness(65%)' }}
          />
          <ModalBody marginBottom={5}>
            <GridBox>
              {supportedNetworks.map((item) => {
                return (
                  <Button
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center !important"
                    border="1px solid var(--components-primary-blue)"
                    _hover={{
                      textDecoration: 'none',
                      filter: 'brightness(75%)',
                    }}
                    _active={{ color: 'transparent' }}
                    _focus={{
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                    px="var(--button-px)"
                    py="var(--button-py)"
                    textColor="var(--text-primary-white)"
                    background="transparent"
                    w="214px"
                    onClick={() => {
                      updateNetwork(item);
                      onClose();
                    }}
                  >
                    <NetworkIcon src={item.imgSrc} alt="image" />
                    <Text>{item.displayName}</Text>
                  </Button>
                );
              })}
            </GridBox>
          </ModalBody>
        </Content>
      </Modal>
    </>
  );
};
