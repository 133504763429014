/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Decimal } from 'decimal.js';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { TokenAmount } from 'src/models/TokenAmount';
import { useAppSelector } from 'src/state';
import UseToaster from 'src/hooks/useToast';
import {
  loadUserVaultUnderwritingPositions,
  startVaultSync,
} from 'src/state/actions';
import {
  Vault,
  Storage,
  Token,
  Pool,
  Position,
  PositionID,
} from 'src/state/contracts';
import { Optional } from 'src/types/Optional';
import { useToken } from 'src/viewmodels/token';
import { TimePeriod } from 'src/utils/time';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { useWallet } from 'src/viewmodels/wallet';
import { permitTokenTransfer } from 'src/utils/ethereum/permit';
import { RHVault__factory } from 'src/types/contracts/factories/RHVault__factory';
import { BigNumber, Signer } from 'ethers';
import { useCMSVault } from 'src/services/cms';
import { useLeverage } from 'src/viewmodels/leverage';
import { message } from 'src/components/OrderBox/MessageTransaction';
import { useYield } from 'src/viewmodels/yield';
import { MessageError, RHError, UncaughtError } from 'src/errors';
import { withdrawableAmount } from 'src/math/MathLib';
import { Wallet } from 'src/models/Wallet';
import { simulatorState } from 'src/state/simulator';
import { RHVault } from 'src/types/contracts/RHVault';
import {
  getEIP721Support,
  getAllowance,
  approveAmount,
} from 'src/utils/transactional';
import { triggerEvent } from 'src/services/tagmanager';
import { GOOGLE_EVENTS } from 'src/constants/constants';

type TokenWithAddress = Token & { address: NetworkAddress };

export enum RiskAversion {
  RiskOn = 'Risk-On',
  Cautious = 'Cautious',
  RiskOff = 'Risk-Off',
}

export type VaultPoolView = {
  id: number;
  protectedTokenAddress: NetworkAddress;
  poolRiskScore: number; // TODO(jason): CMS
  utilizationPercent: number;
  hacked: boolean;
  rawState: Pool;
};

export type VaultPositionView = {
  id: string;
  shares: Decimal; // TODO(jason)
  rawState: Position;
};

export type VaultView = {
  network: NetworkAddress;
  name: string;
  underwritingToken: TokenWithAddress;
  rewardToken: Optional<TokenWithAddress>;
  expirationDate: Date;
  pools: VaultPoolView[];
  vaultCapacity: TokenAmount;
  riskAversion: RiskAversion;
  description: string;
  underwriterRisks: string;
  positions: VaultPositionView[]; // will be empty if user's wallet is not connected
  leverage: string;
  rawState: Vault;
  apy: number;
};

type WithdrawFields = {
  sharesBeingRedeemed: TokenAmount;
  underwritingTokenAmount: TokenAmount;
  withdrawing: boolean;
};

type WithdrawFunctions = {
  withdraw(
    onSuccess: (txHash: string) => unknown,
    onError: (error: RHError) => unknown
  ): Promise<void>;
};

type Withdrawal = WithdrawFields & WithdrawFunctions;

function getRHVaultContract(
  wallet: Wallet,
  vaultAddress: NetworkAddress
): RHVault {
  return RHVault__factory.connect(
    vaultAddress.address.toString(),
    wallet.provider.getSigner() as Signer
  );
}

function useWithdrawal(
  vaultAddress: Optional<NetworkAddress>
): Optional<Withdrawal> {
  // TODO

  const { wallet } = useWallet();
  const [withdrawing, setWithdrawing] = useState(false);
  const networkId = vaultAddress?.network?.id;
  const vaultAddressStr = vaultAddress?.address?.toString?.();

  const vault = useAppSelector((state) =>
    networkId && vaultAddressStr
      ? state.contracts.vaults[networkId]?.[vaultAddressStr]?.value
      : undefined
  );

  /* const rewardToken = useToken(
    vaultAddress
      ? NetworkAddress.fromNetworkAndAddress(
          vaultAddress.network,
          vaultAddress.network.config.addressMap.ticketToken
        )
      : undefined
  ); */

  const underwritingToken = useToken(
    vaultAddress && vault
      ? NetworkAddress.fromNetworkAndAddressString(
          vaultAddress.network,
          vault.config.underwritingToken
        )
      : undefined
  );

  const sharesToken = useMemo(
    () =>
      underwritingToken
        ? ({
            name: 'shares',
            symbol: 'shares',
            // TODO(drew): Is this correct? Or is shares supposed to be 18 decimals
            decimals: underwritingToken.decimals,
          } as Token)
        : undefined,
    [underwritingToken]
  );

  const underwritingPositions = useAppSelector((state) =>
    networkId && vaultAddressStr && wallet
      ? Object.values(
          state.contracts.positions[networkId]?.[vaultAddressStr] ?? {}
        )
          .map((positionStorage) => positionStorage.value)
          .filter((position) => position.state.positionType === 1)
      : undefined
  );

  const totalSharesBeingWithdrawn = useMemo(
    () =>
      underwritingPositions && sharesToken
        ? TokenAmount.from(
            sharesToken,
            underwritingPositions.reduce(
              (acc, position) => acc.add(position.state.value),
              BigNumber.from(0)
            )
          )
        : undefined,
    [sharesToken, underwritingPositions]
  );

  const [totalSharesInVault, underwritingTokensAvailable] = vault
    ? [vault.state.totalShares, vault.state.allocationVector[0]]
    : [undefined, undefined];

  const underwritingTokenAmount = useMemo(
    () =>
      underwritingToken &&
      totalSharesBeingWithdrawn &&
      totalSharesInVault &&
      underwritingTokensAvailable
        ? TokenAmount.from(
            underwritingToken,
            withdrawableAmount(
              totalSharesBeingWithdrawn.toBigNumber(),
              totalSharesInVault,
              underwritingTokensAvailable
            )
          )
        : undefined,
    [
      totalSharesBeingWithdrawn,
      totalSharesInVault,
      underwritingToken,
      underwritingTokensAvailable,
    ]
  );

  /* const rewardTokenAmount = useMemo(
    () =>
      rewardToken && totalSharesBeingWithdrawn && vault && underwritingPositions
        ? TokenAmount.from(
            rewardToken,
            underwritingPositions.reduce(
              (acc, position) =>
                acc.add(
                  rewards(
                    totalSharesBeingWithdrawn.toBigNumber(),
                    vault.config.totalRewards,
                    vault.state.totalAmortizedShares,
                    BigNumber.from(vault.config.start),
                    BigNumber.from(position.state.timestamp),
                    BigNumber.from(vault.config.expiration)
                  )
                ),
              BigNumber.from(0)
            )
          )
        : undefined,
    [rewardToken, totalSharesBeingWithdrawn, underwritingPositions, vault]
  ); */

  const vaultContract =
    wallet && vaultAddress && getRHVaultContract(wallet, vaultAddress);

  const withdraw = useCallback(
    async (
      onSuccess: (txHash: string) => unknown,
      onError: (error: RHError) => unknown
    ) => {
      setWithdrawing(true);
      try {
        if (!vaultContract) {
          throw new MessageError('Vault contract not loaded');
        }

        if (!underwritingPositions) {
          throw new MessageError('Underwriting positions not loaded');
        }

        if (underwritingPositions.length === 0) {
          return;
        }

        const tx = await vaultContract.multicall(
          underwritingPositions.map((position) =>
            vaultContract.interface.encodeFunctionData('withdraw', [
              position.id,
            ])
          )
        );

        await tx.wait(1);

        setWithdrawing(false);
        onSuccess(tx.hash);
      } catch (err) {
        setWithdrawing(false);
        if (err instanceof MessageError) {
          onError(err);
        } else {
          onError(new UncaughtError(err as Error));
        }
      }
    },
    [underwritingPositions, vaultContract]
  );

  return totalSharesBeingWithdrawn && underwritingTokenAmount && vaultContract
    ? {
        withdraw,
        sharesBeingRedeemed: totalSharesBeingWithdrawn,
        underwritingTokenAmount,
        withdrawing,
      }
    : undefined;
}

export function useVault(vaultAddress: Optional<NetworkAddress>): Optional<{
  vault: VaultView;
  deposit(amount: TokenAmount): Promise<void>;
  isDepositing: boolean;
  isApproving: boolean;
  underwritingToken: Optional<Token>;
  withdrawal: Optional<Withdrawal>;
}> {
  // TODO(jason): Handle the case where vaultId is not an actual RHVault contract address
  const dispatch = useDispatch();
  const { wallet } = useWallet();
  const Toast = UseToaster();
  const [isDepositing, setIsDepositing] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);

  const vaultStorage: Optional<Storage<Vault>> = useAppSelector((state) =>
    vaultAddress
      ? state.contracts.vaults[vaultAddress.network.id][
          vaultAddress.address.toString()
        ]
      : undefined
  );

  const positions: Record<PositionID, Storage<Position>> = useAppSelector(
    (state) =>
      vaultAddress
        ? state.contracts.positions?.[vaultAddress.network.id]?.[
            vaultAddress.address.toString()
          ] || {}
        : {}
  );

  const pools: Record<PositionID, Storage<Pool>> = useAppSelector((state) =>
    vaultAddress
      ? state.contracts.pools?.[vaultAddress.network.id]?.[
          vaultAddress.address.toString()
        ] || {}
      : {}
  );
  const networkInfo = useAppSelector((state) => state.network.value);

  const positionViews: VaultPositionView[] = wallet
    ? Object.keys(positions).map((positionId) => ({
        id: positionId,
        shares: new Decimal(positions[positionId].value.state.value.toString()),
        rawState: positions[positionId].value,
      }))
    : [];

  const poolViews: VaultPoolView[] = Object.keys(pools).map((poolId) => ({
    id: parseInt(poolId, 10),
    protectedTokenAddress: NetworkAddress.fromStrings(
      // This will always work
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      vaultAddress!.network.id,
      pools[poolId].value.config.protectedToken
    ),
    poolRiskScore: 0.0,
    utilizationPercent:
      vaultStorage && vaultStorage.value.state.allocationVector[0].gt(0)
        ? new Decimal(
            vaultStorage.value.state.allocationVector[
              parseInt(poolId, 10)
            ].toString()
          )
            .mul(100)
            .div(
              new Decimal(
                vaultStorage.value.state.allocationVector[0].toString()
              )
            )
            .toNumber()
        : 0,
    hacked: pools[poolId].value.state.status === 1, // TODO(jason): cleanup
    rawState: pools[poolId].value,
  }));

  const address = vaultAddress?.address.toString();
  const network = vaultAddress?.network.id;

  useEffect(() => {
    if (!address || !network) return;

    dispatch(
      startVaultSync({
        vaultAddress: NetworkAddress.fromStrings(network, address),
      })
    );
  }, [dispatch, address, network]);

  useEffect(() => {
    if (!address || !network || !wallet) return;

    dispatch(
      loadUserVaultUnderwritingPositions({
        vaultAddress: NetworkAddress.fromStrings(network, address),
      })
    );
  }, [dispatch, address, network, wallet]);

  const underwritingTokenAddress =
    vaultStorage &&
    vaultAddress &&
    NetworkAddress.fromNetworkAndAddressString(
      vaultAddress.network,
      vaultStorage.value.config.underwritingToken
    );

  const rewardTokenAddress =
    vaultStorage &&
    vaultAddress &&
    NetworkAddress.fromNetworkAndAddressString(
      vaultAddress.network,
      vaultStorage.value.config.rewardToken
    );

  const underwritingToken = useToken(underwritingTokenAddress);
  const rewardToken = useToken(rewardTokenAddress);
  const vaultCmsData = useCMSVault(vaultAddress?.address);
  const leverage = useLeverage(vaultStorage?.value);
  const withdrawal = useWithdrawal(vaultAddress);
  const history = useHistory();

  const apy = useYield(vaultStorage?.value);

  const vault: Optional<VaultView> =
    vaultAddress && vaultStorage && underwritingToken
      ? {
          network: vaultAddress,
          name: vaultCmsData?.attributes.name ?? '',
          underwritingToken: {
            ...underwritingToken,
            address: underwritingTokenAddress,
          } as TokenWithAddress,
          rewardToken: {
            ...rewardToken,
            address: rewardTokenAddress,
          } as TokenWithAddress,
          expirationDate: new Date(vaultStorage.value.config.expiration * 1000),
          vaultCapacity: TokenAmount.from(
            underwritingToken,
            vaultStorage.value.state.allocationVector[0]
          ),
          riskAversion:
            (vaultCmsData?.attributes.RiskAversion as RiskAversion) ??
            RiskAversion.Cautious,
          description: vaultCmsData?.attributes.description ?? '',
          underwriterRisks: vaultCmsData?.attributes.underwriterRisks ?? '',
          pools: poolViews || [],
          positions: positionViews || [],
          leverage,
          rawState: vaultStorage.value,
          apy: apy || 0,
        }
      : undefined;

  async function deposit(amount: TokenAmount) {
    try {
      if (!wallet) {
        throw new Error('No wallet connected');
      }

      if (!underwritingTokenAddress) {
        throw new Error('Underwriting token state not loaded');
      }

      if (!vaultAddress) {
        throw new Error('Vault address not defined');
      }
      setIsDepositing(true);

      const spender = vaultAddress.address;
      const value = amount.toBigNumber();
      const deadline = UnixTimestamp.now().add(2, TimePeriod.Day);
      const tokenAddress = underwritingTokenAddress;

      const isEIP721Support = await getEIP721Support(tokenAddress);

      const vaultContract = getRHVaultContract(wallet, vaultAddress);

      let permitCall: string | undefined;
      triggerEvent(GOOGLE_EVENTS.PROVIDE_PROTECTION_STARTED);

      if (!isEIP721Support) {
        const allowance = await getAllowance(
          tokenAddress.address.toLowercaseString(),
          wallet,
          spender.toLowercaseString()
        );
        if (allowance.lt(amount.toBigNumber())) {
          setIsApproving(true);
          const isApprovedSuccessData = await approveAmount(
            tokenAddress.address.toLowercaseString(),
            wallet,
            spender.toLowercaseString(),
            amount.toBigNumber()
          );
          setIsApproving(false);
          if (!isApprovedSuccessData.success) {
            Toast.error({
              message: isApprovedSuccessData.message,
              title: 'Errored Approving',
            });
            setIsDepositing(false);
            triggerEvent(GOOGLE_EVENTS.PROVIDE_PROTECTION_FAILED);
            return;
          }
        }
      } else {
        const { v, r, s } = await permitTokenTransfer(
          wallet,
          spender,
          value,
          deadline,
          tokenAddress
        );

        permitCall = vaultContract.interface.encodeFunctionData('permitToken', [
          underwritingTokenAddress.address.toString(),
          {
            owner: wallet.account.toString(),
            value: amount.toBigNumber(),
            deadline: BigNumber.from(deadline.value),
            signatures: {
              v,
              r,
              s,
            },
          },
        ]);
      }

      const tx = await (permitCall
        ? vaultContract.multicall([
            permitCall,
            vaultContract.interface.encodeFunctionData('deposit', [
              amount.toBigNumber(),
            ]),
          ])
        : vaultContract.deposit(amount.toBigNumber()));

      const receipt = await tx.wait(1);

      setIsDepositing(false);

      const scannerUrl = `${
        networkInfo.config.walletConfig.blockExplorerUrls
          ? networkInfo.config.walletConfig.blockExplorerUrls[0]
          : 'https://etherscan.io'
      }/tx/${receipt.transactionHash}`;

      Toast.success({
        title: 'Successful!',
        message: message(scannerUrl),
      });
      triggerEvent(GOOGLE_EVENTS.PROVIDE_PROTECTION_SUCCESS);
      dispatch(simulatorState.updateSimulator(2));
      history.push(`/pool/maticmum/${vaultAddress.address}/1`);
    } catch (err) {
      setIsDepositing(false);

      Toast.error({
        title: 'Please try again',
        message: (err as Error).message,
      });
    }
  }

  return vault
    ? {
        vault,
        deposit,
        isDepositing,
        underwritingToken,
        withdrawal,
        isApproving,
      }
    : undefined;
}
