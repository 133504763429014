import { addTime, TimePeriod } from 'src/utils/time';

export class UnixTimestamp {
  private constructor(readonly value: number) {}

  static fromDate(date: Date): UnixTimestamp {
    return new UnixTimestamp(Math.round(date.getTime() / 1e3));
  }

  static fromMilliseconds(ms: string | number): UnixTimestamp {
    const msNum = typeof ms === 'string' ? parseInt(ms, 10) : ms;
    return UnixTimestamp.fromDate(new Date(msNum));
  }

  static fromSeconds(seconds: string | number): UnixTimestamp {
    const secondsNum =
      typeof seconds === 'string' ? parseInt(seconds, 10) : seconds;
    return UnixTimestamp.fromMilliseconds(secondsNum * 1e3);
  }

  static now(): UnixTimestamp {
    return UnixTimestamp.fromDate(new Date());
  }

  toDate(): Date {
    return new Date(this.value * 1e3);
  }

  add(quantity: number, period: TimePeriod): UnixTimestamp {
    return UnixTimestamp.fromDate(addTime(this.toDate(), quantity, period));
  }

  lt(other: UnixTimestamp) {
    return this.value < other.value;
  }

  lte(other: UnixTimestamp) {
    return this.value <= other.value;
  }

  gt(other: UnixTimestamp) {
    return this.value > other.value;
  }

  gte(other: UnixTimestamp) {
    return this.value >= other.value;
  }
}
