import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import blank from 'src/assets/transparent-logos/graycircle.svg';
import exitArrow from 'src/assets/exit-arrow.svg';
import switchArrow from 'src/assets/switch-arrow.svg';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import * as blockies from 'blockies-ts';
import { useWallet } from 'src/viewmodels/wallet';
import { SupportedWalletsMap } from 'src/config/wallets';
import {
  getLocalConnectivityData,
  setLocalConnectivityData,
} from 'src/utils/localStore';
import { WalletProviderModal } from 'src/components/Modal/WalletProviderModal';

const FlexBox = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: 1rem;
  margin-right: 0.5rem;
  border-radius: 8px;
`;

const Icon = styled.img`
  height: 20px;
  margin-left: 0.5rem;
`;

const AddressBox = styled.div`
  background: rgba(118, 127, 155, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;

  @media only screen and (max-width: 499px) {
    margin-bottom: 15px;
  }
`;

const AddressText = styled.h2`
  color: var(--text-primary-white);
  font-size: var(--h2-font-size);
  font-weight: 600;
`;

const Content = styled(ModalContent)`
  max-width: 415px !important;
  background-color: var(--modal-blue) !important;

  @media only screen and (max-width: 499px) {
    max-width: 300px !important;
  }
`;

const Header = styled(ModalHeader)`
  color: var(--text-primary-white);
  font-size: var(--h3-font-size);

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

export const WalletButtonModal: React.FC = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOpenProvider,
    isOpen: isOpenProvider,
    onClose: onCloseProvider,
  } = useDisclosure();
  const intl = useIntl();
  const { wallet, disconnect } = useWallet();

  const connectedWalletText = intl.formatMessage({
    id: 'connected-with-wallet-text',
    defaultMessage: 'Connected with ',
  });
  const exitText = intl.formatMessage({
    id: 'exit-modal-text',
    defaultMessage: 'Logout',
  });
  const switchWalletText = intl.formatMessage({
    id: 'switch-wallet-text',
    defaultMessage: 'Switch wallet provider',
  });
  const walletDisconnect = () => {
    const connectivityStatus = getLocalConnectivityData();
    disconnect();
    setLocalConnectivityData({
      ...connectivityStatus,
      connected: false,
    });
  };

  const account = wallet?.account.toString();

  const displayedAddress = `${account?.substr(0, 6)}...${account?.substr(
    38,
    42
  )}`;
  const userIcon = blockies.create({ seed: account }).toDataURL();

  const walletIcon = wallet ? SupportedWalletsMap[wallet.type].iconSrc : blank;

  const walletName = wallet ? SupportedWalletsMap[wallet.type].displayName : '';

  return (
    <>
      <Button
        _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
        _active={{ color: 'transparent' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        px="var(--button-px)"
        py="var(--button-py)"
        textColor="white"
        background="var(--background-blue)"
        fontSize="var(--p-font-size)"
        onClick={onOpen}
      >
        <Image src={userIcon} alt="blockie" />
        {displayedAddress}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="var(--overlay-modal-black)" />
        <Content>
          <Header>
            <FlexBox>
              {connectedWalletText}
              {walletName}
              <Icon className="wallet-icon" src={walletIcon} alt="image" />
            </FlexBox>
          </Header>
          <ModalCloseButton
            style={{ color: 'white', marginTop: 8 }}
            _focus={{ outline: 'none' }}
            _hover={{ filter: 'brightness(65%)' }}
          />
          <ModalBody marginBottom={5}>
            <AddressBox>
              <AddressText>{displayedAddress}</AddressText>
            </AddressBox>

            <FlexBox
              style={{ justifyContent: 'space-between' }}
              className="walletbuttonmodal-buttons-flexbox"
            >
              <Button
                className="logout-button"
                _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
                _active={{ color: 'transparent' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                px="var(--button-px)"
                py="var(--button-py)"
                textColor="white"
                background="var(--background-blue)"
                fontSize="var(--p-font-size)"
                onClick={walletDisconnect}
              >
                {exitText}
                <Icon src={exitArrow} alt="arrow" />
              </Button>

              <Button
                _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
                _active={{ color: 'transparent' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                px="var(--button-px)"
                py="var(--button-py)"
                textColor="white"
                background="var(--components-primary-blue)"
                fontSize="var(--p-font-size)"
                onClick={onOpenProvider}
              >
                {switchWalletText}
                <Icon src={switchArrow} alt="arrow" />
              </Button>
              <WalletProviderModal
                isOpen={isOpenProvider}
                onClose={onCloseProvider}
              />
            </FlexBox>
          </ModalBody>
        </Content>
      </Modal>
    </>
  );
};
