/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from 'graphql-request';
import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { TokenAmount } from 'src/models/TokenAmount';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { Subgraph } from 'src/services/subgraph';
import { resolveNetworkFromName } from 'src/utils/network';

export enum ActivityType {
  Purchase = 'PURCHASE_PROTECTION',
  Underwrite = 'DEPOSIT_FUNDS',
  Withdraw = 'WITHDRAW_TOKENS',
  Claim = 'CLAIM_TOKENS',
  BurnPosition = 'BURN_POSITION',
  SendPosition = 'SEND_POSITION',
  ReceivePosition = 'RECEIVE_POSITION',
}

type Activity = {
  type: ActivityType;
  network: Network;
  txHash: string;
  date: Date;
};

export type PurchaseActivity = Activity & {
  protected: TokenAmount;
  premiumPaid: TokenAmount;
};

export type UnderwriteActivity = Activity & {
  deposited: TokenAmount;
  sharesReceived: TokenAmount;
};

export type WithdrawActivity = Activity & {
  withdrawn: TokenAmount;
  sharesReturned: TokenAmount;
};

export type ClaimActivity = Activity & {
  payout: TokenAmount;
};

export type BurnPositionActivity = Activity;
export type SendPositionActivity = Activity;
export type ReceivePositionActivity = Activity;

export enum VaultStatus {
  Open,
  Paused,
  Closed,
}

export enum PoolStatus {
  Open,
  Paused,
  Hacked,
  Closed,
}

export type PolicyholderPosition = {
  protectedTokenInfo: {
    address: NetworkAddress;
    symbol: string;
  };
  protectedTokenAmount: TokenAmount;
  network: Network;
  status: PoolStatus;
  expiry: Date;
};

export type UnderwriterPosition = {
  underwritingTokenInfo: {
    address: NetworkAddress;
    symbol: string;
  };
  vault: {
    address: string;
  };
  shares: TokenAmount;
  network: Network;
  name: string; // TODO(drew): CMS
  status: VaultStatus;
  expiry: Date;
};

export type UserActivity =
  | PurchaseActivity
  | UnderwriteActivity
  | WithdrawActivity
  | ClaimActivity
  | BurnPositionActivity
  | SendPositionActivity
  | ReceivePositionActivity;

export type UserPortfolio = {
  policyholderPositions: PolicyholderPosition[];
  underwriterPositions: UnderwriterPosition[];
  userActivities: UserActivity[];
};

const UnderwriterPositions = gql`
  query UnderwritingPositions($userId: ID!) {
    user(id: $userId) {
      id
      underwriterPositions(orderBy: createdAtTimestamp, orderDirection: desc) {
        shares
        vault {
          id
          network
          paused
          expireTimestamp
          underwritingToken {
            id
            symbol
            decimals
          }
        }
      }
    }
  }
`;

const PolicyholderPositions = gql`
  query PolicyholderPositions($userId: ID!) {
    user(id: $userId) {
      id
      policyHolderPositions(orderBy: createdAtTimestamp, orderDirection: desc) {
        protectedTokenAmount
        pool {
          protectedToken {
            id
            symbol
            decimals
          }
          hackedTimestamp
          vault {
            network
            paused
            expireTimestamp
          }
        }
      }
    }
  }
`;

const UserActivities = gql`
  query UserActivities($userId: ID!) {
    user(id: $userId) {
      id
      activities(orderBy: activityTimestamp, orderDirection: desc) {
        txHash
        activityType
        activityTimestamp
        shares
        premiumPaid
        positionID
        amount
        pool {
          protectedToken {
            id
            symbol
            decimals
          }
        }
        vault {
          underwritingToken {
            id
            symbol
            decimals
          }
        }
      }
    }
  }
`;

export class PortfolioService {
  private constructor(private readonly subgraph: Subgraph) {}

  static fromNetwork(network: Network): PortfolioService {
    return new PortfolioService(Subgraph.fromNetwork(network));
  }

  async getUnderwriterPositions(
    userId: Address
  ): Promise<UnderwriterPosition[]> {
    const result: any = await this.subgraph.query(UnderwriterPositions, {
      userId: userId.toLowercaseString(),
    });

    return result.user.underwriterPositions.map((position: any) => ({
      underwritingTokenInfo: {
        address: NetworkAddress.fromStrings(
          position.vault.network.toLowerCase(),
          position.vault.underwritingToken.id
        ),
        symbol: position.vault.underwritingToken.symbol as string,
      },
      shares: TokenAmount.from(
        {
          symbol: 'shares',
          decimals: parseInt(position.vault.underwritingToken.decimals, 10),
        },
        position.shares
      ),
      network: resolveNetworkFromName(position.vault.network.toLowerCase()),
      name: 'VAULT NAME',
      status: position.vault.paused
        ? VaultStatus.Paused
        : UnixTimestamp.fromSeconds(position.vault.expireTimestamp).gt(
            UnixTimestamp.now()
          )
        ? VaultStatus.Open
        : VaultStatus.Closed,
      expiry: UnixTimestamp.fromSeconds(
        position.vault.expireTimestamp
      ).toDate(),
      vault: {
        address: position.vault.id,
      },
    }));
  }

  async getPolicyholderPositions(
    userId: Address
  ): Promise<PolicyholderPosition[]> {
    const result: any = await this.subgraph.query(PolicyholderPositions, {
      userId: userId.toLowercaseString(),
    });

    return result.user.policyHolderPositions.map((position: any) => ({
      protectedTokenInfo: {
        address: NetworkAddress.fromStrings(
          position.pool.vault.network.toLowerCase(),
          position.pool.protectedToken.id
        ),
        symbol: position.pool.protectedToken.symbol as string,
      },
      protectedTokenAmount: TokenAmount.from(
        {
          symbol: position.pool.protectedToken.symbol as string,
          decimals: parseInt(position.pool.protectedToken.decimals, 10),
        },
        position.protectedTokenAmount as string
      ),
      network: resolveNetworkFromName(
        position.pool.vault.network.toLowerCase()
      ),
      status: position.pool.hackedTimestamp
        ? PoolStatus.Hacked
        : position.pool.vault.paused
        ? PoolStatus.Paused
        : UnixTimestamp.fromSeconds(position.pool.vault.expireTimestamp).gt(
            UnixTimestamp.now()
          )
        ? PoolStatus.Open
        : PoolStatus.Closed,
      expiry: UnixTimestamp.fromSeconds(
        position.pool.vault.expireTimestamp
      ).toDate(),
    }));
  }

  async getUserActivities(userId: Address): Promise<UserActivity[]> {
    const result: any = await this.subgraph.query(UserActivities, {
      userId: userId.toLowercaseString(),
    });

    return result.user.activities.map((activity: any) => ({
      ...{
        [ActivityType.Purchase]: () =>
          ({
            protected: TokenAmount.from(
              {
                symbol: activity?.pool?.protectedToken?.symbol || 'UNKNOWN',
                decimals: activity?.pool?.protectedToken?.decimals || 0,
              },
              activity.amount
            ),
            premiumPaid: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.premiumPaid
            ),
          } as PurchaseActivity),
        [ActivityType.Underwrite]: () =>
          ({
            deposited: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.amount
            ),
            sharesReceived: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.shares
            ),
          } as UnderwriteActivity),
        [ActivityType.Withdraw]: () =>
          ({
            withdrawn: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.amount
            ),
            sharesReturned: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.shares
            ),
          } as WithdrawActivity),
        [ActivityType.Claim]: () =>
          ({
            payout: TokenAmount.from(
              {
                symbol: activity.vault.underwritingToken.symbol as string,
                decimals: activity.vault.underwritingToken.decimals,
              },
              activity.amount
            ),
          } as ClaimActivity),
        [ActivityType.SendPosition]: () => ({} as SendPositionActivity),
        [ActivityType.ReceivePosition]: () => ({} as ReceivePositionActivity),
        [ActivityType.BurnPosition]: () => ({} as BurnPositionActivity),
      }[activity.activityType as ActivityType](),
      type: activity.activityType as ActivityType,
      txHash: activity.txHash as string,
      date: UnixTimestamp.fromSeconds(activity.activityTimestamp).toDate(),
      network: this.subgraph.network,
    }));
  }

  async getUserPortfolio(userId: Address): Promise<UserPortfolio> {
    const [underwriterPositions, policyholderPositions, userActivities] =
      await Promise.all([
        this.getUnderwriterPositions(userId),
        this.getPolicyholderPositions(userId),
        this.getUserActivities(userId),
      ]);

    return {
      underwriterPositions,
      policyholderPositions,
      userActivities,
    };
  }
}
