import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

interface Props {
  tooltipText: string;
  position?: boolean;
}

export const ToolTip: React.FC<Props> = ({ tooltipText, position }) => {
  return (
    <>
      {position ? (
        <>
          <Tooltip label={tooltipText} fontSize="sm" w="75%" placement="bottom">
            <QuestionOutlineIcon mt="1px" />
          </Tooltip>
        </>
      ) : (
        <>
          &nbsp;&nbsp;
          <Tooltip label={tooltipText} fontSize="sm" w="75%" placement="bottom">
            <QuestionOutlineIcon mb={0.5} />
          </Tooltip>
        </>
      )}
    </>
  );
};

ToolTip.defaultProps = {
  position: false,
};
