import { ethers } from 'ethers';
import { deployments } from 'src/config/deployments';
import { Address } from 'src/models/Address';
import { Network as NetworkType, NetworkID } from 'src/models/Network';
import { toHexString } from 'src/utils/number';
import { RHNetworkConfig } from './types';

// const mainnet = '/network-images/mainnet.jpeg';
// const ropsten = '/network-images/ropsten.jpeg';
// const polygon = '/network-images/polygon.jpeg';
// const arbitrum = '/network-images/arbitrum.jpeg';
// const avalanche = '/network-images/avalanche.jpeg';
// const fantom = '/network-images/fantom.jpeg';

const NetworkIcon = {
  [NetworkID.mainnet]: '/network-images/mainnet.jpeg',
  [NetworkID.ropsten]: '/network-images/mainnet.jpeg',
  [NetworkID.rinkeby]: '/network-images/mainnet.jpeg',
  [NetworkID.arbitrum]: '/network-images/arbitrum.jpeg',
  [NetworkID.arbitrumRinkeby]: '/network-images/arbitrum.jpeg',
  [NetworkID.optimism]: '/network-images/optimism.png',
  [NetworkID.optimismKovan]: '/network-images/optimism.png',
  [NetworkID.polygon]: '/network-images/polygon.jpeg',
  [NetworkID.polygonMumbai]: '/network-images/polygon.jpeg',
  [NetworkID.avalanche]: '/network-images/avalanche.jpeg',
  [NetworkID.avalancheFuji]: '/network-images/avalanche.jpeg',
  [NetworkID.fantom]: '/network-images/fantom.jpeg',
  [NetworkID.fantomTestnet]: '/network-images/fantom.jpeg',
  [NetworkID.bsc]: '/network-images/bsc.jpeg',
  [NetworkID.bscTestnet]: '/network-images/bsc.jpeg',
  [NetworkID.unsupported]: '/network-images/mainnet.jpeg', // TODO(zak) | TODO(afif): Update to a better asset
};

const ChainID = {
  [NetworkID.mainnet]: 1,
  [NetworkID.ropsten]: 3,
  [NetworkID.rinkeby]: 4,
  [NetworkID.arbitrum]: 0xa4b1,
  [NetworkID.arbitrumRinkeby]: 421611,
  [NetworkID.optimism]: 10,
  [NetworkID.optimismKovan]: 69,
  [NetworkID.polygon]: 137,
  [NetworkID.polygonMumbai]: 80001,
  [NetworkID.avalanche]: 43114,
  [NetworkID.avalancheFuji]: 43113,
  [NetworkID.fantom]: 250,
  [NetworkID.fantomTestnet]: 4002,
  [NetworkID.bsc]: 56,
  [NetworkID.bscTestnet]: 97,
  [NetworkID.unsupported]: 99999,
};

export const UNSUPPORTED_NETWORK: NetworkType<RHNetworkConfig> = {
  chainId: ChainID[NetworkID.unsupported],
  id: NetworkID.unsupported,
  displayName: 'Unsupported Network',
  imgSrc: NetworkIcon[NetworkID.unsupported],
  config: {
    addressMap: {
      router: Address.Zero, // TODO
      ticketToken: Address.Zero, // TODO
    },
    urlMap: {
      subgraph: 'TODO',
    },
    walletConfig: {
      chainId: '0x1',
      chainName: 'Ethereum Mainnet',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [`${process.env.REACT_APP_RPC_URL_MAINNET}`],
      blockExplorerUrls: ['https://etherscan.io'],
    },
  },
  defaultProvider: new ethers.providers.AlchemyProvider(
    ChainID[NetworkID.mainnet],
    process.env.REACT_APP_ALCHEMY_KEY
  ) as ethers.providers.Provider,
};

export const SUPPORTED_NETWORKS: NetworkType<RHNetworkConfig>[] = [
  {
    chainId: ChainID[NetworkID.mainnet],
    id: NetworkID.mainnet,
    displayName: 'Ethereum',
    imgSrc: NetworkIcon[NetworkID.mainnet],
    config: {
      addressMap: {
        router: deployments[NetworkID.mainnet].router,
        ticketToken: deployments[NetworkID.mainnet].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.mainnet].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.mainnet]),
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_MAINNET}`],
        blockExplorerUrls: ['https://etherscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      ChainID[NetworkID.mainnet],
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.ropsten],
    id: NetworkID.ropsten,
    displayName: '🛠️ Ropsten',
    imgSrc: NetworkIcon[NetworkID.ropsten],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.ropsten].router,
        ticketToken: deployments[NetworkID.ropsten].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.ropsten].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.ropsten]),
        chainName: 'Ropsten Ethereum Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_ROPSTEN}`],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      ChainID[NetworkID.ropsten],
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.rinkeby],
    id: NetworkID.rinkeby,
    displayName: '🛠️ Rinkeby-ETH',
    imgSrc: NetworkIcon[NetworkID.rinkeby],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.rinkeby].router,
        ticketToken: deployments[NetworkID.rinkeby].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.rinkeby].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.rinkeby]),
        chainName: 'Rinkeby Ethereum Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_ROPSTEN}`],
        blockExplorerUrls: ['https://rinkeby.etherscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      ChainID[NetworkID.ropsten],
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.arbitrum],
    id: NetworkID.arbitrum,
    displayName: 'Arbitrum',
    imgSrc: NetworkIcon[NetworkID.arbitrum],
    config: {
      addressMap: {
        router: deployments[NetworkID.arbitrum].router,
        ticketToken: deployments[NetworkID.arbitrum].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.arbitrum].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.arbitrum]),
        chainName: 'Arbitrum',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_ARBITRUM}`],
        blockExplorerUrls: ['https://arbiscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.arbitrum],
        name: NetworkID.arbitrum,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.arbitrumRinkeby],
    id: NetworkID.arbitrumRinkeby,
    displayName: '🛠️ Rinkeby',
    imgSrc: NetworkIcon[NetworkID.arbitrumRinkeby],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.arbitrumRinkeby].router,
        ticketToken: deployments[NetworkID.arbitrumRinkeby].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.arbitrumRinkeby].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.arbitrumRinkeby]),
        chainName: 'Arbitrum Rinkeby',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_ARBITRUM_RINKEBY}`],
        blockExplorerUrls: ['https://rinkeby-explorer.arbitrum.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.arbitrumRinkeby],
        name: NetworkID.arbitrumRinkeby,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.optimism],
    id: NetworkID.optimism,
    displayName: 'Optimism',
    imgSrc: NetworkIcon[NetworkID.optimism],
    config: {
      addressMap: {
        router: deployments[NetworkID.optimism].router,
        ticketToken: deployments[NetworkID.optimism].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.optimism].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.optimism]),
        chainName: 'Optimism',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_OPTIMISM}`],
        blockExplorerUrls: ['https://optimistic.etherscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.optimism],
        name: NetworkID.optimism,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.optimismKovan],
    id: NetworkID.optimismKovan,
    displayName: '🛠️ Kovan',
    imgSrc: NetworkIcon[NetworkID.optimismKovan],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.optimismKovan].router,
        ticketToken: deployments[NetworkID.optimismKovan].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.optimismKovan].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.optimismKovan]),
        chainName: 'Optimism Kovan',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_OPTIMISM_KOVAN}`],
        blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.optimismKovan],
        name: NetworkID.optimismKovan,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.polygon],
    id: NetworkID.polygon,
    displayName: 'Polygon',
    imgSrc: NetworkIcon[NetworkID.polygon],
    config: {
      addressMap: {
        router: deployments[NetworkID.polygon].router,
        ticketToken: deployments[NetworkID.polygon].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.polygon].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.polygon]),
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_POLYGON}`],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.polygon],
        name: NetworkID.polygon,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.polygonMumbai],
    id: NetworkID.polygonMumbai,
    displayName: '🛠️ Mumbai',
    imgSrc: NetworkIcon[NetworkID.polygonMumbai],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.polygonMumbai].router,
        ticketToken: deployments[NetworkID.polygonMumbai].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.polygonMumbai].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.polygonMumbai]),
        chainName: 'Polygon Mumbai Testnet',
        nativeCurrency: {
          name: 'Matic',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_POLYGON_MUMBAI}`],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
      },
    },
    defaultProvider: new ethers.providers.AlchemyProvider(
      {
        chainId: ChainID[NetworkID.polygonMumbai],
        name: NetworkID.polygonMumbai,
      },
      process.env.REACT_APP_ALCHEMY_KEY
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.avalanche],
    id: NetworkID.avalanche,
    displayName: 'Avalanche',
    imgSrc: NetworkIcon[NetworkID.avalanche],
    config: {
      addressMap: {
        router: deployments[NetworkID.avalanche].router,
        ticketToken: deployments[NetworkID.avalanche].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.avalanche].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.avalanche]),
        chainName: 'Avalanche',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_AVALANCHE}`],
        blockExplorerUrls: ['https://snowtrace.io/'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_AVALANCHE,
      {
        chainId: ChainID[NetworkID.avalanche],
        name: NetworkID.avalanche,
      }
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.avalancheFuji],
    id: NetworkID.avalancheFuji,
    displayName: '🛠️ Fuji',
    imgSrc: NetworkIcon[NetworkID.avalancheFuji],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.avalancheFuji].router,
        ticketToken: deployments[NetworkID.avalancheFuji].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.avalancheFuji].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.avalancheFuji]),
        chainName: 'Avalanche Fuji Testnet',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_AVALANCHE_FUJI}`],
        blockExplorerUrls: ['https://testnet.snowtrace.io/'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_AVALANCHE_FUJI,
      {
        chainId: ChainID[NetworkID.avalancheFuji],
        name: NetworkID.avalancheFuji,
      }
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.fantom],
    id: NetworkID.fantom,
    displayName: 'Fantom',
    imgSrc: NetworkIcon[NetworkID.fantom],
    config: {
      addressMap: {
        router: deployments[NetworkID.fantom].router,
        ticketToken: deployments[NetworkID.fantom].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.fantom].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.fantom]),
        chainName: 'Fantom',
        nativeCurrency: {
          name: 'Fantom',
          symbol: 'FTM',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_FANTOM}`],
        blockExplorerUrls: ['https://ftmscan.com'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_FANTOM,
      {
        chainId: ChainID[NetworkID.fantom],
        name: NetworkID.fantom,
      }
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.fantomTestnet],
    id: NetworkID.fantomTestnet,
    displayName: '🛠️ Ftm. Testnet',
    imgSrc: NetworkIcon[NetworkID.fantomTestnet],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.fantomTestnet].router,
        ticketToken: deployments[NetworkID.fantomTestnet].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.fantomTestnet].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.fantomTestnet]),
        chainName: 'Fantom Testnet',
        nativeCurrency: {
          name: 'Fantom',
          symbol: 'FTM',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_FANTOM_TESTNET}`],
        blockExplorerUrls: ['https://testnet.ftmscan.com'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_FANTOM_TESTNET,
      {
        chainId: ChainID[NetworkID.fantomTestnet],
        name: NetworkID.fantomTestnet,
      }
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.bsc],
    id: NetworkID.bsc,
    displayName: 'BSC',
    imgSrc: NetworkIcon[NetworkID.bsc],
    config: {
      addressMap: {
        router: deployments[NetworkID.bsc].router,
        ticketToken: deployments[NetworkID.bsc].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.bsc].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.bsc]),
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_BSC}`],
        blockExplorerUrls: ['https://bscscan.com'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_BSC,
      {
        chainId: ChainID[NetworkID.bsc],
        name: NetworkID.bsc,
      }
    ) as ethers.providers.Provider,
  },
  {
    chainId: ChainID[NetworkID.bscTestnet],
    id: NetworkID.bscTestnet,
    displayName: '🛠️ BSC Testnet',
    imgSrc: NetworkIcon[NetworkID.bscTestnet],
    isTestnet: true,
    config: {
      addressMap: {
        router: deployments[NetworkID.bscTestnet].router,
        ticketToken: deployments[NetworkID.bscTestnet].ticket,
      },
      urlMap: {
        subgraph: deployments[NetworkID.bscTestnet].subgraph,
      },
      walletConfig: {
        chainId: toHexString(ChainID[NetworkID.bscTestnet]),
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: [`${process.env.REACT_APP_RPC_URL_BSC_TESTNET}`],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
      },
    },
    defaultProvider: new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL_BSC_TESTNET,
      {
        chainId: ChainID[NetworkID.bscTestnet],
        name: NetworkID.bscTestnet,
      }
    ) as ethers.providers.Provider,
  },
].filter((network) =>
  process.env.REACT_APP_ENABLED_NETWORKS?.split(',')?.includes(network.id)
);

export const SUPPORTED_CHAIN_IDS = SUPPORTED_NETWORKS.map(
  (network) => network.chainId
);
