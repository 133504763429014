import React from 'react';
import { Box } from '@chakra-ui/react';
import { PoolCreationForm } from 'src/components/Simulator/PoolCreationForm';

export const HackSimulatorForm: React.FC = () => {
  return (
    <Box
      w="auto"
      maxW="800px"
      minW="200px"
      pb="40px"
      px="20px"
      m="auto"
      justifyContent="center"
      alignContent="center"
    >
      <PoolCreationForm />
    </Box>
  );
};
