import { useToast } from '@chakra-ui/react';
import React from 'react';
import { Optional } from 'src/types/Optional';

export type ToastPayload = {
  title: string;
  message: Optional<React.ReactNode>;
};

interface ToastInterface {
  success(payload: ToastPayload): void;
  error(payload: ToastPayload): void;
  info(payload: ToastPayload): void;
}

export default function useCustomToast(): ToastInterface {
  const toast = useToast();

  const showToast =
    (status: 'success' | 'error' | 'info') =>
    ({ title, message }: ToastPayload) => {
      // We can parameterize other parts of this as our needs change, for now these defaults work
      toast({
        title,
        description: message,
        status,
        duration: 6000,
        position: 'top-right',
        isClosable: true,
      });
    };

  return {
    success: showToast('success'),
    error: showToast('error'),
    info: showToast('info'),
  };
}
