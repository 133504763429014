import { TokenAmount } from 'src/models/TokenAmount';
import { FractionStructOutput } from 'src/types/contracts/RHVault';
import { TokenWithAddress } from 'src/types/contracts/Token';
import { Optional } from 'src/types/Optional';

export function computePayout(
  amountInProtected: Optional<TokenAmount>,
  payoutRatio: Optional<FractionStructOutput>,
  underwritingToken: Optional<TokenWithAddress>
): Optional<TokenAmount> {
  if (!amountInProtected || !payoutRatio || !underwritingToken) {
    return undefined;
  }

  const payout = amountInProtected
    .toBigNumber()
    .mul(payoutRatio.numerator)
    .div(payoutRatio.denominator);

  return TokenAmount.from(underwritingToken, payout);
}
