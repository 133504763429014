/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkID } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { PoolSubgraphResult } from 'src/services/pool';
import { UserPortfolio } from 'src/services/portfolio';
import { VaultSubgraphResult } from 'src/services/vault';
import { PositionID, VaultID } from 'src/state/contracts';

// This acts as a caching layer (only data) for subgraph query results
// The user of this state needs to abstract a cache invalidation policy on top of this and it is not the responsibility
// of the redux store to invalidate the cache

export type SubgraphQuery = string;

export type Cache<T> = {
  updatedAt: Date;
  value: T;
};

interface SubgraphState {
  underwritingPositions: Record<
    NetworkID,
    Record<VaultID, Cache<PositionID[]>>
  >;
  policyholderPositions: Record<
    NetworkID,
    Record<VaultID, Cache<PositionID[]>>
  >;
  vaults: Record<NetworkID, VaultSubgraphResult[]>;
  pools: Record<NetworkID, PoolSubgraphResult[]>;
  portfolio: Record<NetworkID, UserPortfolio>;
}

const initialState: SubgraphState = {
  underwritingPositions: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
  policyholderPositions: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
  vaults: {
    [NetworkID.mainnet]: [],
    [NetworkID.ropsten]: [],
    [NetworkID.rinkeby]: [],
    [NetworkID.arbitrum]: [],
    [NetworkID.arbitrumRinkeby]: [],
    [NetworkID.optimism]: [],
    [NetworkID.optimismKovan]: [],
    [NetworkID.polygon]: [],
    [NetworkID.polygonMumbai]: [],
    [NetworkID.avalanche]: [],
    [NetworkID.avalancheFuji]: [],
    [NetworkID.fantom]: [],
    [NetworkID.fantomTestnet]: [],
    [NetworkID.bsc]: [],
    [NetworkID.bscTestnet]: [],
    [NetworkID.unsupported]: [],
  },
  pools: {
    [NetworkID.mainnet]: [],
    [NetworkID.ropsten]: [],
    [NetworkID.rinkeby]: [],
    [NetworkID.arbitrum]: [],
    [NetworkID.arbitrumRinkeby]: [],
    [NetworkID.optimism]: [],
    [NetworkID.optimismKovan]: [],
    [NetworkID.polygon]: [],
    [NetworkID.polygonMumbai]: [],
    [NetworkID.avalanche]: [],
    [NetworkID.avalancheFuji]: [],
    [NetworkID.fantom]: [],
    [NetworkID.fantomTestnet]: [],
    [NetworkID.bsc]: [],
    [NetworkID.bscTestnet]: [],
    [NetworkID.unsupported]: [],
  },
  portfolio: {
    [NetworkID.mainnet]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.ropsten]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.rinkeby]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.arbitrum]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.arbitrumRinkeby]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.optimism]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.optimismKovan]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.polygon]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.polygonMumbai]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.avalanche]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.avalancheFuji]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.unsupported]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.fantom]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.fantomTestnet]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.bsc]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
    [NetworkID.bscTestnet]: {
      underwriterPositions: [],
      policyholderPositions: [],
      userActivities: [],
    },
  },
};

const subgraphSlice = createSlice({
  name: 'subgraph',
  initialState,
  reducers: {
    loadUnderwritingPositionsBatch: (
      state,
      {
        payload: { networkId, vaultIdToPositionIdsMap },
      }: PayloadAction<{
        networkId: NetworkID;
        vaultIdToPositionIdsMap: { [vaultId: VaultID]: PositionID[] };
      }>
    ) => {
      Object.keys(vaultIdToPositionIdsMap).forEach((vaultId) => {
        state.underwritingPositions[networkId][vaultId] = {
          updatedAt: new Date(),
          value: [...vaultIdToPositionIdsMap[vaultId]],
        };
      });
    },
    loadUnderwritingPositions: (
      state,
      {
        payload: {
          vaultAddress: { network, address },
          positionIds,
        },
      }: PayloadAction<{
        vaultAddress: NetworkAddress;
        positionIds: PositionID[];
      }>
    ) => {
      state.underwritingPositions[network.id][address.toString()] = {
        updatedAt: new Date(),
        value: [...positionIds],
      };
    },
    clearUnderwritingPositions: (
      state,
      {
        payload: {
          vaultAddress: { network, address },
        },
      }: PayloadAction<{
        vaultAddress: NetworkAddress;
      }>
    ) => {
      delete state.underwritingPositions[network.id][address.toString()];
    },
    loadPolicyholderPositionsBatch: (
      state,
      {
        payload: { networkId, vaultIdToPositionIdsMap },
      }: PayloadAction<{
        networkId: NetworkID;
        vaultIdToPositionIdsMap: { [vaultId: VaultID]: PositionID[] };
      }>
    ) => {
      Object.keys(vaultIdToPositionIdsMap).forEach((vaultId) => {
        state.policyholderPositions[networkId][vaultId] = {
          updatedAt: new Date(),
          value: [...vaultIdToPositionIdsMap[vaultId]],
        };
      });
    },
    loadPolicyholderPositions: (
      state,
      {
        payload: {
          vaultAddress: { network, address },
          positionIds,
        },
      }: PayloadAction<{
        vaultAddress: NetworkAddress;
        positionIds: PositionID[];
      }>
    ) => {
      state.policyholderPositions[network.id][address.toString()] = {
        updatedAt: new Date(),
        value: [...positionIds],
      };
    },
    clearPolicyholderPositions: (
      state,
      {
        payload: {
          vaultAddress: { network, address },
        },
      }: PayloadAction<{
        vaultAddress: NetworkAddress;
      }>
    ) => {
      delete state.policyholderPositions[network.id][address.toString()];
    },
    loadVaults: (
      state,
      {
        payload: { vaults, networkId },
      }: PayloadAction<{ networkId: NetworkID; vaults: VaultSubgraphResult[] }>
    ) => {
      state.vaults[networkId] = vaults;
    },
    loadPools: (
      state,
      {
        payload: { pools, networkId },
      }: PayloadAction<{ networkId: NetworkID; pools: PoolSubgraphResult[] }>
    ) => {
      state.pools[networkId] = pools;
    },
    loadUserPortfolio: (
      state,
      {
        payload: { networkId, portfolio },
      }: PayloadAction<{ networkId: NetworkID; portfolio: UserPortfolio }>
    ) => {
      state.portfolio[networkId] = portfolio;
    },
  },
});

export const subgraphState = {
  ...subgraphSlice.actions,
  reducer: subgraphSlice.reducer,
};
