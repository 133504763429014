/* eslint-disable @typescript-eslint/no-explicit-any */
import { cancel, fork, put, take } from 'redux-saga/effects';
import { disconnectWallet } from 'src/state/actions';
import { web3WalletSaga } from 'src/state/sagas/wallet/web3Wallet';
import { walletState } from 'src/state/wallet';

function* disconnectWalletWorker() {
  yield put(walletState.removeWallet());
}

export function* walletSaga() {
  while (true) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const web3WalletJob = yield fork(web3WalletSaga);
    yield take(disconnectWallet.type);
    yield cancel(web3WalletJob);
    yield disconnectWalletWorker();
  }
}
