import styled from 'styled-components';
import React from 'react';

const StyledLink = styled.a`
  color: white;
  font-weight: bold;
  text-decoration: underline;
`;

export const message = (url: string): React.ReactFragment => {
  return (
    <StyledLink target="_blank" href={url}>
      View transaction
    </StyledLink>
  );
};
