/* eslint-disable camelcase */
import { BigNumber, ContractTransaction, Signer } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { TokenAmount } from 'src/models/TokenAmount';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { useCMSPool } from 'src/services/cms';
import { useAppSelector } from 'src/state';
import {
  loadUserVaultPolicyholderPositions,
  startVaultSync,
} from 'src/state/actions';
import { Pool, PoolID, Token, Vault } from 'src/state/contracts';
import { RHVault__factory } from 'src/types/contracts/factories/RHVault__factory';
import { Optional } from 'src/types/Optional';
import { permitTokenTransfer } from 'src/utils/ethereum/permit';
import { logger } from 'src/utils/logger';
import { TimePeriod } from 'src/utils/time';
import { useToken } from 'src/viewmodels/token';
import { useWallet } from 'src/viewmodels/wallet';
import useToaster from 'src/hooks/useToast';
import { message } from 'src/components/OrderBox/MessageTransaction';
import { MessageError, RHError, UncaughtError } from 'src/errors';
import { useNetwork } from 'src/viewmodels/network';
import { Wallet } from 'src/models/Wallet';
import { RHVault } from 'src/types/contracts/RHVault';
import { bnMin } from 'src/utils/bignumber';
import { getReplacedTx, isReplacedTxError } from 'src/utils/transaction';
import { useERC20Functions } from 'src/hooks/useERC20Functions';
import { TokenWithAddress } from 'src/types/contracts/Token';
import {
  getEIP721Support,
  getAllowance,
  approveAmount,
} from 'src/utils/transactional';
import { triggerEvent } from 'src/services/tagmanager';
import { GOOGLE_EVENTS } from 'src/constants/constants';
import { simulatorState } from 'src/state/simulator';

export type PoolView = {
  name: string;
  protectedToken: TokenWithAddress;
  underwritingToken: TokenWithAddress;
  underlyingTokensDisplay: string[];
  payoutRatioDisplay: string;
  defaultRatioDisplay: string[];
  poolDescription: string;
  policyholderRisks: string;
  tokenVersion: string | undefined;
  rawState: Pool;
  poolId: PoolID;
};

export enum ClaimStage {
  StartClaim = 'start_claim',
  TerminateClaim = 'terminate_claim',
}

export enum ClaimAction {
  ApproveTokenTransfer = 'approve_token_transfer',
  StartClaim = 'start_claim',
  CancelClaim = 'cancel_claim',
  FinishClaim = 'finish_claim',
}

type ClaimsData = {
  claimInProgress: Optional<{
    claimAmount: TokenAmount;
    payoutAmount: TokenAmount;
  }>;
  currentAction: Optional<ClaimAction>;
  currentStage: ClaimStage;
  totalProtectedAmount: TokenAmount;
  loading: boolean;
};

type ClaimsFunctions = {
  computePayout(claimAmount: TokenAmount): Optional<TokenAmount>;
  startClaim(
    claimAmount: TokenAmount,
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ): Promise<void>;
  cancelClaim(
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ): Promise<void>;
  finishClaim(
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ): Promise<void>;
};

// this is the type of pool.claims (where pool is the return value of usePool() viewmodel)
type ClaimsFields = ClaimsData & ClaimsFunctions;

function getRHVaultContract(wallet: Wallet, vaultAddress: string): RHVault {
  return RHVault__factory.connect(
    vaultAddress,
    wallet.provider.getSigner() as Signer
  );
}

function useStartClaim(
  vault: Optional<NetworkAddress>,
  poolId: Optional<PoolID>
): {
  startClaim: (
    amount: TokenAmount,
    onSuccess: (txHash: string) => unknown,
    onError: (error: RHError) => unknown
  ) => Promise<void>;
  processing: boolean;
  loading: boolean;
} {
  const [processing, setProcessing] = useState(false);
  const { wallet } = useWallet();
  const network = useNetwork();
  const dispatch = useDispatch();
  const currentNetwork = network.currentNetwork.id;
  const vaultNetwork = vault?.network.id;
  const vaultAddress = vault?.address.toString();
  const positionsRecord = useAppSelector((state) =>
    vaultNetwork && vaultAddress
      ? state.contracts.positions[vaultNetwork]?.[vaultAddress]
      : undefined
  );

  const protectedTokenAddress = useAppSelector((state) =>
    vaultNetwork && vaultAddress && poolId
      ? state.contracts.pools?.[vaultNetwork]?.[vaultAddress]?.[poolId]?.value
          ?.config.protectedToken
      : undefined
  );

  const protectedTokenNetworkAddress = useMemo(
    () =>
      protectedTokenAddress && vaultNetwork
        ? NetworkAddress.fromStrings(vaultNetwork, protectedTokenAddress)
        : undefined,
    [protectedTokenAddress, vaultNetwork]
  );

  const protectedToken = useToken(protectedTokenNetworkAddress);

  const protectedTokenERC20 = useERC20Functions(
    protectedToken && protectedTokenNetworkAddress
      ? {
          ...protectedToken,
          address: protectedTokenNetworkAddress,
        }
      : undefined
  );

  const positionsSortedDescByAmount = useMemo(
    () =>
      positionsRecord &&
      // TODO(jason): Add PositionType enum for client-side logic like this
      Object.values(positionsRecord)
        .filter((position) => position.value.state.positionType === 2)
        .sort((a, b) =>
          a.value.state.claimingProtectedAmount.lte(
            b.value.state.claimingProtectedAmount
          )
            ? 1
            : -1
        ),
    [positionsRecord]
  );

  const startClaim = useCallback(
    async (
      amount: TokenAmount,
      onSuccess: (txHash: string) => unknown,
      onError: (error: RHError) => unknown
    ) => {
      setProcessing(true);
      try {
        if (!wallet) {
          throw new MessageError("User's wallet is not connected");
        }

        if (!vaultAddress || !vaultNetwork) {
          throw new MessageError('Vault address not loaded');
        }

        if (!protectedTokenERC20) {
          throw new MessageError('ERC20 is not defined');
        }

        const spender = NetworkAddress.fromStrings(vaultNetwork, vaultAddress);

        const protectedTokenAllowance = await protectedTokenERC20.getAllowance(
          spender
        );

        if (protectedTokenAllowance.toBigNumber().lt(amount.toBigNumber())) {
          const approveTx = await protectedTokenERC20.approve(spender, amount);
          await approveTx.wait(1);
        }

        if (!positionsSortedDescByAmount) {
          throw new MessageError('Positions data not loaded');
        }

        if (currentNetwork !== vaultNetwork) {
          throw new MessageError(
            "User's network does not match vault's network"
          );
        }

        const isClaimInProgress = positionsSortedDescByAmount.find((position) =>
          position.value.state.isClaimingBlock.gt(0)
        );

        if (isClaimInProgress) {
          throw new MessageError(
            'Cannot start claim when a claim is already in progress'
          );
        }

        const positionsToUtilizeArr = [];
        let remainingAmountToProtect = amount.toBigNumber();

        // eslint-disable-next-line no-restricted-syntax
        for (const position of positionsSortedDescByAmount) {
          if (remainingAmountToProtect.gt(0)) {
            remainingAmountToProtect = remainingAmountToProtect.sub(
              bnMin(remainingAmountToProtect, position.value.state.value)
            );
            positionsToUtilizeArr.push(position);
          }
        }

        if (remainingAmountToProtect.gt(0)) {
          throw new MessageError('Claim amount too high');
        }

        const vaultContract = getRHVaultContract(wallet, vaultAddress);

        // const deadline = UnixTimestamp.now().add(2, TimePeriod.Day);

        // const { v, r, s } = await permitTokenTransfer(
        //   wallet,
        //   Address.fromString(vaultAddress),
        //   amount.toBigNumber(),
        //   deadline,
        //   protectedToken
        // );

        let amountToClaim = BigNumber.from(amount.toRawString());

        // TODO(jason): Call approveToken here if the underlying protected token doesn't support permit
        // (this also adds another step)

        let tx: ContractTransaction;
        try {
          tx = await vaultContract.multicall(
            // tx = await vaultContract.multicall([
            // vaultContract.interface.encodeFunctionData('permitToken', [
            //   protectedToken.address.toString(),
            //   {
            //     owner: wallet.account.toString(),
            //     value: amount.toBigNumber(),
            //     deadline: BigNumber.from(deadline.value),
            //     signatures: {
            //       v,
            //       r,
            //       s,
            //     },
            //   },
            // ]),
            positionsToUtilizeArr.map((position) => {
              const positionAmount = bnMin(
                position.value.state.value,
                amountToClaim
              );

              amountToClaim = amountToClaim.gte(positionAmount)
                ? amountToClaim.sub(positionAmount)
                : BigNumber.from(0);

              return vaultContract.interface.encodeFunctionData('startClaim', [
                position.value.id,
                positionAmount,
              ]);
            })
          );

          await tx.wait(1);
        } catch (err) {
          if (isReplacedTxError(err)) {
            tx = getReplacedTx(err);
          } else {
            throw err;
          }
        }

        // Trigger an update of the positions state to get latest state
        dispatch(
          loadUserVaultPolicyholderPositions({
            vaultAddress: NetworkAddress.fromStrings(
              vaultNetwork,
              vaultAddress
            ),
          })
        );

        setProcessing(false);
        // TODO(jason): Revert this
        onSuccess(tx?.hash || 'nothing');
      } catch (err) {
        setProcessing(false);
        if (err instanceof MessageError) {
          onError(err);
        } else {
          onError(new MessageError((err as Error).message));
        }
      }
    },
    [
      wallet,
      vaultAddress,
      vaultNetwork,
      protectedTokenERC20,
      positionsSortedDescByAmount,
      currentNetwork,
      dispatch,
    ]
  );

  return {
    startClaim,
    processing,
    loading:
      !vaultNetwork ||
      !vaultAddress ||
      !positionsRecord ||
      !protectedTokenERC20,
  };
}

function useTerminateClaim(
  terminateFunction: 'finishClaim' | 'cancelClaim',
  vault: Optional<NetworkAddress>
): {
  terminateClaim: (
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ) => Promise<void>;
  processing: boolean;
  loading: boolean;
} {
  const [processing, setProcessing] = useState(false);
  const { wallet } = useWallet();
  const network = useNetwork();

  const currentNetwork = network.currentNetwork.id;
  const vaultNetwork = vault?.network.id;
  const vaultAddress = vault?.address.toString();

  const positionsRecord = useAppSelector((state) =>
    vaultNetwork && vaultAddress
      ? state.contracts.positions[vaultNetwork]?.[vaultAddress]
      : undefined
  );

  const positionsInCurrentClaim = useMemo(
    () =>
      positionsRecord &&
      Object.values(positionsRecord).filter((position) =>
        position.value.state.isClaimingBlock.gt(0)
      ),
    [positionsRecord]
  );

  const dispatch = useDispatch();

  const terminateClaim = useCallback(
    async (
      onSuccess: (txHash: string) => unknown,
      onError: (error: RHError) => unknown
    ) => {
      setProcessing(true);

      try {
        if (!wallet) {
          throw new MessageError("User's wallet is not connected");
        }

        if (!vaultAddress || !vaultNetwork) {
          throw new MessageError('Vault address not loaded');
        }

        if (!positionsInCurrentClaim) {
          throw new MessageError('Positions data not loaded');
        }

        if (currentNetwork !== vaultNetwork) {
          throw new MessageError(
            "User's network does not match vault's network"
          );
        }

        if (positionsInCurrentClaim.length === 0) {
          throw new MessageError('No claims in progress');
        }

        const vaultContract = getRHVaultContract(wallet, vaultAddress);

        let tx: ContractTransaction;

        try {
          tx = await vaultContract.multicall(
            positionsInCurrentClaim.map((position) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              vaultContract.interface.encodeFunctionData(terminateFunction, [
                position.value.id,
              ])
            )
          );

          await tx.wait(1);
        } catch (err) {
          if (isReplacedTxError(err)) {
            tx = getReplacedTx(err);
          } else {
            throw err;
          }
        }

        // Trigger an update of the positions state to get latest state
        dispatch(
          loadUserVaultPolicyholderPositions({
            vaultAddress: NetworkAddress.fromStrings(
              vaultNetwork,
              vaultAddress
            ),
          })
        );

        setProcessing(false);
        onSuccess(tx.hash);
      } catch (err) {
        setProcessing(false);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorCode = (err as any)?.error?.data?.originalError?.code;

        if (err instanceof MessageError) {
          onError(err);
        } else if (errorCode === 3) {
          onError(new MessageError('Pool is not in a hacked state'));
        } else {
          onError(new UncaughtError(err as Error));
        }
      }
    },
    [
      currentNetwork,
      dispatch,
      positionsInCurrentClaim,
      terminateFunction,
      vaultAddress,
      vaultNetwork,
      wallet,
    ]
  );

  return {
    terminateClaim,
    processing,
    loading: !vaultNetwork || !vaultAddress || !positionsRecord,
  };
}

function useFinishClaim(vault: Optional<NetworkAddress>): {
  finishClaim: (
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ) => Promise<void>;
  processing: boolean;
  loading: boolean;
} {
  const {
    terminateClaim: finishClaim,
    processing,
    loading,
  } = useTerminateClaim('finishClaim', vault);

  return {
    finishClaim,
    processing,
    loading,
  };
}

function useCancelClaim(vault: Optional<NetworkAddress>): {
  cancelClaim: (
    onSuccess: (txHash: string) => unknown,
    onFailure: (error: RHError) => unknown
  ) => Promise<void>;
  processing: boolean;
  loading: boolean;
} {
  const {
    terminateClaim: cancelClaim,
    processing,
    loading,
  } = useTerminateClaim('cancelClaim', vault);

  return {
    cancelClaim,
    processing,
    loading,
  };
}

function useClaims(
  input: Optional<{
    vaultAddress: NetworkAddress;
    poolId: PoolID;
  }>
): Optional<ClaimsFields> {
  const { vaultAddress, poolId } = input || {};

  const {
    startClaim,
    processing: startClaimProcessing,
    loading: startClaimLoading,
  } = useStartClaim(vaultAddress, poolId);
  const {
    finishClaim,
    processing: finishClaimProcessing,
    loading: finishClaimLoading,
  } = useFinishClaim(vaultAddress);
  const {
    cancelClaim,
    processing: cancelClaimProcessing,
    loading: cancelClaimLoading,
  } = useCancelClaim(vaultAddress);

  const underwritingTokenAddress = useAppSelector((state) =>
    vaultAddress
      ? state.contracts.vaults?.[vaultAddress.network.id]?.[
          vaultAddress.address.toString()
        ]?.value?.config?.underwritingToken
      : undefined
  );

  const protectedTokenAddress = useAppSelector((state) =>
    vaultAddress && poolId
      ? state.contracts.pools?.[vaultAddress.network.id]?.[
          vaultAddress.address.toString()
        ]?.[poolId]?.value?.config?.protectedToken
      : undefined
  );

  const underwritingToken = useToken(
    vaultAddress && underwritingTokenAddress
      ? NetworkAddress.fromNetworkAndAddressString(
          vaultAddress.network,
          underwritingTokenAddress
        )
      : undefined
  );

  const protectedToken = useToken(
    vaultAddress && protectedTokenAddress
      ? NetworkAddress.fromNetworkAndAddressString(
          vaultAddress.network,
          protectedTokenAddress
        )
      : undefined
  );

  const payoutRatio = useAppSelector((state) =>
    vaultAddress && poolId
      ? state.contracts.pools?.[vaultAddress.network.id]?.[
          vaultAddress.address.toString()
        ]?.[poolId]?.value?.config?.payoutRatio || {
          numerator: BigNumber.from(0),
          denominator: BigNumber.from(1),
        }
      : {
          numerator: BigNumber.from(0),
          denominator: BigNumber.from(1),
        }
  );

  const computePayout = useCallback(
    (claimAmount: TokenAmount) => {
      if (!underwritingToken) {
        return undefined;
      }

      return TokenAmount.from(
        underwritingToken,
        claimAmount
          .toBigNumber()
          .mul(payoutRatio.numerator)
          .div(payoutRatio.denominator)
      );
    },
    [payoutRatio.denominator, payoutRatio.numerator, underwritingToken]
  );

  const vaultNetwork = vaultAddress?.network.id;
  const vaultAddressStr = vaultAddress?.address.toString();

  const positionsRecord = useAppSelector((state) =>
    vaultNetwork && vaultAddressStr
      ? state.contracts.positions[vaultNetwork]?.[vaultAddressStr] || {}
      : undefined
  );

  const totalProtectedAmount = useMemo(
    () =>
      positionsRecord && protectedToken
        ? TokenAmount.from(
            protectedToken,
            Object.values(positionsRecord)
              .filter((position) => position.value.state.positionType === 2)
              .reduce(
                (protectedAmount, position) =>
                  protectedAmount.add(position.value.state.value),
                BigNumber.from(0)
              )
          )
        : undefined,
    [positionsRecord, protectedToken]
  );

  const positionsInCurrentClaim = useMemo(
    () =>
      positionsRecord &&
      Object.values(positionsRecord).filter((position) =>
        position.value.state.isClaimingBlock.gt(0)
      ),
    [positionsRecord]
  );

  const currentClaimAmount = useMemo(
    () =>
      positionsInCurrentClaim && protectedToken
        ? TokenAmount.from(
            protectedToken,
            positionsInCurrentClaim.reduce(
              (claimAmount, position) =>
                claimAmount.add(position.value.state.claimingProtectedAmount),
              BigNumber.from(0)
            )
          )
        : undefined,
    [positionsInCurrentClaim, protectedToken]
  );

  const currentPayoutAmount = useMemo(
    () => (currentClaimAmount ? computePayout(currentClaimAmount) : undefined),
    [computePayout, currentClaimAmount]
  );

  return input && totalProtectedAmount
    ? {
        computePayout,
        startClaim,
        cancelClaim,
        finishClaim,
        currentAction: (() => {
          if (startClaimProcessing) {
            return ClaimAction.StartClaim;
          }

          if (cancelClaimProcessing) {
            return ClaimAction.CancelClaim;
          }

          if (finishClaimProcessing) {
            return ClaimAction.FinishClaim;
          }

          return undefined;
        })(),
        currentStage: positionsInCurrentClaim?.length
          ? ClaimStage.TerminateClaim
          : ClaimStage.StartClaim,
        claimInProgress:
          currentClaimAmount && currentPayoutAmount
            ? {
                claimAmount: currentClaimAmount,
                payoutAmount: currentPayoutAmount,
              }
            : undefined,
        totalProtectedAmount,
        loading: startClaimLoading || finishClaimLoading || cancelClaimLoading,
      }
    : undefined;
}

export function usePool(
  poolAddress: Optional<{
    vaultAddress: NetworkAddress;
    poolId: PoolID;
  }>
): Optional<{
  pool: PoolView;
  purchase(amount: TokenAmount, maxPremium: TokenAmount): Promise<void>;
  isPurchasing: boolean;
  underwritingToken: Optional<Token>;
  protectedToken: Optional<Token>;
  claims: ClaimsFields;
  isApproving: boolean;
}> {
  // TODO(jason): Handle the case where vaultId is not an actual RHVault contract address
  const dispatch = useDispatch();
  const toast = useToaster();
  const { wallet } = useWallet();
  const { vaultAddress, poolId } = poolAddress ?? {};
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);

  const address = vaultAddress?.address.toString();
  const network = vaultAddress?.network.id;

  useEffect(() => {
    if (!address || !network) return;

    dispatch(
      startVaultSync({
        vaultAddress: NetworkAddress.fromStrings(network, address),
      })
    );
  }, [address, dispatch, network]);
  const networkInfo = useAppSelector((state) => state.network.value);

  useEffect(() => {
    if (!address || !network || !wallet) return;

    dispatch(
      loadUserVaultPolicyholderPositions({
        vaultAddress: NetworkAddress.fromStrings(network, address),
      })
    );
  }, [dispatch, address, network, wallet]);

  const vault: Optional<Vault> = useAppSelector((state) =>
    vaultAddress &&
    state.contracts.vaults[vaultAddress.network.id][
      vaultAddress.address.toString()
    ]
      ? state.contracts.vaults[vaultAddress.network.id][
          vaultAddress.address.toString()
        ].value
      : undefined
  );

  const pool: Optional<Pool> = useAppSelector((state) =>
    vaultAddress &&
    poolId &&
    state.contracts.pools[vaultAddress.network.id][
      vaultAddress.address.toString()
    ] &&
    state.contracts.pools[vaultAddress.network.id][
      vaultAddress.address.toString()
    ][poolId]
      ? state.contracts.pools[vaultAddress.network.id][
          vaultAddress.address.toString()
        ][poolId].value
      : undefined
  );

  const underwritingTokenAddress =
    network && vault
      ? NetworkAddress.fromStrings(network, vault.config.underwritingToken)
      : undefined;

  const protectedTokenAddress =
    network && pool
      ? NetworkAddress.fromStrings(network, pool.config.protectedToken)
      : undefined;

  const underwritingToken = useToken(underwritingTokenAddress);
  const protectedToken = useToken(protectedTokenAddress);

  const poolCmsData = useCMSPool(vaultAddress?.address, poolId)?.attributes;
  const subgraphPools = useAppSelector((state) =>
    vaultAddress ? state.subgraph.pools[vaultAddress.network.id] : []
  );
  const poolData = subgraphPools.find(
    (poolSubgraphData) => poolSubgraphData.id === poolId
  );

  const poolView: Optional<PoolView> =
    underwritingTokenAddress && pool && poolId
      ? {
          name: poolCmsData?.name ?? '',
          protectedToken: {
            ...protectedToken,
            address: protectedTokenAddress,
          } as TokenWithAddress,
          underwritingToken: {
            ...underwritingToken,
            address: underwritingTokenAddress,
          } as TokenWithAddress,
          underlyingTokensDisplay: poolCmsData?.underlyingTokensDisplay ?? [],
          payoutRatioDisplay: poolCmsData?.payoutRatioDisplay ?? '',
          defaultRatioDisplay: poolCmsData?.defaultRatioDisplay ?? [],
          poolDescription: poolCmsData?.description ?? '',
          policyholderRisks: poolCmsData?.policyholderRisks ?? '',
          rawState: pool,
          tokenVersion:
            poolData &&
            poolData.tokenVersion &&
            poolData.tokenVersion.toString()
              ? poolData.tokenVersion.toString()
              : undefined,
          poolId,
        }
      : undefined;

  const claims = useClaims(
    vaultAddress && poolId ? { vaultAddress, poolId } : undefined
  );
  const history = useHistory();

  async function purchase(amount: TokenAmount, maxPremium: TokenAmount) {
    try {
      if (!wallet) {
        throw new Error('No wallet connected');
      }

      if (!vaultAddress) throw new Error('vaultAddress is not defined');

      if (!poolId) throw new Error('poolId is not defined');

      if (!underwritingToken || !underwritingTokenAddress) {
        throw new Error('Underwriting token state not loaded');
      }
      setIsPurchasing(true);
      const spender = vaultAddress.address;
      const value = maxPremium.toBigNumber();
      const deadline = UnixTimestamp.now().add(2, TimePeriod.Day);
      const tokenAddress = underwritingTokenAddress;
      triggerEvent(GOOGLE_EVENTS.BUY_PROTECTION_INITIATED);

      const vaultContract = RHVault__factory.connect(
        vaultAddress.address.toString(),
        wallet.provider.getSigner() as Signer
      );

      const isEIP721Support = await getEIP721Support(tokenAddress);
      let permitCall: string | undefined;
      if (!isEIP721Support) {
        const allowance = await getAllowance(
          tokenAddress.address.toLowercaseString(),
          wallet,
          spender.toLowercaseString()
        );
        if (allowance.lt(amount.toBigNumber())) {
          setIsApproving(true);
          const isApprovedSuccessData = await approveAmount(
            tokenAddress.address.toLowercaseString(),
            wallet,
            spender.toLowercaseString(),
            amount.toBigNumber()
          );
          setIsApproving(false);
          if (!isApprovedSuccessData.success) {
            toast.error({
              message: isApprovedSuccessData.message,
              title: 'Errored Approving',
            });
            setIsPurchasing(false);
            return;
          }
        }
      } else {
        const { v, r, s } = await permitTokenTransfer(
          wallet,
          spender,
          value,
          deadline,
          tokenAddress,
          poolView?.tokenVersion
        );

        permitCall = vaultContract.interface.encodeFunctionData('permitToken', [
          tokenAddress.address.toString(),
          {
            owner: wallet.account.toString(),
            value: maxPremium.toBigNumber(),
            deadline: BigNumber.from(deadline.value),
            signatures: {
              v,
              r,
              s,
            },
          },
        ]);
      }

      const tx = await (permitCall
        ? vaultContract.multicall([
            permitCall,
            vaultContract.interface.encodeFunctionData('purchase', [
              poolId,
              amount.toBigNumber(),
              maxPremium.toBigNumber(),
            ]),
          ])
        : vaultContract.purchase(
            poolId,
            amount.toBigNumber(),
            maxPremium.toBigNumber()
          ));

      const receipt = await tx.wait(1);

      logger.debug('PERMIT + PURCHASE TX SENT', { purchaseTx: tx });

      logger.debug('PERMIT + PURCHASE TX CONFIRMED', {
        purchaseTx: tx,
        purchaseReceipt: receipt,
      });
      setIsPurchasing(false);
      dispatch(simulatorState.updateSimulator(3));
      const blockExporerUrls =
        networkInfo.config.walletConfig.blockExplorerUrls;
      const scannerUrl = `${
        blockExporerUrls && blockExporerUrls.length > 0
          ? blockExporerUrls[0]
          : 'https://etherscan.io'
      }/tx/${receipt.transactionHash}`;
      toast.success({
        title: 'Purchase Successful!',
        message: message(scannerUrl),
      });
      triggerEvent(GOOGLE_EVENTS.BUY_PROTECTION_SUCCESS);
      history.push(`/hack/pool/maticmum/${vaultAddress.address}`);
    } catch (err) {
      setIsPurchasing(false);
      triggerEvent(GOOGLE_EVENTS.BUY_PROTECTION_FAILED);
      let messageText = 'Transaction Failed';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (err && (err as any).code === 'UNPREDICTABLE_GAS_LIMIT') {
        messageText = 'Gas Prediction Error';
      }
      toast.error({
        message: messageText,
        title: 'Please try again',
      });
    }
  }

  return poolView && claims
    ? {
        pool: poolView,
        purchase,
        isPurchasing,
        underwritingToken,
        protectedToken,
        claims,
        isApproving,
      }
    : undefined;
}
