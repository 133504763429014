/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from 'graphql-request';
import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { Subgraph } from 'src/services/subgraph';

export type VaultSubgraphResult = {
  address: Address;
  underwritingToken: Address;
  totalCapacity: string;
  utilizedCapacity: string;
  expiry: UnixTimestamp;
};

const Vaults = gql`
  query Vaults {
    vaults {
      id
      underwritingToken {
        id
      }
      totalCapacity
      totalUtilizedCapacity
      expireTimestamp
    }
  }
`;

export class VaultService {
  private constructor(private readonly subgraph: Subgraph) {}

  static fromNetwork(network: Network) {
    return new VaultService(Subgraph.fromNetwork(network));
  }

  async getVaults(): Promise<VaultSubgraphResult[]> {
    const result = await this.subgraph.query(Vaults, {});

    return (
      (result as any)?.vaults.map(
        (vault: any) =>
          ({
            address: Address.fromString(vault.id),
            underwritingToken: Address.fromString(vault.underwritingToken.id),
            totalCapacity: vault.totalCapacity,
            utilizedCapacity: vault.totalUtilizedCapacity,
            expiry: UnixTimestamp.fromSeconds(vault.expireTimestamp),
          } as VaultSubgraphResult)
      ) ?? []
    );
  }
}
