import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useWallet, useWalletOptions } from 'src/viewmodels/wallet';
import { getLocalConnectivityData } from 'src/utils/localStore';
import {
  useNetwork,
  useNetworkOptions,
  useChainOptions,
} from 'src/viewmodels/network';
import { WalletType } from 'src/config/wallets';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1.5rem;
  row-gap: 1.5rem;

  @media only screen and (max-width: 796px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const Text = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
`;

const Content = styled(ModalContent)`
  max-width: 500px !important;
  background-color: var(--modal-blue) !important;

  @media only screen and (max-width: 796px) {
    max-width: 300px !important;
  }
`;

export const WalletProviderModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const headerText = intl.formatMessage({
    id: 'wallet-modal-header-text',
    defaultMessage: 'Select a wallet',
  });

  // random values here; mix of wallet and network names; to be changed later
  const walletOptions = useWalletOptions();
  const { connect, disconnect } = useWallet();
  const supportedNetworks = useNetworkOptions();
  const supportedChainIds = useChainOptions();
  const { currentNetwork, updateNetwork } = useNetwork();
  const connectivityStatus = getLocalConnectivityData();

  useEffect(() => {
    if (connectivityStatus.connected) {
      if (
        supportedChainIds.includes(connectivityStatus.chainId) &&
        currentNetwork.chainId !== connectivityStatus.chainId
      ) {
        updateNetwork(
          supportedNetworks.find(
            (network) => network.chainId === connectivityStatus.chainId
          ) || supportedNetworks[0]
        );
      }
      connect(connectivityStatus.source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const connectWalletNow = (type: WalletType) => {
    const currentStatus = getLocalConnectivityData();
    if (currentStatus.connected) {
      disconnect();
    }
    connect(type);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="var(--overlay-modal-black)" />
      <Content>
        <ModalHeader textColor="var(--text-primary-white)">
          {headerText}
        </ModalHeader>
        <ModalCloseButton
          style={{ color: 'white', marginTop: 10 }}
          _focus={{ outline: 'none' }}
          _hover={{ filter: 'brightness(65%)' }}
        />
        <ModalBody marginBottom={5}>
          <GridBox>
            {walletOptions.map((wallet) => {
              return (
                <Button
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center !important"
                  border="1px solid var(--components-primary-blue)"
                  _hover={{
                    textDecoration: 'none',
                    filter: 'brightness(75%)',
                  }}
                  _active={{ color: 'transparent' }}
                  _focus={{
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="var(--text-primary-white)"
                  background="transparent"
                  w="214px"
                  onClick={() => connectWalletNow(wallet.type)}
                >
                  <Image src={wallet.iconSrc} alt="image" />
                  <Text>{wallet.displayName}</Text>
                </Button>
              );
            })}
          </GridBox>
        </ModalBody>
      </Content>
    </Modal>
  );
};
