import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from '@chakra-ui/react';
import { VaultContext } from 'src/contexts/VaultContext';
import { OrderButton } from 'src/components/OrderBox/OrderButton';
import { useVault } from 'src/viewmodels/vault';
import UseToaster from 'src/hooks/useToast';
import { RHError } from 'src/errors';
import { getBlockExplorerUrl } from 'src/utils/network';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-blue);
  border-radius: 8px;
  margin-top: 0.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p2-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
`;

interface Props {
  networkMismatch: boolean | null | undefined;
}

export const Withdraw: React.FC<Props> = ({ networkMismatch }) => {
  const vaultAddress = useContext(VaultContext);
  const { vault = undefined, withdrawal = undefined } =
    useVault(vaultAddress) ?? {};
  const underwritingAmount =
    withdrawal?.underwritingTokenAmount.toFormattedString(2, 2, true);
  const underwritingSymbol = withdrawal?.underwritingTokenAmount.token.symbol;
  // const rewardsAmount = withdrawal?.rewardTokenAmount.toFormattedString(
  //   2,
  //   2,
  //   true
  // );
  // const rewardsSymbol = withdrawal?.rewardTokenAmount.token.symbol;

  const currDate = new Date();
  const vaultExpired = vault ? currDate > vault.expirationDate : false;

  const toast = UseToaster();

  const onSuccess = (txHash: string) => {
    toast.success({
      title: `Withdrawal Successful!`,
      message: (
        <Link
          href={`${
            getBlockExplorerUrl(vaultAddress?.network) || 'https://etherscan.io'
          }/tx/${txHash}`}
          isExternal
        >
          View transaction
        </Link>
      ),
    });
  };
  const onFailure = (error: RHError) => {
    toast.error({
      title: `Withdrawal Failed`,
      message: error.message,
    });
  };

  return (
    <Container>
      {networkMismatch ? (
        <>
          <AmountBox style={{ marginBottom: 0 }}>
            <Text style={{ margin: 'auto' }}>
              Please switch to {vault?.network.network.displayName}
            </Text>
          </AmountBox>
        </>
      ) : (
        <>
          {vaultExpired ? (
            <>
              <AmountBox>
                <Text>UNDERWRITING TOKENS</Text>
                <AmountText>
                  {underwritingAmount} {underwritingSymbol}
                </AmountText>
              </AmountBox>
              <OrderButton
                actionType="Withdraw"
                disabled={withdrawal?.withdrawing}
                onClick={() => withdrawal?.withdraw(onSuccess, onFailure)}
              />
            </>
          ) : (
            <>
              <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
                <Text>
                  Withdrawals cannot be processed at this time. Withdrawals will
                  be available when the vault expires.
                </Text>
              </AmountBox>
              <OrderButton actionType="Withdraw" disabled />
            </>
          )}
        </>
      )}
    </Container>
  );
};
