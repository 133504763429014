/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { BigNumber, BigNumberish, Signature, providers } from 'ethers';
import { splitSignature } from 'ethers/lib/utils';
import { Address } from 'src/models/Address';
import { NetworkID } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { UnixTimestamp } from 'src/models/UnixTimestamp';
import { Wallet } from 'src/models/Wallet';
import { ERC20Permit__factory } from 'src/types/contracts/factories/ERC20Permit__factory';
import { logger } from 'src/utils/logger';

interface TokenVersionInterface {
  [key: string]: {
    [key: string]: string;
  };
}

const tokenVersions: TokenVersionInterface = {
  [NetworkID.mainnet]: {
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': '2',
  },
  [NetworkID.arbitrum]: {
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': '1',
  },
  [NetworkID.ropsten]: {
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': '1',
  },
  [NetworkID.arbitrumRinkeby]: {},
  [NetworkID.optimism]: {},
  [NetworkID.optimismKovan]: {},
  [NetworkID.polygon]: {},
  [NetworkID.polygonMumbai]: {},
  [NetworkID.avalanche]: {},
  [NetworkID.avalancheFuji]: {},
  [NetworkID.fantom]: {},
  [NetworkID.fantomTestnet]: {},
  [NetworkID.bsc]: {},
  [NetworkID.bscTestnet]: {},
  [NetworkID.unsupported]: {},
};

export function getTokenVersion(tokenAddress: NetworkAddress) {
  const version =
    tokenVersions[tokenAddress.network.id][
      tokenAddress.address.toString().toLocaleLowerCase()
    ];
  return version || undefined;
}

export async function permitTokenTransfer(
  wallet: Wallet,
  spender: Address,
  value: BigNumberish,
  deadline: UnixTimestamp,
  tokenAddress: NetworkAddress,
  version?: string
): Promise<Signature> {
  const user = wallet.account.toString();
  const tokenContract = ERC20Permit__factory.connect(
    tokenAddress.address.toString(),
    wallet.provider as providers.Provider
  );

  const { chainId } = tokenAddress.network;

  const [nonce, name] = await Promise.all([
    tokenContract.nonces(user),
    tokenContract.name(),
  ]);

  const domain = {
    name,
    version: version ?? getTokenVersion(tokenAddress) ?? '1',
    chainId,
    verifyingContract: tokenAddress.address.toString(),
  };

  const types = {
    Permit: [
      {
        name: 'owner',
        type: 'address',
      },
      {
        name: 'spender',
        type: 'address',
      },
      {
        name: 'value',
        type: 'uint256',
      },
      {
        name: 'nonce',
        type: 'uint256',
      },
      {
        name: 'deadline',
        type: 'uint256',
      },
    ],
  };

  const permission = {
    owner: wallet.account.toString(),
    spender: spender.toString(),
    value,
    nonce,
    deadline: BigNumber.from(deadline.value),
  };

  logger.debug('ERC20 PERMISSION', { permission });

  const signer = wallet.provider.getSigner();
  const signature = await signer._signTypedData(domain, types, permission);

  return splitSignature(signature);
}
