import numeral from 'numeral';
import React from 'react';
import { Optional } from 'src/types/Optional';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  width: 100%;

  @media only screen and (max-width: 1473px) {
    margin-bottom: 1rem;
    margin-left: 0rem;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-weight: 700;
  text-align: center;

  @media only screen and (max-width: 1473px) {
    text-align: left;
  }

  @media only screen and (max-width: 325px) {
    font-size: var(--h2-font-size);
  }
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
  text-align: center;

  @media only screen and (max-width: 1473px) {
    text-align: left;
  }
`;

const APYContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  apy: Optional<number>;
}

const formatApy = (_apy: Optional<number>) => numeral(_apy).format('0.0%');

export const UnderwriteInfo: React.FC<Props> = ({ apy }) => {
  return (
    <Container>
      <LargeText>Simulator Vault</LargeText>
      <APYContainer>
        <Text>{`Current Reward Rate ${formatApy(apy)}`}</Text>
      </APYContainer>
    </Container>
  );
};
