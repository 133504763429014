import { SUPPORTED_CHAIN_IDS, SUPPORTED_NETWORKS } from 'src/config/network';
import { RHNetworkConfig } from 'src/config/types';
import { useDispatchableCallback } from 'src/hooks/useDispatchableCallback';
import { Network } from 'src/models/Network';
import { useAppSelector } from 'src/state';
import { networkState } from 'src/state/network';
import { pick } from 'src/utils/objects';

export function useNetwork(): {
  updateNetwork: (network: Network) => void;
  currentNetwork: Network;
} {
  const currentNetwork = useAppSelector((state) => state.network.value);
  const updateNetwork = useDispatchableCallback(
    (network: Network<RHNetworkConfig>) => networkState.updateNetwork(network)
  );

  return {
    updateNetwork,
    currentNetwork,
  };
}

export function useNetworkOptions(): Network<RHNetworkConfig>[] {
  // Keeping this as a hook to decouple this hardcoded constant from view layer
  return SUPPORTED_NETWORKS;
}

export function useChainOptions(): number[] {
  return SUPPORTED_CHAIN_IDS;
}

export function useNetworkMetadata(): Pick<
  Network<RHNetworkConfig>,
  'chainId' | 'id'
> {
  const { currentNetwork } = useNetwork();

  return pick(currentNetwork, 'chainId', 'id');
}

export function useNetworkConfig(): RHNetworkConfig {
  const { currentNetwork } = useNetwork();

  return currentNetwork.config;
}
