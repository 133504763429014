import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import { resolveNetworkFromName } from 'src/utils/network';

export class NetworkAddress {
  private constructor(
    public readonly network: Network,
    public readonly address: Address
  ) {}

  static fromStrings(network: string, address: string) {
    return new NetworkAddress(
      resolveNetworkFromName(network),
      Address.fromString(address)
    );
  }

  static fromNetworkAndAddressString(network: Network, address: string) {
    return new NetworkAddress(network, Address.fromString(address));
  }

  static fromNetworkAndAddress(network: Network, address: Address) {
    return new NetworkAddress(network, address);
  }

  static fromNetworkStringAndAddress(network: string, address: Address) {
    return new NetworkAddress(resolveNetworkFromName(network), address);
  }
}
