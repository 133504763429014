import { RHError } from 'src/errors';

enum LogLevel {
  debug,
  info,
  warn,
  error,
}

function logLevelToString(level: LogLevel): string {
  switch (level) {
    case LogLevel.debug:
      return 'debug';
    case LogLevel.info:
      return 'info';
    case LogLevel.warn:
      return 'warn';
    default:
      return 'error';
  }
}

type LogFunction<T = Record<string, unknown>> = (
  message: string,
  metadata?: T
) => void;

interface Logger {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction<RHError>;
}

const LOG_LEVEL =
  process.env.NODE_ENV === 'production' ? LogLevel.error : LogLevel.debug;

function makeLogFunction(level: LogLevel): LogFunction {
  return (message, metadata = {}) => {
    if (LOG_LEVEL <= level) {
      // eslint-disable-next-line no-console
      console.log(`[${logLevelToString(level)}] ${message}`, metadata);
    }
  };
}

export const logger: Logger = {
  debug: makeLogFunction(LogLevel.debug),
  info: makeLogFunction(LogLevel.info),
  warn: makeLogFunction(LogLevel.warn),
  error: (message, error?: RHError) => {
    // eslint-disable-next-line no-console
    console.error(`[ERROR] ${message}`, { error, metadata: error?.metadata });
  },
};
