import React from 'react';
import styled from 'styled-components';
import { UnderwriteInfo } from 'src/components/Banner/UnderwriteInfo';
import { VaultDetailsGrid } from 'src/components/Banner/VaultDetailsGrid';
import { VaultView } from 'src/viewmodels/vault';
import { PoolView } from 'src/viewmodels/pool';
import { Optional } from 'src/types/Optional';
import { PoolDetailsGrid } from './PoolDetailsGrid';
import { ProtectionInfo } from './ProtectionInfo';

const FlexBox = styled.div`
  display: flex;
  background: var(--banner-gradient-blue);
  border-radius: var(--card-radius);
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 1rem;
  align-items: center;
  padding: 1.5rem;

  @media only screen and (max-width: 1473px) {
    flex-direction: column;
    align-items: normal;
  }
`;

interface Props {
  type: string;
  vaultInfo?: Optional<VaultView>;
  poolInfo?: Optional<PoolView>;
  poolExpDate?: Optional<Date>;
}

export const Banner: React.FC<Props> = ({
  type,
  vaultInfo,
  poolInfo,
  poolExpDate,
}) => {
  return (
    <>
      <FlexBox>
        <div>
          {type === 'protection' ? (
            <ProtectionInfo pool={poolInfo} />
          ) : (
            <UnderwriteInfo apy={vaultInfo?.apy} />
          )}
        </div>
        {type === 'protection' ? (
          <PoolDetailsGrid
            protectedToken={poolInfo?.protectedToken.symbol}
            underwritingToken={poolInfo?.underwritingToken.symbol}
            expirationDate={poolExpDate}
          />
        ) : (
          <VaultDetailsGrid
            underwritingToken={vaultInfo?.underwritingToken}
            vaultCapacity={vaultInfo?.vaultCapacity}
            riskAversion={vaultInfo?.riskAversion}
            network={vaultInfo?.network?.network.displayName}
            expirationDate={vaultInfo?.expirationDate}
          />
        )}
      </FlexBox>
    </>
  );
};

Banner.defaultProps = {
  vaultInfo: undefined,
  poolInfo: undefined,
  poolExpDate: undefined,
};
