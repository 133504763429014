import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Button, Input } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { TokenAmountInput } from 'src/components/OrderBox/TokenAmountInput';
import { OrderButton } from 'src/components/OrderBox/OrderButton';
import { useVault } from 'src/viewmodels/vault';
import { PoolContext } from 'src/contexts/PoolContext';
import { usePool } from 'src/viewmodels/pool';
import { TokenAmount } from 'src/models/TokenAmount';
import { computePayout } from 'src/math/payout';
// import { minBigNum } from 'src/math/utils';
import { usePremium } from 'src/viewmodels/protect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SlippageText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const SDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
`;

const SlippageContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 499px) {
    margin-bottom: 1rem;
  }
`;

const PresetSlippage = styled(Button)`
  background-color: transparent !important;
  border: 1px solid var(--components-primary-blue) !important;
  border-radius: 100px !important;
  width: 4.5rem !important;
  color: var(--text-primary-white) !important;
  margin-right: 0.25rem !important;

  &:hover {
    background-color: transparent !important;
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
`;

const InputBox = styled(Input)``;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--section-bm);

  @media only screen and (max-width: 499px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: var(--background-blue);
  border-radius: 8px;
  width: 48%;

  @media only screen and (max-width: 499px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

// const PolicyBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 1rem 1.25rem;
//   background-color: var(--background-blue);
//   border-radius: 8px;
//   margin-bottom: var(--section-bm);
// `;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;
`;

const SwitchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-blue);
  border-radius: 8px;
  margin-top: 0.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const SwitchText = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin: auto;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

interface Props {
  networkMismatch: boolean | null | undefined;
}

export const Protect: React.FC<Props> = ({ networkMismatch }) => {
  const intl = useIntl();
  const slippageText = intl.formatMessage({
    id: 'slippage-text',
    defaultMessage: 'Slippage Tolerance',
  });
  const payoutText = intl.formatMessage({
    id: 'claim-payout-text',
    defaultMessage: 'PAYOUT',
  });
  const premiumText = intl.formatMessage({
    id: 'premium-text',
    defaultMessage: 'PREMIUM',
  });
  const poolLimitExceededText = intl.formatMessage({
    id: 'pool-capacity-exceeded',
    defaultMessage: 'Pool Capacity Exceeded',
  });
  const walletLimitExceededText = intl.formatMessage({
    id: 'insufficient-funds',
    defaultMessage: 'Insufficient Funds',
  });
  // const policyTimeText = intl.formatMessage({
  //   id: 'policy-act-time-text',
  //   defaultMessage: 'POLICY ACTIVATION TIME',
  // });

  const [tokenAmount, setTokenAmount] = useState<TokenAmount>(
    TokenAmount.default()
  );
  const [slippage, setSlippage] = useState<string>('0.1');
  // eslint-disable-next-line
  const [isInvalidAmount, setIsInvalidAmount] = useState<boolean>(false);
  const poolAddress = useContext(PoolContext);
  const { vault = undefined } = useVault(poolAddress?.vaultAddress) ?? {};
  const {
    pool = undefined,
    purchase = undefined,
    isPurchasing = false,
    underwritingToken = undefined,
    protectedToken = undefined,
  } = usePool(poolAddress) ?? {};
  const regExp = /^(100|([1-9]?[0-9]?(\.[0-9]?)?))$/;
  const { premium, maxPremium } = usePremium(
    poolAddress?.vaultAddress,
    poolAddress?.poolId,
    pool?.underwritingToken,
    tokenAmount,
    slippage
  );
  const payoutAmount = computePayout(
    tokenAmount,
    pool?.rawState?.config?.payoutRatio,
    pool?.underwritingToken
  );
  let maxAmt = TokenAmount.default();
  let underwritingBalance = TokenAmount.default();
  let protectedBalance = TokenAmount.default();
  let isUnderwritingInvalid = false;
  let errorMessage = '';
  if (underwritingToken) {
    underwritingBalance = TokenAmount.from(
      underwritingToken,
      underwritingToken.balance || 0
    );
    if (premium) {
      isUnderwritingInvalid = premium
        .toBigNumber()
        .gt(underwritingBalance.toBigNumber());
      if (isUnderwritingInvalid) {
        errorMessage = walletLimitExceededText;
      }
    }
  }
  if (protectedToken) {
    protectedBalance = TokenAmount.from(
      protectedToken,
      protectedToken.balance || 0
    );
    maxAmt = protectedBalance;
  }
  if (pool && vault) {
    // const userProtectTokenBalance = TokenAmount.from(
    //   pool.protectedToken,
    //   pool.protectedToken.balance || 0
    // );
    maxAmt = TokenAmount.from(
      pool.protectedToken,
      vault.vaultCapacity
        .toBigNumber()
        .sub(vault.rawState.state.allocationVector[parseInt(pool.poolId, 10)])
        .mul(pool.rawState.config.payoutRatio.denominator)
        .div(pool.rawState.config.payoutRatio.numerator)
    );
    if (maxAmt.amount.lt(tokenAmount.amount)) {
      errorMessage = poolLimitExceededText;
    }
  }

  const handleSlippageChange = (event: InputEvent): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rawVal = (event.target as any)?.value || '';
    if (!regExp.test(rawVal)) {
      return;
    }
    setSlippage(rawVal);
  };
  const onAmountChange = (amount: TokenAmount) => {
    if (maxAmt.amount.lt(amount.amount)) {
      errorMessage = poolLimitExceededText;
    }
    setTokenAmount(amount);
    // setIsInvalidAmount(amount.toBigNumber().gt(protectedBalance.toBigNumber()));
  };

  return (
    <>
      {networkMismatch ? (
        <>
          <SwitchBox>
            <SwitchText>
              Please switch to {poolAddress?.vaultAddress.network.displayName}
            </SwitchText>
          </SwitchBox>
        </>
      ) : (
        <Container>
          <TokenAmountInput
            actionType="Protect"
            token={pool?.protectedToken}
            amount={tokenAmount}
            maxAmount={maxAmt}
            onChange={onAmountChange}
          />
          <SlippageText>{slippageText}</SlippageText>
          <SDiv>
            <SlippageContainer>
              <PresetSlippage onClick={() => setSlippage('0.1')}>
                0.1%
              </PresetSlippage>
              <PresetSlippage onClick={() => setSlippage('0.5')}>
                0.5%
              </PresetSlippage>
              <PresetSlippage onClick={() => setSlippage('1')}>
                1%
              </PresetSlippage>
            </SlippageContainer>
            <InputContainer>
              <InputBox
                className="protect-inputbox"
                placeholder="0"
                textAlign="right"
                _placeholder={{
                  opacity: '0.7',
                  color: 'var(--text-primary-white)',
                }}
                border="2px solid var(--background-blue) !important"
                focusBorderColor="none"
                color="var(--text-secondary-gray)"
                value={slippage}
                onChange={(event: InputEvent) => handleSlippageChange(event)}
              />
              <Text style={{ marginBottom: 0, marginTop: 7 }}>%</Text>
            </InputContainer>
          </SDiv>
          <SubContainer>
            <ItemBox>
              <Text>
                {payoutAmount?.toFormattedString(1, 2, true)}{' '}
                {pool?.underwritingToken.symbol}
              </Text>
              <Description>{payoutText}</Description>
            </ItemBox>
            <ItemBox>
              <Text>
                {premium?.toFormattedString(1, 2, true)}{' '}
                {pool?.underwritingToken.symbol}
              </Text>
              <Description>{premiumText}</Description>
            </ItemBox>
          </SubContainer>
          {/* <PolicyBox>
        <Text>Sep. 20, 2021 | 4:16 a.m. (UTC)</Text>
        <Description>{policyTimeText}</Description>
      </PolicyBox> */}
          <OrderButton
            actionType="Protect"
            isActionInProgress={isPurchasing}
            disabled={Boolean(
              isInvalidAmount || isUnderwritingInvalid || errorMessage
            )}
            errorMessage={errorMessage}
            onClick={() =>
              maxPremium ? purchase?.(tokenAmount, maxPremium) : undefined
            }
            zeroAmount={tokenAmount.toBigNumber().eq(0)}
          />
        </Container>
      )}
    </>
  );
};
