/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'src/models/Address';
import { Wallet } from 'src/models/Wallet';
import { Optional } from 'src/types/Optional';

interface WalletState {
  value: Optional<Wallet>;
}

const initialState: WalletState = {
  value: undefined,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    loadWallet: (state, action: PayloadAction<typeof initialState.value>) => {
      state.value = action.payload;
    },
    removeWallet: (state) => {
      state.value = undefined;
    },
    updateAccount: (state, action: PayloadAction<Address>) => {
      if (state.value) {
        state.value.account = action.payload;
      }
    },
  },
});

export const walletState = {
  ...walletSlice.actions,
  reducer: walletSlice.reducer,
};
