import React, { useContext } from 'react';
import { Progress } from '@chakra-ui/react';
import styled from 'styled-components';
import { TokenAmount } from 'src/models/TokenAmount';
import { PoolView } from 'src/viewmodels/pool';
import { Optional } from 'src/types/Optional';
import { PoolContext } from 'src/contexts/PoolContext';
import { useVault } from 'src/viewmodels/vault';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5rem 0.5rem 6rem;
  row-gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 1473px) {
    margin-bottom: 0rem;
    margin-left: 0rem;
    flex-direction: row;
    align-items: center;
    row-gap: 0rem;
  }

  @media only screen and (max-width: 1045px) {
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1473px) {
    margin-right: 7rem;
  }

  @media only screen and (max-width: 1045px) {
    margin-right: auto;
    align-self: left;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressBar = styled(Progress)`
  width: 225px;
  max-width: 350px;

  @media only screen and (max-width: 1473px) {
    width: 65%;
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: baseline;

  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-weight: 600;
  margin-right: 10px;

  @media only screen and (max-width: 375px) {
    font-size: var(--h2-font-size);
  }
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--h2-font-size);

  @media only screen and (max-width: 375px) {
    font-size: var(--p-font-size);
  }
`;

const SmallText = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

interface Props {
  pool: Optional<PoolView>;
}

export const ProtectionInfo: React.FC<Props> = ({ pool }) => {
  const elementText = 'Element Principal Token yvCurve-alUSD-29APR22';
  const curveElementText = 'Curve FRAX Pool yVault';
  let direction = 'row';
  let protectedTokenName = pool?.protectedToken.name;
  let protectedTokenSymbol = pool?.protectedToken.symbol;

  if (protectedTokenName === curveElementText) {
    protectedTokenName = 'Yearn Frax Curve 3pool';
    direction = 'column';
  } else if (pool?.protectedToken.name === elementText) {
    protectedTokenName = pool?.protectedToken.name.substring(0, 24);
    protectedTokenSymbol = pool?.protectedToken.symbol.substring(0, 4);
  }

  let remainingCapacityInProtected = TokenAmount.default();
  let utilization = 0;
  const poolAddress = useContext(PoolContext);
  const { vault = undefined } = useVault(poolAddress?.vaultAddress) ?? {};
  if (pool && vault) {
    remainingCapacityInProtected = TokenAmount.from(
      pool.protectedToken,
      vault.vaultCapacity
        .toBigNumber()
        .sub(vault.rawState.state.allocationVector[parseInt(pool.poolId, 10)])
        .mul(pool.rawState.config.payoutRatio.denominator)
        .div(pool.rawState.config.payoutRatio.numerator)
    );
    utilization = vault.pools[parseInt(pool.poolId, 10) - 1].utilizationPercent;
  }

  return (
    <Container>
      <SubContainer>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <NameContainer style={{ flexDirection: direction as any }}>
          <LargeText>{protectedTokenSymbol}</LargeText>
          <Text>{pool?.underwritingToken.symbol}</Text>
        </NameContainer>
        <SmallText>{protectedTokenName}</SmallText>
      </SubContainer>
      <ProgressContainer>
        <ProgressBar
          size="xs"
          transition="all 0s"
          borderRadius="20px"
          value={utilization}
          isAnimated
          mb={1}
        />
        <SmallText>
          {remainingCapacityInProtected.toFormattedString(1, 2, true)}{' '}
          {protectedTokenSymbol} remaining
        </SmallText>
      </ProgressContainer>
    </Container>
  );
};
