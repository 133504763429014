const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = 60 * SECOND_IN_MS;
const HOUR_IN_MS = 60 * MINUTE_IN_MS;
const DAY_IN_MS = 24 * HOUR_IN_MS;
const WEEK_IN_MS = 7 * DAY_IN_MS;
const MONTH_IN_MS = 30 * DAY_IN_MS;
const YEAR_IN_MS = 365 * DAY_IN_MS;

export enum TimePeriod {
  Millisecond = 1,
  Second = SECOND_IN_MS,
  Minute = MINUTE_IN_MS,
  Hour = HOUR_IN_MS,
  Day = DAY_IN_MS,
  Week = WEEK_IN_MS,
  Month = MONTH_IN_MS,
  Year = YEAR_IN_MS,
}

export function addTime(
  date: Date,
  quantity: number,
  timePeriod: TimePeriod
): Date {
  const ms = date.getTime();
  const msToAdd = quantity * timePeriod;
  return new Date(ms + msToAdd);
}
