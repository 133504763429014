import React from 'react';
import {
  Redirect,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { NotFoundError, RHError, UncaughtError } from 'src/errors';
import { Optional } from 'src/types/Optional';
import { PropsWithChildren } from 'src/types/PropsTypes';
import { ErrorModal } from 'src/components/Modal/ErrorModal';
import { logger } from 'src/utils/logger';

type StateType = {
  error: Optional<RHError>;
};

type PropsType = PropsWithChildren<{
  history: ReturnType<typeof useHistory>;
  location: ReturnType<typeof useLocation>;
}>;

class InternalErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error): StateType {
    // Update state so the next render will show the fallback UI.
    const uncaughtError = new UncaughtError(error);

    return {
      error: uncaughtError.resolvedError ?? uncaughtError,
    };
  }

  componentDidCatch(error: RHError): void {
    logger.error(error.message, error);
  }

  render(): React.ReactNode {
    const { children, location } = this.props;
    const { error } = this.state;

    if (error) {
      if (error.is(NotFoundError)) {
        this.setState({ error: undefined });
        return (
          <Redirect
            to={{ pathname: '/not-found', state: { from: location } }}
          />
        );
      }

      return <ErrorModal message={error.message} />;
    }

    return children;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ErrorBoundary = withRouter(InternalErrorBoundary);
