import React from 'react';
import styled from 'styled-components';
import { NetworkModal } from 'src/components/Modal/NetworkModal';
import { WalletButtonModal } from 'src/components/Modal/WalletButtonModal';

const FlexBox = styled.div`
  display: flex;

  @media only screen and (max-width: 960px) {
    margin-top: 2.5rem;
    flex-direction: column;
    row-gap: 1rem;
    width: 200px;
    margin: 1.5rem auto auto auto;
  }
`;

export const WalletConnected: React.FC = () => {
  return (
    <FlexBox>
      <NetworkModal />
      <WalletButtonModal />
    </FlexBox>
  );
};
