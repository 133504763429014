import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useColorModeValue,
  HStack,
  Tooltip,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Link,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { utils, BigNumber } from 'ethers';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Tour from 'reactour';
import { ConditionalRender } from 'src/components/Common/ConditionalRender';
import { useSimulator, SessionForm } from 'src/viewmodels/simulator';
import { useNetwork } from 'src/viewmodels/network';
import UseToaster from 'src/hooks/useToast';
import { getBlockExplorerUrl } from 'src/utils/network';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ToolTip as CustomToolTip } from 'src/components/ToolTip/ToolTip';
import { SIMULATION_FORM } from 'src/constants/constants';

const CreatePool = styled.div`
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 50px 12px rgba(17, 17, 17, 0.15);
  border-radius: 15px;
  padding: 3rem;
  text-align: center;
`;

const POOL = {
  name: 'Hackable Pool',
};

interface TokenFieldProps {
  name: string;
  isMintable?: boolean;
  mintPlaceholder?: string;
  namePlaceholder: string;
  symbolPlaceholder: string;
  decimalsPlaceholder: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateTokenName(name: string): void {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateTokenSymbol(symbol: string): void {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateTokenDecimals(decimals: string): undefined | string {
  try {
    const num = parseInt(decimals, 10);
    if (num >= 0 && num <= 50) {
      return undefined;
    }

    return 'Number should be between 0 and 50';
  } catch (err) {
    return 'Invalid value';
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateTokenAmount(amount: string): undefined | string {
  try {
    BigNumber.from(amount);
    return undefined;
  } catch (err) {
    return 'Invalid value';
  }
}

const NumberField: React.FC<
  BoxProps & {
    id: string;
    placeholder: string;
    label: string;
    isNotBigNumber?: boolean;
    tooltip?: string;
  }
> = ({
  id,
  placeholder,
  label,
  isNotBigNumber = false,
  tooltip,
  ...boxProps
}) => {
  const questionIconColor = useColorModeValue(
    'rgba(6, 6, 6, 0.5)',
    'rgba(249, 249, 249, 0.5)'
  );

  return (
    <Box {...boxProps}>
      <Field
        name={id}
        validate={(val: string) => {
          try {
            if (isNotBigNumber) {
              parseInt(val, 10);
            } else {
              BigNumber.from(val);
            }
            return undefined;
          } catch (_) {
            return 'Invalid value';
          }
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {({ field, form }: { [key: string]: any }) => (
          <FormControl isInvalid={form.errors[id] && form.touched[id]}>
            <HStack align="center">
              <FormLabel color="white">{label}</FormLabel>
              <ConditionalRender show={!!tooltip}>
                <Tooltip label={tooltip || ''} placement="right" p="12px">
                  <Box transform="translateY(-6px) translateX(-10px)">
                    <QuestionOutlineIcon color={questionIconColor} />
                  </Box>
                </Tooltip>
              </ConditionalRender>
            </HStack>
            <Input {...field} id={id} color="white" placeholder={placeholder} />
            <FormErrorMessage>{form.errors[id]}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Box>
  );
};

const TokenField: React.FC<BoxProps & TokenFieldProps> = ({
  isMintable,
  name,
  namePlaceholder,
  symbolPlaceholder,
  decimalsPlaceholder,
  mintPlaceholder,
  ...rest
}) => {
  const tokenNameKey = `${name.toLowerCase()}TokenName`;
  const tokenSymbolKey = `${name.toLowerCase()}TokenSymbol`;
  const tokenDecimalsKey = `${name.toLowerCase()}TokenDecimals`;
  const tokenMintKey = `${name.toLowerCase()}TokenToMint`;

  return (
    <Box {...rest}>
      <FormLabel fontSize="30px" color="white" fontWeight="medium">
        {`${name} Token`}
      </FormLabel>
      <HStack mt="15px" align="start">
        <Field name={tokenNameKey} validate={validateTokenName}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ field, form }: { [key: string]: any }) => (
            <FormControl
              isInvalid={
                form.errors[tokenNameKey] && form.touched[tokenNameKey]
              }
            >
              <FormLabel color="white">Name</FormLabel>
              <Input
                {...field}
                color="white"
                id={tokenNameKey}
                placeholder={namePlaceholder}
              />
              <FormErrorMessage>{form.errors[tokenNameKey]}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name={tokenSymbolKey} validate={validateTokenSymbol}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ field, form }: { [key: string]: any }) => (
            <FormControl
              isInvalid={
                form.errors[tokenSymbolKey] && form.touched[tokenSymbolKey]
              }
            >
              <FormLabel color="white">Symbol</FormLabel>
              <Input {...field} color="white" placeholder={symbolPlaceholder} />
              <FormErrorMessage>{form.errors[tokenSymbolKey]}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name={tokenDecimalsKey} validate={validateTokenDecimals}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ field, form }: { [key: string]: any }) => (
            <FormControl
              isInvalid={
                form.errors[tokenDecimalsKey] && form.touched[tokenDecimalsKey]
              }
            >
              <FormLabel color="white">Decimals</FormLabel>
              <Input
                {...field}
                color="white"
                placeholder={decimalsPlaceholder}
              />
              <FormErrorMessage>
                {form.errors[tokenDecimalsKey]}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </HStack>
      {isMintable && (
        <Field name={tokenMintKey} validate={validateTokenAmount}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {({ field, form }: { [key: string]: any }) => (
            <FormControl
              isInvalid={
                form.errors[tokenMintKey] && form.touched[tokenMintKey]
              }
              mt="15px"
            >
              <FormLabel color="white">Mint</FormLabel>
              <Input
                {...field}
                color="white"
                placeholder={mintPlaceholder || ''}
              />
              <FormErrorMessage>{form.errors[tokenMintKey]}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      )}
    </Box>
  );
};

export const PoolCreationForm: React.FC = () => {
  const { createSession, getUserVaults } = useSimulator();
  const [, setSession] = useLocalStorage('session', '{}');
  const { currentNetwork } = useNetwork();
  const toast = UseToaster();
  const history = useHistory();
  const [creatingSession, setCreatingSession] = useState<boolean>(false);
  const [enableSteps, setEnableSteps] = useState<boolean>(false);
  const [sliderPayoutValue, setSliderPayoutValue] = useState<number>(0.5);
  const [sliderDefaultValue, setSliderDefaultValue] = useState<number>(0.5);

  const steps = [
    {
      selector: '#slider-track-payout-slider',
      content:
        'Set your payout ratio. This is the ratio for which the user will be paid per each covered token.',
    },
    {
      selector: '#slider-track-default-slider',
      content:
        'Set your default ratio. This is the ratio for which a default event is considered.',
    },
  ];

  const getCreatedVaultsData = async () => {
    const data = await getUserVaults();
    history.push(`/vault/maticmum/${data}`);
  };

  const onFormSubmit = async (values: SessionForm) => {
    setCreatingSession(true);
    const txHash = await createSession({
      defaultRatio: utils.parseEther(sliderDefaultValue.toString()).toString(),
      payoutRatio: utils.parseEther(sliderPayoutValue.toString()).toString(),
      poolName: values.poolName,
    });
    setCreatingSession(false);
    if (!txHash) {
      return;
    }
    getCreatedVaultsData();
    setSession(
      JSON.stringify({
        defaultRatio: utils
          .parseEther(sliderDefaultValue.toString())
          .toString(),
        payoutRatio: utils.parseEther(sliderPayoutValue.toString()).toString(),
        poolName: values.poolName,
      })
    );
    if (txHash) {
      toast.success({
        title: `Session Create Successful!`,
        message: (
          <Link
            href={`${
              getBlockExplorerUrl(currentNetwork) || 'https://etherscan.io'
            }/tx/${txHash}`}
            isExternal
          >
            View transaction
          </Link>
        ),
      });
    }
  };

  const initialValues = {
    poolName: POOL.name,
    payoutRatio: '0.5',
    defaultRatio: '0.5',
  };

  const onExit = () => {
    setEnableSteps(false);
  };

  return (
    <CreatePool>
      <Tour steps={steps} isOpen={enableSteps} onRequestClose={onExit} />
      <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
        {() => (
          <Form>
            <FormLabel
              lineHeight="35px"
              fontSize="18px"
              color="white"
              fontWeight="700"
            >
              Set your payout ratio
              <CustomToolTip tooltipText={SIMULATION_FORM.PAYOUT_RATIO} />
            </FormLabel>
            <Slider
              id="payout-slider"
              defaultValue={sliderPayoutValue}
              min={0}
              max={1}
              step={0.01}
              marginTop="30px"
              onChange={(val) => setSliderPayoutValue(val)}
            >
              <SliderMark
                value={sliderPayoutValue}
                textAlign="center"
                color="white"
                mt="-10"
                ml="-6"
                w="12"
              >
                {sliderPayoutValue}
              </SliderMark>
              <SliderTrack bg="#13255E" height="10px">
                <Box position="relative" right={10} />
                <SliderFilledTrack bg="linear-gradient(104.25deg, #568FFF 11.56%, #20BFE2 93.48%)" />
              </SliderTrack>
              <SliderThumb boxSize={6} />
            </Slider>
            <FormLabel
              fontSize="18px"
              color="white"
              fontWeight="700"
              lineHeight="35px"
              marginTop="30px"
            >
              Set your default ratio
              <CustomToolTip tooltipText={SIMULATION_FORM.DEFAULT_RATION} />
            </FormLabel>
            <Slider
              id="default-slider"
              defaultValue={sliderDefaultValue}
              min={0}
              max={1}
              step={0.01}
              marginTop="30px"
              onChange={(val) => setSliderDefaultValue(val)}
            >
              <SliderMark
                value={sliderDefaultValue}
                textAlign="center"
                color="white"
                mt="-10"
                ml="-6"
                w="12"
              >
                {sliderDefaultValue}
              </SliderMark>
              <SliderTrack bg="#13255E" height="10px">
                <Box position="relative" right={10} />
                <SliderFilledTrack bg="linear-gradient(104.25deg, #568FFF 11.56%, #20BFE2 93.48%)" />
              </SliderTrack>
              <SliderThumb boxSize={6} />
            </Slider>
            <br />
            <Button
              isLoading={creatingSession}
              loadingText="Creating your pool"
              width="100%"
              height="45px"
              marginTop="45px"
              borderColor="#2288FF"
              color="#FFF"
              bg="#2288FF"
              type="submit"
              _hover={{ bg: '#2288FF' }}
              disabled={creatingSession}
            >
              Create your pool
            </Button>
          </Form>
        )}
      </Formik>
    </CreatePool>
  );
};

PoolCreationForm.defaultProps = {};

TokenField.defaultProps = {
  isMintable: false,
  mintPlaceholder: '',
};

NumberField.defaultProps = {
  isNotBigNumber: false,
  tooltip: '',
};
