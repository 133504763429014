/* eslint-disable camelcase */
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { Token } from 'src/models/Token';
import { TokenAmount } from 'src/models/TokenAmount';
import { RHVault__factory } from 'src/types/contracts/factories/RHVault__factory';
import { Optional } from 'src/types/Optional';

export function usePremium(
  vaultAddress: Optional<NetworkAddress>,
  poolId: Optional<string>,
  underwritingToken: Optional<Token>,
  amountToProtect: Optional<TokenAmount>,
  slippage: string
): { premium: Optional<TokenAmount>; maxPremium: Optional<TokenAmount> } {
  const [premium, setPremium] = useState<Optional<TokenAmount>>();
  const [maxPremium, setMaxPremium] = useState<Optional<TokenAmount>>();

  const { network, address } = vaultAddress ?? {};
  const networkId = network?.id;
  const addressStr = address?.toString();

  useEffect(() => {
    (async () => {
      if (
        !amountToProtect ||
        !underwritingToken ||
        !addressStr ||
        !network ||
        !poolId ||
        !slippage
      ) {
        return;
      }

      const vaultContract = RHVault__factory.connect(
        addressStr,
        network.defaultProvider
      );

      let premiumAmount: BigNumber;
      try {
        premiumAmount = await vaultContract.computePremium(
          poolId,
          amountToProtect.toBigNumber()
        );
      } catch {
        premiumAmount = BigNumber.from(0);
      }

      const maxPremiumAmount = premiumAmount
        .mul(BigNumber.from(1000).add(Math.ceil(Number(slippage) * 10)))
        .div(1000);

      setPremium(TokenAmount.from(underwritingToken, premiumAmount));
      setMaxPremium(TokenAmount.from(underwritingToken, maxPremiumAmount));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkId, addressStr, amountToProtect, slippage]);

  return { premium, maxPremium };
}
