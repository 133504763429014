import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Collapse } from '@chakra-ui/react';
import { Optional } from 'src/types/Optional';

interface Props {
  type: string;
  description: Optional<string>;
  risksDesc: Optional<string>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--description-font-size);
  font-weight: 500;
  & a {
    text-decoration: underline;
  }
`;

export const InfoDesc: React.FC<Props> = ({ type, description, risksDesc }) => {
  const [readMore] = useState(false); // can change to const
  const [readMore2] = useState(false);

  if (type === 'pool') {
    return (
      <Container>
        <Text>
          {description && <ReactMarkdown>{description}</ReactMarkdown>}
          <br />
          <div style={{ marginBottom: '1rem' }} />
          {risksDesc && <ReactMarkdown>{risksDesc}</ReactMarkdown>}
        </Text>
      </Container>
    );
  }

  return (
    <Container>
      <Text>
        {description && (
          <>
            <Collapse startingHeight="7rem" in={readMore}>
              <ReactMarkdown>{description}</ReactMarkdown>
            </Collapse>
          </>
        )}
        <br />
        <div style={{ marginBottom: '1rem' }} />
        {risksDesc && (
          <>
            <Collapse startingHeight="7rem" in={readMore2}>
              <ReactMarkdown>{risksDesc}</ReactMarkdown>
            </Collapse>
          </>
        )}
      </Text>
    </Container>
  );
};
