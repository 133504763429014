/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SUPPORTED_NETWORKS } from 'src/config/network';
import { RHNetworkConfig } from 'src/config/types';
import { Network } from 'src/models/Network';

interface NetworkState {
  value: Network<RHNetworkConfig>;
}

const initialState: NetworkState = {
  value: SUPPORTED_NETWORKS[0],
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    updateNetwork: (
      state,
      action: PayloadAction<typeof initialState.value>
    ) => {
      state.value = action.payload;
    },
  },
});

export const networkState = {
  ...networkSlice.actions,
  reducer: networkSlice.reducer,
};
