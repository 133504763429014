import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Tour from 'reactour';
import { Banner } from 'src/components/Banner/Banner';
import { OrderBox } from 'src/components/OrderBox';
import { InfoDesc } from 'src/components/PVInfo/InfoDesc';
import { usePool } from 'src/viewmodels/pool';
import { useVault } from 'src/viewmodels/vault';
import { useNetwork } from 'src/viewmodels/network';
import { useWallet } from 'src/viewmodels/wallet';
import { useParams } from 'react-router-dom';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { PoolContext } from 'src/contexts/PoolContext';
import { triggerEvent } from 'src/services/tagmanager';
import { GOOGLE_EVENTS, ProtectionDetails } from 'src/constants/constants';
import { useSimulator } from 'src/viewmodels/simulator';

const Section = styled.div`
  display: flex;
  column-gap: 2rem !important;

  @media only screen and (max-width: 1149px) {
    flex-direction: column;
    column-gap: 0rem !important;
  }
`;

const SubSection = styled.div`
  width: 54%;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

export const Pool: React.FC = () => {
  const { network, vaultId, poolId } =
    useParams<{ network: string; vaultId: string; poolId: string }>();
  const [enableSteps, setInitialEnableSteps] = useState<boolean>(false);

  const vaultAddress = useMemo(
    () => NetworkAddress.fromStrings(network, vaultId),
    [network, vaultId]
  );
  useEffect(() => {
    triggerEvent(GOOGLE_EVENTS.NETWORK_CHANGE);
  }, []);

  const poolContext = { vaultAddress, poolId };

  const steps = [
    {
      selector: '#order-box',
      content:
        'First, enter the amount that you want to protect. Next, choose your preferred slippage tolerance' +
        ', which is the highest percentage difference that you’ll tolerate between the quoted' +
        ' price and the actual price when the transaction processes. The “Payout”' +
        ' value demonstrates how much you would get paid out if a default event affects the pool. ' +
        'The “Premium” value is the price of protection.',
    },
  ];

  const { pool = undefined } = usePool({ vaultAddress, poolId }) ?? {};
  const { vault = undefined } = useVault(vaultAddress) ?? {};
  const { wallet } = useWallet();
  const { currentNetwork } = useNetwork();
  const { simulatorStage } = useSimulator();

  const dataLoaded = pool && vault && vaultAddress;
  const walletConnected = dataLoaded && wallet;
  const networkMismatch =
    dataLoaded &&
    walletConnected &&
    vaultAddress.network.id !== currentNetwork.id;

  return (
    <PoolContext.Provider value={poolContext}>
      <Tour
        steps={steps}
        isOpen={enableSteps}
        onRequestClose={() => setInitialEnableSteps(false)}
      />
      <Banner
        type="protection"
        poolInfo={pool}
        poolExpDate={vault?.expirationDate}
      />
      <Section>
        <SubSection>
          <InfoDesc
            type="pool"
            description={
              simulatorStage === 2
                ? ProtectionDetails.DESCP
                : ProtectionDetails.DESCC
            }
            risksDesc=""
          />
        </SubSection>
        <OrderBox type="pool" mismatchNetwork={networkMismatch} />
      </Section>
    </PoolContext.Provider>
  );
};
