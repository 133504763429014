// eslint-disable-next-line camelcase
import { ERC20__factory } from 'src/types/contracts/factories/ERC20__factory';
import { Wallet } from 'src/models/Wallet';
import { BigNumber, providers, Signer } from 'ethers';
import { getTokenVersion } from 'src/utils/ethereum/permit';
import { NetworkAddress } from 'src/models/NetworkAddress';

export async function getEIP721Support(
  tokenAddress: NetworkAddress
): Promise<boolean> {
  return !!getTokenVersion(tokenAddress);
}

export async function getAllowance(
  tokenAddress: string,
  wallet: Wallet,
  contractAddress: string
): Promise<BigNumber> {
  const user = wallet.account.toString();
  const tokenContract = ERC20__factory.connect(
    tokenAddress,
    wallet.provider as providers.Provider
  );
  const allowance = await tokenContract.allowance(user, contractAddress);
  return allowance;
}

export async function approveAmount(
  tokenAddress: string,
  wallet: Wallet,
  spender: string,
  amount: BigNumber
): Promise<{
  success: boolean;
  message?: string;
}> {
  try {
    const signer = wallet.provider.getSigner();
    const tokenContract = ERC20__factory.connect(
      tokenAddress,
      signer as Signer
    );

    const tx = await tokenContract.approve(spender, amount);
    await tx.wait(1);
    return {
      success: true,
    };
  } catch (Err) {
    return {
      success: false,
      message: Err ? (Err as Error).message : 'Approval error',
    };
  }
}
