import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Address } from 'src/models/Address';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { useAppSelector } from 'src/state';
import { startTokenSync } from 'src/state/actions';
import { Storage, Token, TokenID } from 'src/state/contracts';
import { Optional } from 'src/types/Optional';
import { NetworkID } from 'src/models/Network';

export function useTokenByNetwork(
  networkId: NetworkID
): Optional<Record<TokenID, Storage<Token>>> {
  const tokenStorage: Optional<Record<TokenID, Storage<Token>>> =
    useAppSelector((state) => state.contracts.tokens[networkId]);
  return tokenStorage;
}

export function useToken(
  tokenAddress: Optional<NetworkAddress>
): Optional<Token> {
  const dispatch = useDispatch();

  const network = tokenAddress?.network.id;
  const address = tokenAddress?.address.toString();

  useEffect(() => {
    if (!address || Address.fromString(address).isZeroAddress() || !network) {
      return;
    }

    dispatch(
      startTokenSync({
        tokenAddress: NetworkAddress.fromStrings(network, address),
      })
    );
  }, [dispatch, network, address]);

  const tokenStorage: Optional<Storage<Token>> = useAppSelector((state) =>
    tokenAddress
      ? state.contracts.tokens[tokenAddress.network.id][
          tokenAddress.address.toString()
        ]
      : undefined
  );
  return tokenStorage?.value;
}
