import { BigNumber } from 'ethers';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { Vault } from 'src/state/contracts';
import { Optional } from 'src/types/Optional';

export function useLeverage(vault: Optional<Vault>): string {
  const [leverage, setLeverage] = useState('0.0x');

  useEffect(() => {
    if (!vault) return;
    const { allocationVector } = vault.state;
    const aZero = allocationVector[0];
    if (aZero.isZero()) {
      return;
    }
    let sum = BigNumber.from(0);

    for (let i = 1; i < allocationVector.length; i += 1) {
      sum = sum.add(allocationVector[i]);
    }

    if (sum.isZero()) return;

    // mul by 100, so we can get some precision
    const res = numeral(sum.mul(100).div(aZero).toNumber() / 100).format(
      '0.0[0]'
    );
    setLeverage(`${res}x`);
  }, [vault]);

  return leverage;
}
