/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumberish } from 'ethers';
import { NetworkID } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import {
  AMMStorageStructOutput,
  PoolStorageStructOutput,
  PositionStorageStructOutput,
  VaultConfigStructOutput,
  VaultStateStructOutput,
} from 'src/types/contracts/RHVault';
import { Optional } from 'src/types/Optional';

export type VaultID = string;
export type PoolID = string;
export type PositionID = string;
export type TokenID = string;

export type Pool = PoolStorageStructOutput;
export type Position = PositionStorageStructOutput & { id: PositionID };
export type Token = {
  name: string;
  symbol: string;
  decimals: number;
  balance: Optional<BigNumberish>;
};

export type Storage<T extends Vault | Pool | Position | Token> = {
  updatedAt: Date;
  value: T;
};

export type Vault = {
  config: VaultConfigStructOutput;
  state: VaultStateStructOutput;
  amm: AMMStorageStructOutput;
};

interface ContractsState {
  vaults: Record<NetworkID, Record<VaultID, Storage<Vault>>>;
  pools: Record<NetworkID, Record<VaultID, Record<PoolID, Storage<Pool>>>>;
  positions: Record<
    NetworkID,
    Record<VaultID, Record<PositionID, Storage<Position>>>
  >;
  tokens: Record<NetworkID, Record<TokenID, Storage<Token>>>;
}

const initialState: ContractsState = {
  vaults: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
  pools: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
  positions: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
  tokens: {
    [NetworkID.mainnet]: {},
    [NetworkID.ropsten]: {},
    [NetworkID.rinkeby]: {},
    [NetworkID.arbitrum]: {},
    [NetworkID.arbitrumRinkeby]: {},
    [NetworkID.optimism]: {},
    [NetworkID.optimismKovan]: {},
    [NetworkID.polygon]: {},
    [NetworkID.polygonMumbai]: {},
    [NetworkID.avalanche]: {},
    [NetworkID.avalancheFuji]: {},
    [NetworkID.fantom]: {},
    [NetworkID.fantomTestnet]: {},
    [NetworkID.bsc]: {},
    [NetworkID.bscTestnet]: {},
    [NetworkID.unsupported]: {},
  },
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    loadVault: (
      state,
      action: PayloadAction<{ vaultAddress: NetworkAddress; vault: Vault }>
    ) => {
      const { vaultAddress, vault } = action.payload;

      state.vaults[vaultAddress.network.id][vaultAddress.address.toString()] = {
        updatedAt: new Date(),
        value: vault,
      };
    },
    loadPool: (
      state,
      action: PayloadAction<{
        vaultAddress: NetworkAddress;
        poolId: PoolID;
        pool: Pool;
      }>
    ) => {
      const {
        vaultAddress: { network, address },
        poolId,
        pool,
      } = action.payload;

      if (!state.pools[network.id]) {
        state.pools[network.id] = {};
      }

      if (!state.pools[network.id][address.toString()]) {
        state.pools[network.id][address.toString()] = {};
      }

      state.pools[network.id][address.toString()][poolId] = {
        updatedAt: new Date(),
        value: pool,
      };
    },
    loadPosition: (
      state,
      action: PayloadAction<{
        vaultAddress: NetworkAddress;
        positionId: PositionID;
        position: Position;
      }>
    ) => {
      const {
        vaultAddress: { network, address },
        positionId,
        position: positionData,
      } = action.payload;

      const position = {
        ...positionData,
        id: positionId,
      };

      if (!state.positions[network.id]) {
        state.positions[network.id] = {};
      }

      if (!state.positions[network.id][address.toString()]) {
        state.positions[network.id][address.toString()] = {};
      }

      state.positions[network.id][address.toString()][positionId] = {
        updatedAt: new Date(),
        value: position,
      };
    },
    loadToken: (
      state,
      action: PayloadAction<{ tokenAddress: NetworkAddress; token: Token }>
    ) => {
      const { tokenAddress, token } = action.payload;
      const { network, address } = tokenAddress;

      state.tokens[network.id][address.toString()] = {
        updatedAt: new Date(),
        value: token,
      };
    },
    loadTokenBalance: (
      state,
      action: PayloadAction<{
        tokenAddress: NetworkAddress;
        tokenBalance: string;
      }>
    ) => {
      const { tokenAddress, tokenBalance } = action.payload;
      const { network, address } = tokenAddress;

      state.tokens[network.id][address.toString()].updatedAt = new Date();
      state.tokens[network.id][address.toString()].value.balance = tokenBalance;
    },
    clearVault: (
      state,
      action: PayloadAction<{ vaultAddress: NetworkAddress }>
    ) => {
      const { vaultAddress } = action.payload;
      delete state.vaults[vaultAddress.network.id][
        vaultAddress.address.toString()
      ];
    },
    clearPool: (
      state,
      action: PayloadAction<{ vaultAddress: NetworkAddress; poolId: PoolID }>
    ) => {
      const {
        vaultAddress: { address, network },
        poolId,
      } = action.payload;

      delete state.pools[network.id][address.toString()][poolId];
    },
    clearPosition: (
      state,
      action: PayloadAction<{
        vaultAddress: NetworkAddress;
        positionId: PositionID;
      }>
    ) => {
      const {
        vaultAddress: { network, address },
        positionId,
      } = action.payload;

      delete state.positions[network.id][address.toString()][positionId];
    },
    clearToken: (
      state,
      action: PayloadAction<{ tokenAddress: NetworkAddress }>
    ) => {
      const { tokenAddress } = action.payload;
      const { network, address } = tokenAddress;

      delete state.tokens[network.id][address.toString()];
    },
  },
});

export const contractsState = {
  ...contractsSlice.actions,
  reducer: contractsSlice.reducer,
};
