import { constants, Signer } from 'ethers';
import { useDispatch } from 'react-redux';
import { useWallet } from 'src/viewmodels/wallet';
import { useAppSelector } from 'src/state';
import { simulatorState } from 'src/state/simulator';
// eslint-disable-next-line camelcase
import { SimulatorFactory__factory } from 'src/types/contracts/factories/SimulatorFactory__factory';
import useToaster from 'src/hooks/useToast';
import { message } from 'src/components/OrderBox/MessageTransaction';

export type SessionForm = {
  defaultRatio: string;
  payoutRatio: string;
  poolName: string;
};

export function useSimulator() {
  const { wallet } = useWallet();
  const dispatch = useDispatch();
  const toast = useToaster();
  const simulatorStage = useAppSelector((state) => state.simulator.stage);
  const networkInfo = useAppSelector((state) => state.network.value);

  const FACTORY_ADDRESS = '0x1d724e2437511797744bD2d291987bEc5ca42D56';
  const UNDERWRITING_TOKEN = '0x6620a98f764c53ad415836300201D862Bc4093c0';
  const REWARD_TOKEN = '0x5AEBD90C158097Ae5022a70A80aDe2d516103283';
  const PROTECTED_TOKEN = '0x55df1Ac6d7000f1939D8C0cb1B5Bdf199f977132';

  async function createSession(vaultInfo: SessionForm) {
    if (!wallet) {
      throw new Error('No wallet connected');
    }
    try {
      const SimulatorFactoryContract = SimulatorFactory__factory.connect(
        FACTORY_ADDRESS,
        wallet.provider.getSigner() as Signer
      );

      const tx = await SimulatorFactoryContract.newSession(
        vaultInfo.poolName,
        constants.WeiPerEther.mul(1000),
        {
          underwritingToken: UNDERWRITING_TOKEN,
          rewardToken: REWARD_TOKEN,
          start: Math.round(new Date().getTime() / 1000).toString(),
          expiration: Math.round(
            new Date().getTime() / 1000 + 31536000
          ).toString(),
          underwritingTokenDecimals: 18,
          totalRewards: 0,
        },
        {
          defaultector: FACTORY_ADDRESS,
          protectedToken: PROTECTED_TOKEN,
          tokensNeededToCheck: false,
          defaultRatio: { numerator: 1, denominator: 2 },
          payoutRatio: { numerator: 1, denominator: 2 },
          other: '0x',
        },
        {
          expectedXVector: [{ numerator: 1, denominator: 1 }],
          varCovarMatrix: [{ numerator: 1, denominator: 1 }],
          lambda: { numerator: 0, denominator: 1 },
        },
        {
          expectedXVector: [
            { numerator: '1', denominator: '1' },
            { numerator: '1', denominator: '1' },
          ],
          varCovarMatrix: [
            { numerator: '0', denominator: '1' },
            { numerator: '0', denominator: '1' },
            { numerator: '0', denominator: '1' },
            { numerator: '0', denominator: '1' },
          ],
          lambda: { numerator: '0', denominator: '1' },
        }
      );
      const blockExporerUrls =
        networkInfo.config.walletConfig.blockExplorerUrls;
      const scannerUrl = `${
        blockExporerUrls && blockExporerUrls.length > 0
          ? blockExporerUrls[0]
          : 'https://etherscan.io'
      }/tx/${tx.hash}`;
      toast.success({
        title: 'Confirming transaction!',
        message: message(scannerUrl),
      });
      await tx.wait(3);
      dispatch(simulatorState.updateSimulator(1));
      return tx.hash;
    } catch (Err) {
      return false;
    }
  }

  async function toggleHack() {
    if (!wallet) {
      throw new Error('No wallet connected');
    }
    try {
      const SimulatorFactoryContract = SimulatorFactory__factory.connect(
        FACTORY_ADDRESS,
        wallet.provider.getSigner() as Signer
      );
      const tx = await SimulatorFactoryContract.toggleHack();
      await tx.wait(1);
      dispatch(simulatorState.updateSimulator(4));
      return tx.hash;
    } catch (Err) {
      return false;
    }
  }

  async function getUserVaults() {
    if (!wallet) {
      throw new Error('No wallet connected');
    }
    try {
      const SimulatorFactoryContract = SimulatorFactory__factory.connect(
        FACTORY_ADDRESS,
        wallet.provider.getSigner() as Signer
      );
      const createdVaultData = await SimulatorFactoryContract.userCreatedVaults(
        wallet.account.toString()
      );
      return createdVaultData;
    } catch (Err) {
      return '0x0';
    }
  }

  async function getHackStatus(address: string) {
    if (!wallet) {
      throw new Error('No wallet connected');
    }
    try {
      const SimulatorFactoryContract = SimulatorFactory__factory.connect(
        FACTORY_ADDRESS,
        wallet.provider.getSigner() as Signer
      );
      const hackStatus = await SimulatorFactoryContract.vaultHackedStatus(
        address
      );
      /* const hackStatus = await SimulatorFactoryContract.softCheck({
        defaultector: '0x44eD4412B4B2739bD5Aa6edFBFC77c63C27F6cef',
        tokensNeededToCheck: false,
        protectedToken: PROTECTED_TOKEN,
        defaultRatio: { numerator: 1, denominator: 2 },
        payoutRatio: { numerator: 1, denominator: 2 },
        other:
          '0x5bda7162b84dff721bcb8f777278b488ded8d1475264d4361c6b0abfcc2a99a8',
      }); */
      return hackStatus;
    } catch (Err) {
      return false;
    }
  }

  return {
    createSession,
    toggleHack,
    getUserVaults,
    getHackStatus,
    simulatorStage,
  };
}
