import React, { useState } from 'react';
import styled from 'styled-components';
import errorIcon from 'src/assets/error-icon.png';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const FlexBox = styled.div`
  display: flex;
`;

const Icon = styled.img`
  height: 20px;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
`;

const Content = styled(ModalContent)`
  max-width: 415px !important;
  background-color: var(--modal-blue) !important;

  @media only screen and (max-width: 499px) {
    max-width: 300px !important;
  }
`;

const Header = styled(ModalHeader)`
  color: var(--text-primary-white);
  font-size: var(--h3-font-size);

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

const ErrorText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const Text = styled.p`
  font-size: ar(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 499px) {
    font-size: 1.5rem;
  }
`;

interface Props {
  message: string;
}

export const ErrorModal: React.FC<Props> = ({ message }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    document.location.href = '/';
  };

  return (
    <>
      <Modal isOpen={open} onClose={handleClose}>
        <ModalOverlay bg="var(--overlay-modal-black)" />
        <Content>
          <Header>
            <FlexBox>
              <Icon src={errorIcon} alt="error-icon" />
              Something went wrong
            </FlexBox>
          </Header>
          <ModalCloseButton
            style={{ color: 'white', marginTop: 6 }}
            _focus={{ outline: 'none' }}
            _hover={{ filter: 'brightness(65%)' }}
          />
          <ModalBody marginBottom={5}>
            <ErrorText>{message}</ErrorText>
            <Text onClick={handleClose}>Go back home</Text>
          </ModalBody>
        </Content>
      </Modal>
    </>
  );
};
