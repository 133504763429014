import { WalletType } from 'src/config/wallets';

export type ConnectivityDetails = {
  source: WalletType;
  connected: boolean;
  chainId: number;
};

export const getLocalConnectivityData = (): ConnectivityDetails => {
  const localData = window.localStorage.getItem('connectivity');
  let connectTypeData: ConnectivityDetails;
  try {
    connectTypeData = JSON.parse(localData || '');
  } catch (Err) {
    connectTypeData = {
      connected: false,
      source: WalletType.metamask,
      chainId: 1,
    };
  }
  if (!connectTypeData) {
    connectTypeData = {
      connected: false,
      source: WalletType.metamask,
      chainId: 1,
    };
  }
  return {
    source: connectTypeData.source,
    connected: connectTypeData.connected,
    chainId: connectTypeData.chainId,
  };
};

export const setLocalConnectivityData = (data: ConnectivityDetails): void => {
  window.localStorage.setItem('connectivity', JSON.stringify(data));
};
