import { Token } from 'src/models/Token';
import { TokenAmount } from 'src/models/TokenAmount';
import { Optional } from 'src/types/Optional';
import { VaultView } from 'src/viewmodels/vault';

/* // Vault.sol L#282
function computeProtectTokenValueInUnderwriting(vaultView: VaultView) {
  const { pools } = vaultView;
  const protectedTokenValueInUnderwriting: BigNumber = BigNumber.from(0);
  pools.forEach((pool) => {
    const { config, state } = pool.rawState;
    const swappedTokenValueInUnderwriting = state.protectedTokensSwapped
      .mul(config.payoutRatio.numerator)
      .div(config.payoutRatio.denominator);
    protectedTokenValueInUnderwriting.add(swappedTokenValueInUnderwriting);
  });

  return protectedTokenValueInUnderwriting;
}

// Vault.sol computeCurrentValue(...)
function computeCurrentVaultValue(vaultView: VaultView) {
  const undrippedPrems = undrippedPremiums(vaultView?.rawState);
  const protectedTokenValueInUnderwriting =
    computeProtectTokenValueInUnderwriting(vaultView);
  return vaultView.rawState.state.allocationVector[0]
    .sub(undrippedPrems)
    .add(protectedTokenValueInUnderwriting);
}

// Calculate the expect shares given an amount of underwriting tokens
// Vault.sol#L79
function calculateExpectedShares(
  amountInUnderwriting: TokenAmount,
  vaultView: VaultView
) {
  const { totalShares } = vaultView.rawState.state;
  const currentVaultValue = computeCurrentVaultValue(vaultView);
  return totalShares.isZero()
    ? amountInUnderwriting.amount
    : amountInUnderwriting.amount.mul(totalShares).div(currentVaultValue);
}

// MathLib.sol#65
function rewards(
  positionShares: BigNumber,
  vaultTotalRewards: BigNumber,
  vaultTotalAmortizedShares: BigNumber,
  vaultStart: number,
  positionStart: number,
  vaultExpiration: number
) {
  const vaultDuration = BigNumber.from(vaultExpiration - vaultStart);
  const remainingDuration = BigNumber.from(vaultExpiration - positionStart);
  const amortizedShares = positionShares
    .mul(remainingDuration)
    .div(vaultDuration);

  // If they are the first depositor, vaultTotalAmortizedShares == 0
  // throwing a division by zero errors
  if (!vaultTotalAmortizedShares || vaultTotalAmortizedShares.isZero())
    return vaultTotalRewards;
  return vaultTotalRewards.mul(amortizedShares).div(vaultTotalAmortizedShares);
}
 */
export function calculateRewards(vaultView: Optional<VaultView>) {
  const vault = vaultView?.rawState;
  // Todo: need function to turn addr into Token object
  const rewardToken: Token = {
    name: 'Risk Harbor Tickets',
    symbol: 'RxHT',
    decimals: 18,
  };

  if (!vault) return TokenAmount.zero(rewardToken);
  return TokenAmount.from(rewardToken, vault.config.totalRewards);

  /*   const totalRewardsInVault = vault.config.totalRewards;

  // Short circuit if there are no rewards in the vault
  if (!totalRewardsInVault || totalRewardsInVault.isZero())
    return TokenAmount.zero(rewardToken);

  const sharesGivenAmount = calculateExpectedShares(amountToDeposit, vaultView);

  const {
    state: { totalAmortizedShares },
    config: { start, expiration },
  } = vault;

  const expectedRewards = rewards(
    sharesGivenAmount,
    totalRewardsInVault,
    totalAmortizedShares || BigNumber.from(0),
    start,
    Math.floor(Date.now() / 1000),
    expiration
  );

  return TokenAmount.from(rewardToken, expectedRewards); */
}
