export enum ErrorID {
  RHError = 'RHError',
  InvalidAddress = 'InvalidAddress',
  InvalidOrUnsupportedNetwork = 'InvalidOrUnsupportedNetwork',
  UncaughtError = 'UncaughtError',
  MiscRHError = 'MiscRHError',
  NotFoundError = 'NotFoundError',
  MessageError = 'MessageError',
}

export abstract class RHError extends Error {
  abstract readonly ID: ErrorID;

  abstract readonly metadata: Record<string, unknown>;

  public static formatObject(obj: Record<string, unknown>): string {
    return JSON.stringify(obj, null, 2);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public is(ErrorType: any): boolean {
    return this instanceof ErrorType;
  }
}

export class InvalidAddress extends RHError {
  readonly ID = ErrorID.InvalidAddress;

  readonly metadata: {
    address: string;
  };

  constructor(address: string) {
    super(`Invalid address: ${address}`);
    this.metadata = { address };
  }
}

export class InvalidOrUnsupportedNetworkError extends RHError {
  readonly ID = ErrorID.InvalidOrUnsupportedNetwork;

  readonly metadata: {
    network:
      | {
          name: string;
        }
      | { chainId: string | number };
  };

  private constructor(
    metadata:
      | {
          name: string;
        }
      | { chainId: string | number }
  ) {
    super(
      `Invalid or unsupported network: ${InvalidOrUnsupportedNetworkError.formatObject(
        metadata
      )}`
    );
    this.metadata = { network: metadata };
  }

  public static fromName(name: string) {
    return new InvalidOrUnsupportedNetworkError({ name });
  }

  public static fromChainId(chainId: string | number) {
    return new InvalidOrUnsupportedNetworkError({ chainId });
  }
}

export class UncaughtError extends RHError {
  readonly ID = ErrorID.UncaughtError;

  readonly metadata: {
    error: Error;
    resolvedError?: RHError;
  };

  readonly resolvedError?: RHError;

  constructor(error: Error) {
    super(error.message || 'Something went wrong');
    const resolvedError = UncaughtError.tryResolvingError(error);
    this.metadata = { error, resolvedError };
    this.resolvedError = resolvedError;
  }

  // TODO(jason): Add to this and remove eslint-disable below
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private static tryResolvingError(error: Error): RHError | undefined {
    if (UncaughtError.isRHError(error)) {
      return error;
    }

    return undefined;
  }

  private static isRHError(error: Error): error is RHError {
    return Boolean((error as RHError).ID);
  }
}

export class MiscRHError extends RHError {
  readonly ID = ErrorID.MiscRHError;

  readonly metadata: Record<string, unknown>;

  constructor(...args: unknown[]) {
    super(`Something went wrong`);
    this.metadata = { ...args };
  }
}

export class NotFoundError extends RHError {
  readonly ID = ErrorID.NotFoundError;

  readonly metadata: Record<string, unknown>;

  constructor(metadata: Record<string, unknown>) {
    super(`Not found error: ${NotFoundError.formatObject(metadata)}`);
    this.metadata = metadata;
  }
}

export class MessageError extends RHError {
  readonly ID = ErrorID.MessageError;

  readonly metadata: Record<string, unknown>;

  constructor(message: string, ...args: unknown[]) {
    super(message);
    this.metadata = { ...args };
  }
}
