import { Address } from 'src/models/Address';
import { NetworkID } from 'src/models/Network';

export const deployments = {
  [NetworkID.mainnet]: {
    router: Address.fromString('0x2806c3d95c9e90bb2bae17efbd2099d53a80dfe6'),
    ticket: Address.fromString('0x56fc70e104aEa357F9E795c245be3689117D0030'),
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-mainnet',
  },
  [NetworkID.ropsten]: {
    router: Address.fromString('0x557970aEBD504a84dc8B3b535AB02F603418A7c8'),
    ticket: Address.fromString('0x34a1764D5C5A1e67F4538f3E0e5F106aB6e38896'),
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-ropsten',
  },
  [NetworkID.rinkeby]: {
    router: Address.fromString('0x2Ec293CE758F868A31510AA87edC3adB24e28cF3'),
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/yashthakor/simulator/graphql',
  },
  [NetworkID.arbitrum]: {
    router: Address.fromString('0xa72d705858c602f8d5439179742e8aaca0177022'),
    ticket: Address.fromString('0x7c1187af4d6b23f7f7682799454168e24bc06eed'),
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-arbitrum',
  },
  [NetworkID.arbitrumRinkeby]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-arbitrum-rinkeby',
  },
  [NetworkID.optimism]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-optimism',
  },
  [NetworkID.optimismKovan]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-optimism-kovan',
  },
  [NetworkID.polygon]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-polygon',
  },
  [NetworkID.polygonMumbai]: {
    router: Address.fromString('0x8d93c37aa32dff755683acf26323ee3329e22120'),
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/yashthakor/simulator-mumbai',
  },
  [NetworkID.avalanche]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-avalanche',
  },
  [NetworkID.avalancheFuji]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-avalanche-fuji',
  },
  [NetworkID.fantom]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-fantom',
  },
  [NetworkID.fantomTestnet]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph:
      'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-fantom-dev',
  },
  [NetworkID.bsc]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-bsc',
  },
  [NetworkID.bscTestnet]: {
    router: Address.Zero,
    ticket: Address.Zero,
    subgraph: 'https://api.thegraph.com/subgraphs/name/risk-harbor/v2-bsc-dev',
  },
};
