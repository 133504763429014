/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { put, take, fork, select, race, call } from 'redux-saga/effects';
import { RHNetworkConfig } from 'src/config/types';
import { WalletType } from 'src/config/wallets';
import { SUPPORTED_CHAIN_IDS } from 'src/config/network';
import { MiscRHError } from 'src/errors';
import { Address } from 'src/models/Address';
import { Network } from 'src/models/Network';
import {
  disconnectWallet,
  connectWeb3Wallet,
  newBlockAction,
} from 'src/state/actions';
import { networkState } from 'src/state/network';
import { walletState } from 'src/state/wallet';
import { logger } from 'src/utils/logger';
import { resolveNetwork } from 'src/utils/network';
import {
  getLocalConnectivityData,
  setLocalConnectivityData,
} from 'src/utils/localStore';
import { triggerEvent } from 'src/services/tagmanager';
import { GOOGLE_EVENTS } from 'src/constants/constants';

type Web3Provider = ethers.providers.Web3Provider;

function getChainId(provider: Web3Provider): string {
  // TODO(jason): Maybe remove the default here
  return (
    provider.network?.chainId ||
    (provider as any).chainId ||
    (provider.provider as any).chainId ||
    '0x1'
  );
}

async function waitForAccountChange(provider: Web3Provider): Promise<boolean> {
  return new Promise((resolve) => {
    (provider.provider as any).once('accountsChanged', () => {
      resolve(true);
    });
  });
}

async function waitForNetworkChanged(provider: Web3Provider): Promise<string> {
  return new Promise((resolve) => {
    (provider.provider as any).once('chainChanged', (chainId: string) => {
      resolve(chainId);
    });
  });
}

async function waitForNewBlock(provider: Web3Provider): Promise<number> {
  return new Promise((resolve) => {
    provider.once('block', (blockNumber: number) => {
      resolve(blockNumber);
    });
  });
}

async function switchWalletNetwork(
  provider: Web3Provider,
  network: Network<RHNetworkConfig>
) {
  try {
    await provider.send('wallet_switchEthereumChain', [
      {
        chainId: network.config.walletConfig.chainId,
      },
    ]);
  } catch (switchError) {
    if ((switchError as any).code === 4902) {
      await provider.send('wallet_addEthereumChain', [
        network.config.walletConfig,
      ]);
    } else {
      throw switchError;
    }
  }
}

function* newBlockListener(provider: Web3Provider) {
  // @ts-ignore
  while (true) {
    // @ts-ignore
    const newBlockNumber = yield waitForNewBlock(provider);
    yield put(newBlockAction(newBlockNumber));
  }
}

function* accountsChangeListener(provider: Web3Provider) {
  // @ts-ignore
  while (true) {
    yield waitForAccountChange(provider);
    const newAccount: string = yield provider.getSigner().getAddress();
    yield put(walletState.updateAccount(Address.fromString(newAccount)));
  }
}

function* networkChangeListener(provider: Web3Provider) {
  while (true) {
    // @ts-ignore
    const newChainId = yield waitForNetworkChanged(provider);
    const network: Network<RHNetworkConfig> = yield select(
      (state) => state.network.value
    );

    const newNetwork = resolveNetwork(newChainId);

    if (network.chainId !== newNetwork.chainId) {
      const connectivityData = getLocalConnectivityData();
      setLocalConnectivityData({
        ...connectivityData,
        chainId: network.chainId,
      });
      yield put(networkState.updateNetwork(newNetwork));
    }
  }
}

function* userNetworkChangeListener(provider: Web3Provider) {
  let oldNetwork: Network<RHNetworkConfig> = yield select(
    (state) => state.network.value
  );
  // @ts-ignore
  while (yield take(networkState.updateNetwork.type)) {
    const network: Network<RHNetworkConfig> = yield select(
      (state) => state.network.value
    );

    try {
      yield switchWalletNetwork(provider, network);
      triggerEvent(GOOGLE_EVENTS.NETWORK_CHANGE);
      const connectivityData = getLocalConnectivityData();
      setLocalConnectivityData({
        ...connectivityData,
        chainId: network.chainId,
      });
      oldNetwork = network;
    } catch (err) {
      logger.error(
        `Switching app network state back to ${oldNetwork.displayName} due to error`,
        new MiscRHError(err)
      );

      yield put(networkState.updateNetwork(oldNetwork));
    }
  }
}

function* web3WalletWorker(walletType: WalletType) {
  let web3Wallet: any;
  // TODO(jason): Support coinbase wallet and other injected providers here parametrically/dynamically
  if ([WalletType.metamask, WalletType.coinbase].includes(walletType)) {
    const { providers = [] } = (window as any)?.ethereum ?? {};
    const isCorrectType = (p: any) =>
      walletType === WalletType.metamask ? p?.isMetaMask : p?.isCoinbaseWallet;

    web3Wallet = providers
      ? providers.find((p: any) => isCorrectType(p)) || (window as any).ethereum
      : (window as any).ethereum;

    if (!isCorrectType(web3Wallet)) {
      logger.error(`injected provider is not ${walletType}`);
      return;
    }

    try {
      // TODO(jason): Migrate to "eth_requestAccounts" RPC method instead (.enable() is deprecated)
      yield web3Wallet.request({ method: 'eth_requestAccounts' });
      // yield metamask.enable();
      triggerEvent(GOOGLE_EVENTS.WALLET_CONNECT, walletType);
    } catch (err) {
      logger.error(
        'Exiting wallet connect flow due to error',
        new MiscRHError(err)
      );
      return;
    }
  } else if (walletType === WalletType.walletconnect) {
    web3Wallet = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_ID,
    });

    try {
      yield web3Wallet.enable();
    } catch (err) {
      logger.error(
        'Exiting wallet connect flow due to error',
        new MiscRHError(err)
      );
      return;
    }
  }

  const provider = new ethers.providers.Web3Provider(web3Wallet);
  provider.pollingInterval = 6_000;

  const network = resolveNetwork(getChainId(provider));
  const currentNetwork: Network<RHNetworkConfig> = yield select(
    (state) => state.network.value
  );
  if (SUPPORTED_CHAIN_IDS.includes(network.chainId)) {
    setLocalConnectivityData({
      source: walletType,
      connected: true,
      chainId: network.chainId,
    });
  }
  if (currentNetwork.chainId !== network.chainId) {
    try {
      yield switchWalletNetwork(provider, currentNetwork);
      setLocalConnectivityData({
        source: walletType,
        connected: true,
        chainId: currentNetwork.chainId,
      });
    } catch (err) {
      logger.error(
        'Exiting wallet connect flow due to error',
        new MiscRHError(err)
      );
      return;
    }
  }

  const account: string = yield provider.getSigner().getAddress();

  yield put(
    walletState.loadWallet({
      provider,
      account: Address.fromString(account),
      type: walletType,
    })
  );

  yield fork(accountsChangeListener, provider);
  yield fork(networkChangeListener, provider);
  yield fork(userNetworkChangeListener, provider);
  yield fork(newBlockListener, provider);
}

export function* web3WalletSaga() {
  while (true) {
    const { payload: walletType }: ReturnType<typeof connectWeb3Wallet> =
      yield take(connectWeb3Wallet.type);

    yield race({
      job: call(web3WalletWorker, walletType),
      cancel: take(disconnectWallet.type),
    });
  }

  // yield takeLeading(connectWeb3Wallet.type, race({}));
  // // @ts-ignore
  // while (yield take(connectWeb3Wallet.type)) {
  //   // @ts-ignore
  //   const web3WalletJob = yield fork(web3WalletWorker);
  //   yield take(disconnectWallet.type);
  //   yield cancel(web3WalletJob);
  // }
}
