import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  InputGroup,
  Input,
  InputRightAddon,
  InputLeftElement,
  Button,
} from '@chakra-ui/react';
import { Optional } from 'src/types/Optional';
import { TokenAmount } from 'src/models/TokenAmount';
import { Token } from 'src/models/Token';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const InputBox = styled(Input)`
  margin-bottom: 1rem;
  text-align: right;
`;

const IR = styled(InputRightAddon)`
  background-color: var(--background-blue) !important;
  border: 2px solid var(--background-blue) !important;
  color: var(--text-primary-white);
  font-weight: 600;
`;

const MaxButton = styled(Button)`
  width: 3rem !important;
  height: 60% !important;
  border-radius: 100px !important;
  background-color: var(--components-primary-blue) !important;
  color: var(--text-primary-white);
  font-size: var(--p2-font-size) !important;
  padding: 0.3rem !important;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

interface Props {
  actionType?: string;
  token: Optional<Token>;
  amount: TokenAmount;
  maxAmount: TokenAmount;
  onChange: (amount: TokenAmount) => void;
}

export const TokenAmountInput: React.FC<Props> = ({
  actionType,
  token,
  amount,
  maxAmount,
  onChange,
}) => {
  const intl = useIntl();
  let amountText = intl.formatMessage({
    id: 'token-amount-text',
    defaultMessage: 'Amount',
  });

  if (actionType === 'Withdraw') {
    amountText = intl.formatMessage({
      id: 'shares-withdraw-text',
      defaultMessage: 'Shares',
    });
  }

  const strAmount = amount.toDecimalString();

  return (
    <Container>
      <Text>{amountText}</Text>
      <InputGroup>
        <InputLeftElement width="3rem !important" marginLeft="8px">
          <MaxButton onClick={() => onChange(maxAmount)}>MAX</MaxButton>
        </InputLeftElement>
        <InputBox
          value={strAmount !== '0' ? strAmount : ''}
          onChange={(event: InputEvent) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rawAmount = (event.target as any)?.value || '';
            const parsedAmount = TokenAmount.parseFromRawString(
              token || amount.token,
              rawAmount
            );

            if (
              !['', '.', '0', '0.', '0.0'].includes(rawAmount) &&
              parsedAmount.toRawString() === '0'
            ) {
              return;
            }

            onChange(parsedAmount);
          }}
          placeholder="0"
          _placeholder={{ opacity: '0.7', color: 'var(--text-primary-white)' }}
          border="2px solid var(--background-blue) !important"
          focusBorderColor="none"
          color="var(--text-secondary-gray)"
        />
        <IR>
          {token?.symbol === 'ePyvCurve-alUSD-29APR22'
            ? token?.symbol.substring(0, 4)
            : token?.symbol}
        </IR>
      </InputGroup>
    </Container>
  );
};

TokenAmountInput.defaultProps = {
  actionType: '',
};
