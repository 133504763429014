export interface SerDe {
  serializeToString<T>(x: T): string;
  deserializeFromString<T>(x: string): T;
}

export const serde: SerDe = {
  serializeToString(x) {
    return JSON.stringify(x);
  },
  deserializeFromString(x) {
    return JSON.parse(x);
  },
};
