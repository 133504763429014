import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Banner } from 'src/components/Banner/Banner';
import { OrderBox } from 'src/components/OrderBox';
import { InfoDesc } from 'src/components/PVInfo/InfoDesc';
import { PoolBreakDown } from 'src/components/TableComponents/PoolBreakDown';
import { useVault, VaultView } from 'src/viewmodels/vault';
import Tour from 'reactour';
import { useNetwork } from 'src/viewmodels/network';
import { useWallet } from 'src/viewmodels/wallet';
import { useParams } from 'react-router-dom';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { VaultContext } from 'src/contexts/VaultContext';
import { useCMSPools } from 'src/services/cms';
import { Pool } from 'src/config/cms';
import { VaultDetails } from 'src/constants/constants';

const Section = styled.div`
  display: flex;
  column-gap: 2rem !important;

  @media only screen and (max-width: 1109px) {
    flex-direction: column;
    column-gap: 0rem !important;
  }
`;

const SubSection = styled.div`
  width: 54%;

  @media only screen and (max-width: 1109px) {
    width: 100%;
  }
`;

const mergeVaultAndCMS = (
  vault: VaultView | undefined,
  poolsCmsData: Pool[] | undefined
): VaultView | undefined => {
  if (!vault || !poolsCmsData) return;
  vault.pools.forEach((pool, index) => {
    const cmsPool = poolsCmsData.find(
      (p) => p.attributes.poolId === Number(pool.id)
    );
    // eslint-disable-next-line no-param-reassign
    vault.pools[index].poolRiskScore = cmsPool?.attributes.poolRiskScore ?? 0.2;
  });

  // eslint-disable-next-line consistent-return
  return vault;
};

export const Vault: React.FC = () => {
  // TODO(jason): Read network from path and use that to fetch contract data to allow -
  // for users to directly load to vault page. Right now, it uses the default network and this causes issues IF -
  // user tries to directly load into a vault on a different network
  const { vaultId, network } =
    useParams<{ vaultId: string; network: string }>();

  const steps = [
    {
      selector: '#order-box',
      content: 'Enter the amount that you want to underwrite.',
    },
  ];

  const [enableSteps, setEnableSteps] = useState<boolean>(false);

  const vaultAddress = useMemo(
    () => NetworkAddress.fromStrings(network, vaultId),
    [network, vaultId]
  );
  const { vault = undefined } = useVault(vaultAddress) ?? {};
  const poolsCMSData = useCMSPools(vaultAddress.address);

  const filledVault: VaultView | undefined = useMemo(
    () => mergeVaultAndCMS(vault, poolsCMSData),
    [vault, poolsCMSData]
  );

  const { wallet } = useWallet();
  const { currentNetwork } = useNetwork();
  const vaultLoaded = vault && vaultAddress;
  const walletConnected = vaultLoaded && wallet;
  const networkMismatch =
    vaultLoaded &&
    walletConnected &&
    vaultAddress.network.id !== currentNetwork.id;

  const onExit = () => {
    setEnableSteps(false);
  };

  return (
    <VaultContext.Provider value={vaultAddress}>
      <Tour steps={steps} isOpen={enableSteps} onRequestClose={onExit} />
      <Banner type="underwrite" vaultInfo={filledVault} />
      <Section>
        <SubSection>
          <InfoDesc
            type="vault"
            description={VaultDetails.DESC}
            risksDesc={filledVault?.underwriterRisks}
          />
          <PoolBreakDown
            pools={filledVault?.pools}
            vaultID={vaultAddress.address.toString()}
          />
        </SubSection>
        <OrderBox type="vault" mismatchNetwork={networkMismatch} />
      </Section>
    </VaultContext.Provider>
  );
};
