import React from 'react';
import styled from 'styled-components';
import { Button, Spinner } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useWallet } from 'src/viewmodels/wallet';
import { ButtonConnect } from 'src/components/Header/Connect/ButtonConnect';
import { ClaimAction, ClaimStage } from 'src/viewmodels/pool';

const StatusButton = styled(Button)<{ isDisabled: boolean }>`
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: var(--h2-font-size) !important;
  text-align: center !important;
  font-weight: 600 !important;
  width: 100% !important;
  padding: 2rem 0rem !important;
  border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  color: ${(props) =>
    props.isDisabled
      ? `var(--text-secondary-gray) !important`
      : `var(--text-primary-white) !important`};
  background-color: ${(props) =>
    props.isDisabled
      ? `var(--background-blue) !important`
      : `var(--components-primary-blue) !important`};

  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? `var(--background-blue) !important`
        : `var(--components-primary-blue) !important`};
    filter: ${(props) =>
      props.isDisabled ? `brightness(100%)` : `brightness(80%)`};
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const FlexBox = styled.div`
  display: flex;
  column-gap: 12px;

  @media only screen and (max-width: 340px) {
    flex-direction: column;
    column-gap: 0px;
    row-gap: 12px;
    align-items: center;
  }
`;

interface Props {
  actionType?: string;
  onClick?: () => void;
  isActionInProgress?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  claimAction?: ClaimAction;
  currentClaimStage?: ClaimStage;
  zeroAmount?: boolean;
}

export const OrderButton: React.FC<Props> = ({
  actionType = '',
  onClick,
  isActionInProgress,
  disabled,
  errorMessage,
  claimAction,
  currentClaimStage,
  zeroAmount,
}) => {
  const intl = useIntl();
  const { wallet } = useWallet();
  const isDisabled = false; // disabled if wallet not connected or not enough balance
  let submitText = intl.formatMessage({
    id: 'submit-orderbox-amount-text',
    defaultMessage: 'Enter Amount',
  });

  if (!isDisabled) {
    if (actionType === 'Protect') {
      submitText = intl.formatMessage({
        id: 'submit-orderbox-protect-text',
        defaultMessage: 'PURCHASE PROTECTION',
      });
    } else if (actionType === 'Claim') {
      submitText = intl.formatMessage({
        id: 'submit-orderbox-claim-text',
        defaultMessage:
          currentClaimStage === ClaimStage.TerminateClaim
            ? 'FINISH CLAIM'
            : 'FILE CLAIM',
      });
    } else if (actionType === 'Deposit') {
      submitText = intl.formatMessage({
        id: 'submit-orderbox-deposit-text',
        defaultMessage: 'DEPOSIT',
      });
    } else {
      submitText = intl.formatMessage({
        id: 'submit-orderbox-withdraw-text',
        defaultMessage: 'WITHDRAW',
      });
    }
  }
  if (errorMessage) {
    submitText = errorMessage;
  }

  if (zeroAmount) {
    submitText = 'ENTER AMOUNT';
  }

  if (!wallet) {
    return <ButtonConnect type="orderbox" />;
  }

  if (claimAction) {
    return (
      <StatusButton isDisabled>
        <text>
          {
            {
              [ClaimAction.ApproveTokenTransfer]: 'APPROVING TOKEN TRANSFER...',
              [ClaimAction.StartClaim]: 'STARTING CLAIM...',
              [ClaimAction.CancelClaim]: 'CANCELING CLAIM...',
              [ClaimAction.FinishClaim]: 'FINISHING CLAIM...',
            }[claimAction]
          }
        </text>
      </StatusButton>
    );
  }

  return (
    <StatusButton
      isDisabled={isDisabled || isActionInProgress || disabled || zeroAmount}
      onClick={onClick}
    >
      {isActionInProgress ? (
        <FlexBox>
          <Spinner /> <text>Processing transaction</text>
        </FlexBox>
      ) : (
        submitText
      )}
    </StatusButton>
  );
};

OrderButton.defaultProps = {
  actionType: '',
  onClick: () => {},
  isActionInProgress: false,
  disabled: false,
  errorMessage: '',
  claimAction: undefined,
  currentClaimStage: undefined,
  zeroAmount: false,
};
