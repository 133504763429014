import React from 'react';
import styled from 'styled-components';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { Protect } from 'src/components/OrderBox/Protect';
import { Claim } from 'src/components/OrderBox/Claim';
import { Deposit } from 'src/components/OrderBox/Deposit';
import { Withdraw } from 'src/components/OrderBox/Withdraw';
import { useSimulator } from 'src/viewmodels/simulator';

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--components-primary-blue);
  border-radius: var(--card-radius);
  max-width: 27rem;
  width: 46%;
  margin-left: auto;
  height: 100%;

  @media only screen and (max-width: 1149px) {
    margin: 1rem auto 2rem 0;
    width: 100%;
  }
`;

const CustomTab = styled(Tab)`
  color: var(--text-primary-white);
  text-align: center;
  font-weight: 600;
  width: 100%;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:disabled {
    cursor: default;
    filter: brightness(100%);
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

const Line = styled.hr`
  color: var(--components-primary-blue);
`;

interface Props {
  type: string;
  mismatchNetwork: boolean | null | undefined;
}

export const OrderBox: React.FC<Props> = ({ type, mismatchNetwork }) => {
  const intl = useIntl();
  const { simulatorStage } = useSimulator();
  const protectText = intl.formatMessage({
    id: 'protect-orderbox-text',
    defaultMessage: 'PROTECT',
  });
  const claimText = intl.formatMessage({
    id: 'claim-orderbox-text',
    defaultMessage: 'FILE CLAIM',
  });
  const withdrawText = intl.formatMessage({
    id: 'withdraw-orderbox-text',
    defaultMessage: 'WITHDRAW',
  });
  const depositText = intl.formatMessage({
    id: 'deposit-orderbox-text',
    defaultMessage: 'DEPOSIT',
  });

  return (
    <Container id="order-box">
      <Tabs
        variant="unstyled"
        defaultIndex={simulatorStage === 4 && type === 'pool' ? 1 : 0}
      >
        <TabList>
          {type === 'pool' ? (
            <>
              <CustomTab
                fontSize="var(--h2-font-size)"
                padding="0.75rem 0rem"
                isDisabled={simulatorStage === 4}
                borderRadius="var(--card-radius) 0rem 0rem 0rem !important"
                _selected={{
                  bg: 'var(--components-primary-blue)',
                }}
              >
                {protectText}
              </CustomTab>
              <CustomTab
                fontSize="var(--h2-font-size)"
                padding="0.75rem 0rem"
                isDisabled={simulatorStage === 2}
                borderRadius="0rem var(--card-radius) 0rem 0rem !important"
                _selected={{
                  bg: 'var(--components-primary-blue)',
                }}
              >
                {claimText}
              </CustomTab>
            </>
          ) : (
            <>
              <CustomTab
                id="deposit-tab-head"
                fontSize="var(--h2-font-size)"
                padding="0.75rem 0rem"
                borderRadius="var(--card-radius) 0rem 0rem 0rem !important"
                _selected={{
                  bg: 'var(--components-primary-blue)',
                }}
              >
                {depositText}
              </CustomTab>
              <CustomTab
                id="withdraw-tab-head"
                fontSize="var(--h2-font-size)"
                padding="0.75rem 0rem"
                isDisabled={simulatorStage === 1}
                borderRadius="0rem var(--card-radius) 0rem 0rem !important"
                _selected={{
                  bg: 'var(--components-primary-blue)',
                }}
              >
                {withdrawText}
              </CustomTab>
            </>
          )}
        </TabList>
        <Line />
        <TabPanels>
          <TabPanel>
            {type === 'pool' ? (
              <Protect networkMismatch={mismatchNetwork} />
            ) : (
              <Deposit networkMismatch={mismatchNetwork} />
            )}
          </TabPanel>
          <TabPanel>
            {type === 'pool' ? (
              <Claim networkMismatch={mismatchNetwork} />
            ) : (
              <Withdraw networkMismatch={mismatchNetwork} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

OrderBox.defaultProps = {};
