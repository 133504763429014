import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { VaultPoolView } from 'src/viewmodels/vault';
import { Optional } from 'src/types/Optional';
import { useToken } from 'src/viewmodels/token';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { getTokenIcon } from 'src/utils/token-icon';
import { useTable } from 'react-table';
import emptyTokenIcon from 'src/assets/empty-token.png';

interface Props {
  pools: Optional<VaultPoolView[]>;
  vaultID: string;
}

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
  width: 40rem !important;
  margin-top: -1rem;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }

  @media only screen and (max-width: 1109px) {
    margin-top: 0rem;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-blue);
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
`;

const Image = styled.img`
  width: 25px;
  margin-right: 10px;
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Token: React.FC<{ value: NetworkAddress }> = ({ value }) => {
  const protectedToken = useToken(value);
  const tokenLogo = getTokenIcon(value);
  const elementText = 'Element Principal Token yvCurve-alUSD-29APR22';
  const curveElementText = 'Curve FRAX Pool yVault';
  let protectedTokenName = protectedToken?.name;
  if (protectedTokenName === curveElementText) {
    protectedTokenName = 'Yearn Frax Curve 3pool';
  } else if (protectedToken?.name === elementText) {
    protectedTokenName = protectedToken.name.substring(0, 24);
  }

  return (
    <HFlexbox>
      <Image
        src={tokenLogo}
        alt=""
        onError={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e.target as any).src = emptyTokenIcon;
        }}
      />
      <TokenSymbol>{protectedTokenName}</TokenSymbol>
    </HFlexbox>
  );
};

export const PoolBreakDown: React.FC<Props> = ({ pools, vaultID }) => {
  const history = useHistory();
  const data = useMemo(
    () =>
      pools
        ? pools.map((pool: VaultPoolView) => ({
            protectedToken: pool.protectedTokenAddress,
            riskScore: pool.poolRiskScore,
            hacked: pool.hacked ? 'Yes' : 'No',
            poolID: pool.id,
            networkId: pool.protectedTokenAddress.network.id,
            rawState: pool.rawState,
          }))
        : [],
    [pools]
  );

  const columns = useMemo(
    () =>
      pools
        ? [
            {
              Header: (
                <FormattedMessage
                  id="protected-token"
                  defaultMessage="PROTECTED TOKEN"
                />
              ),
              accessor: 'protectedToken',
              Cell: Token,
            },
            {
              Header: (
                <FormattedMessage
                  id="pool-risk-score"
                  defaultMessage="POOL RISK SCORE"
                />
              ),
              accessor: 'riskScore',
            },
            {
              Header: <FormattedMessage id="hacked" defaultMessage="HACKED" />,
              accessor: 'hacked',
            },
          ]
        : [],
    [pools]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns,
      data,
    });

  return (
    <Box overflowX="auto">
      <STable variant="unstyled" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                if (index === 1) {
                  return (
                    <>
                      <STh
                        {...column.getHeaderProps()}
                        textAlign="right"
                        w="33%"
                      >
                        {column.render('Header')}
                      </STh>
                      <STh />
                    </>
                  );
                }
                return (
                  <STh {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </STh>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <STr
                {...row.getRowProps()}
                onClick={() =>
                  history.push(
                    `/pool/${row.original.networkId}/${vaultID}/${row.original.poolID}`
                  )
                }
              >
                {row.cells.map((cell, index) => {
                  if (index === 1) {
                    return (
                      <>
                        <STd {...cell.getCellProps()} textAlign="right">
                          {cell.render('Cell')}
                        </STd>
                        <STd />
                      </>
                    );
                  }
                  return (
                    <STd {...cell.getCellProps()}>{cell.render('Cell')}</STd>
                  );
                })}
              </STr>
            );
          })}
        </Tbody>
      </STable>
    </Box>
  );
};
