import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { walletState } from 'src/state/wallet';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from 'src/state/sagas';
import { subgraphState } from 'src/state/subgraph';
import { contractsState } from 'src/state/contracts';
import { simulatorState } from 'src/state/simulator';
import { networkState } from './network';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    network: networkState.reducer,
    wallet: walletState.reducer,
    subgraph: subgraphState.reducer,
    contracts: contractsState.reducer,
    simulator: simulatorState.reducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
