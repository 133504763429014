import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ToolTip } from 'src/components/ToolTip/ToolTip';
import { VaultDetails } from 'src/constants/constants';
import { TokenAmountInput } from 'src/components/OrderBox/TokenAmountInput';
import { OrderButton } from 'src/components/OrderBox/OrderButton';
import { VaultContext } from 'src/contexts/VaultContext';
import { useVault } from 'src/viewmodels/vault';
import { Box } from '@chakra-ui/react';
import { calculateRewards } from 'src/math/rewards';
import { TokenAmount } from 'src/models/TokenAmount';
import { minBigNum } from 'src/math/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-blue);
  border-radius: 8px;
  margin-top: 0.5rem;
  margin-bottom: var(--section-bm);

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const ExpiryText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p2-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-primary-white);
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;
  text-overflow: ellipsis;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

interface Props {
  networkMismatch: boolean | null | undefined;
}

export const Deposit: React.FC<Props> = ({ networkMismatch }) => {
  const intl = useIntl();
  const rewardLabel = intl.formatMessage({
    id: 'deposit-return-text',
    defaultMessage: 'AVAILABLE REWARDS',
  });

  const [tokenAmount, setTokenAmount] = useState<TokenAmount>(
    TokenAmount.default()
  );

  const vaultAddress = useContext(VaultContext);
  const {
    vault = undefined,
    deposit = undefined,
    isDepositing = false,
    underwritingToken = undefined,
  } = useVault(vaultAddress) ?? {};
  const vaultHasRewards = !!vault?.rewardToken;
  const rewardAmount = calculateRewards(vault);

  let maxAmount = vault
    ? TokenAmount.from(
        vault.underwritingToken,
        vault.underwritingToken.balance || 0
      )
    : TokenAmount.default();
  if (underwritingToken) {
    const underwritingAmountBalance = TokenAmount.from(
      underwritingToken,
      underwritingToken.balance || 0
    );
    maxAmount = minBigNum(maxAmount, underwritingAmountBalance);
  }

  const onAmountChange = (amount: TokenAmount) => {
    setTokenAmount(amount);
  };

  const isInvalidAmount = tokenAmount.toBigNumber().gt(maxAmount.toBigNumber());

  const currDate = new Date();
  const vaultNotExpired = vault ? currDate <= vault.expirationDate : false;

  return (
    <>
      {networkMismatch ? (
        <>
          <AmountBox style={{ marginBottom: 0 }}>
            <Text style={{ margin: 'auto' }}>
              Please switch to {vault?.network.network.displayName}
            </Text>
          </AmountBox>
        </>
      ) : (
        <Container>
          {vaultNotExpired ? (
            <>
              <TokenAmountInput
                actionType="Deposit"
                token={vault?.underwritingToken}
                amount={tokenAmount}
                maxAmount={maxAmount}
                onChange={onAmountChange}
              />
              {vaultHasRewards ? (
                <AmountBox>
                  <Text>
                    {rewardLabel}
                    <ToolTip tooltipText={VaultDetails.REW} />
                  </Text>
                  <AmountText>
                    {rewardAmount.toFormattedString(0, 4, true)}{' '}
                    {vault?.rewardToken?.symbol}
                  </AmountText>
                </AmountBox>
              ) : (
                <Box h="60px" />
              )}
              <OrderButton
                actionType="Deposit"
                isActionInProgress={isDepositing}
                disabled={isInvalidAmount}
                onClick={() => deposit?.(tokenAmount)}
                zeroAmount={tokenAmount.toBigNumber().eq(0)}
              />
            </>
          ) : (
            <>
              <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
                <ExpiryText>
                  The vault has expired. Deposit is unavailable at this time.
                </ExpiryText>
              </AmountBox>
              <OrderButton actionType="Deposit" disabled />
            </>
          )}
        </Container>
      )}
    </>
  );
};
