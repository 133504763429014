import { createAction } from '@reduxjs/toolkit';
import { WalletType } from 'src/config/wallets';
import { Network } from 'src/models/Network';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { VaultID, PositionID, PoolID } from 'src/state/contracts';
import { SubgraphQuery } from 'src/state/subgraph';

export const connectWeb3Wallet = createAction<WalletType>(
  'wallet/connect-web3-wallet'
);
export const disconnectWallet = createAction('wallet/disconnect-wallet');
// TODO(jason): Fire this even when user's wallet isn't connected by using default provider
export const newBlockAction = createAction<number>('evm/new-block');
export const querySubgraph =
  createAction<{ network: Network; query: SubgraphQuery }>('subgraph/query');

export const startVaultSync = createAction<{
  vaultAddress: NetworkAddress;
}>('contracts/load/start/vault');
export const endVaultSync = createAction<VaultID>('contracts/load/end/vault');

export const startPoolSync = createAction<{
  vaultAddress: NetworkAddress;
  poolId: PoolID;
}>('contracts/load/start/pool');
export const endPoolSync = createAction<{ vaultId: VaultID; poolId: PoolID }>(
  'contracts/load/end/pool'
);

export const startPositionSync = createAction<{
  vaultAddress: NetworkAddress;
  positionId: PositionID;
}>('contracts/load/start/position');
export const endPositionSync = createAction<{
  vaultId: VaultID;
  positionId: PositionID;
}>('contracts/load/end/position');

export const startTokenSync = createAction<{
  tokenAddress: NetworkAddress;
}>('contracts/load/start/token');
export const endTokenSync = createAction<{
  tokenAddress: NetworkAddress;
}>('contracts/load/end/token');

export const loadUserVaultUnderwritingPositions = createAction<{
  vaultAddress: NetworkAddress;
}>('subgraph/load/start/vault/position/underwriting');

export const loadUserVaultPolicyholderPositions = createAction<{
  vaultAddress: NetworkAddress;
}>('subgraph/load/start/vault/position/policyholder');
