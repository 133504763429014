import { isFunction } from 'src/utils/functions';
import { Optional } from './Optional';

export type ValueOrGetter<T, K = unknown> = T | ((config: K) => T);

export function resolveValueOrGetter<T, K = unknown>(
  x: ValueOrGetter<T>,
  config: Optional<K> = undefined
): T {
  return isFunction(x) ? x(config) : x;
}
