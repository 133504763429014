import { useEffect, useState } from 'react';
import { Vault } from 'src/state/contracts';
import { Optional } from 'src/types/Optional';

export function useYield(vault: Optional<Vault>): Optional<number> {
  const [underwriterYield, setUnderwriterYield] = useState(0);

  useEffect(() => {
    if (!vault) return;
    const { allocationVector, premiums } = vault.state;
    const { start, expiration } = vault.config;
    const aZero = allocationVector[0];

    if (aZero.isZero() || premiums.isZero()) {
      return;
    }

    const yieldForTerm = aZero.sub(premiums).mul(100).div(aZero);
    const SECONDS_PER_YEAR = 31536000;
    const yearMultiple = SECONDS_PER_YEAR / (expiration - start);

    setUnderwriterYield((yieldForTerm.toNumber() / 10000) * yearMultiple);
  }, [vault]);

  return underwriterYield;
}
