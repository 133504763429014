import { BigNumber } from 'ethers';
import { SUPPORTED_NETWORKS, UNSUPPORTED_NETWORK } from 'src/config/network';
import { RHNetworkConfig } from 'src/config/types';
import { InvalidOrUnsupportedNetworkError } from 'src/errors';
import { Network, Network as RHNetwork, NetworkID } from 'src/models/Network';
import { Optional } from 'src/types/Optional';

const networkByNameCache: { [networkId: string]: RHNetwork } = {};
const networkByChainIdCache: { [chainId: string]: RHNetwork } = {};

const NAME_TO_ID_MAP: Record<string, NetworkID> = {
  mainnet: NetworkID.mainnet,
  ethereum: NetworkID.mainnet,
  ropsten: NetworkID.ropsten,
  testnet: NetworkID.ropsten,
  arbitrum: NetworkID.arbitrum,
  'arbitrum-one': NetworkID.arbitrum,
  'arbitrum-mainnet': NetworkID.arbitrum,
  'arbitrum-rinkeby': NetworkID.arbitrumRinkeby,
  'arbitrum-testnet': NetworkID.arbitrumRinkeby,
  optimism: NetworkID.optimism,
  'optimism-mainnet': NetworkID.optimism,
  'optimism-kovan': NetworkID.optimismKovan,
  'optimism-testnet': NetworkID.optimismKovan,
  polygon: NetworkID.polygon,
  'polygon-mainnet': NetworkID.polygon,
  matic: NetworkID.polygon,
  'matic-mainnet': NetworkID.polygon,
  mumbai: NetworkID.polygonMumbai,
  'polygon-mumbai': NetworkID.polygonMumbai,
  'polygon-testnet': NetworkID.polygonMumbai,
  'matic-testnet': NetworkID.polygonMumbai,
  'matic-mumbai': NetworkID.polygonMumbai,
  maticmum: NetworkID.polygonMumbai,
  avalanche: NetworkID.avalanche,
  'avalanche-mainnet': NetworkID.avalanche,
  fuji: NetworkID.avalancheFuji,
  'avalanche-fuji': NetworkID.avalancheFuji,
  'avalanche-testnet': NetworkID.avalancheFuji,
  fantom: NetworkID.fantom,
  'fantom-mainnet': NetworkID.fantom,
  'fantom-testnet': NetworkID.fantomTestnet,
  bsc: NetworkID.bsc,
  'bsc-mainnet': NetworkID.bsc,
  'bsc-testnet': NetworkID.bscTestnet,
  'binance-smart-chain': NetworkID.bsc,
  'binance-smart-chain-mainnet': NetworkID.bsc,
  'binance-smart-chain-testnet': NetworkID.bscTestnet,
};

export function resolveNetworkFromName(name: string): RHNetwork {
  if (networkByNameCache[name]) {
    return networkByNameCache[name];
  }

  const networkId = NAME_TO_ID_MAP[name] || name;

  const matchingRHNetwork = SUPPORTED_NETWORKS.find(
    (supportedNetwork) => supportedNetwork.id === networkId
  );

  if (!matchingRHNetwork) {
    throw InvalidOrUnsupportedNetworkError.fromName(name);
  }

  networkByNameCache[name] = matchingRHNetwork;

  return matchingRHNetwork;
}

export function resolveNetwork(chainId: string): RHNetwork<RHNetworkConfig> {
  if (networkByChainIdCache[chainId]) {
    return networkByChainIdCache[chainId];
  }

  const matchingRHNetwork = SUPPORTED_NETWORKS.find((supportedNetwork) =>
    BigNumber.from(supportedNetwork.chainId).eq(BigNumber.from(chainId))
  );

  if (!matchingRHNetwork) {
    return UNSUPPORTED_NETWORK;
    // throw InvalidOrUnsupportedNetworkError.fromChainId(chainId);
  }

  networkByChainIdCache[chainId] = matchingRHNetwork;

  return matchingRHNetwork;
}

export function getBlockExplorerUrl(
  network: Optional<Network>
): Optional<string> {
  return network?.config?.walletConfig?.blockExplorerUrls?.[0];
}
