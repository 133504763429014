import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { ToolTip } from 'src/components/ToolTip/ToolTip';
import { VaultDetails } from 'src/constants/constants';
import { TokenAmount } from 'src/models/TokenAmount';
import { RiskAversion } from 'src/viewmodels/vault';
import { Optional } from 'src/types/Optional';
import styled from 'styled-components';
import moment from 'moment';
import { TokenWithAddress } from 'src/types/contracts/Token';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 5rem;
  grid-row-gap: 2rem;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 0rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

interface Props {
  underwritingToken: Optional<TokenWithAddress>;
  vaultCapacity: Optional<TokenAmount>;
  riskAversion: Optional<RiskAversion>;
  network: Optional<string>;
  expirationDate: Optional<Date>;
}

export const VaultDetailsGrid: React.FC<Props> = ({
  underwritingToken,
  vaultCapacity,
  riskAversion,
  network,
  expirationDate,
}) => {
  const intl = useIntl();
  const vaultCapText = intl.formatMessage({
    id: 'vault-cap-text',
    defaultMessage: 'VAULT CAPACITY',
  });
  const riskAversionText = intl.formatMessage({
    id: 'risk-aversion-text',
    defaultMessage: 'RISK AVERSION',
  });

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          <FormattedMessage
            id="underwrt-asset-text"
            defaultMessage="UNDERWRITING ASSET"
          />
          <ToolTip tooltipText={VaultDetails.UWA} />
        </Text>
        <SmallText>{underwritingToken?.name}</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {vaultCapText}
          <ToolTip tooltipText={VaultDetails.VC} />
        </Text>
        <SmallText>
          {vaultCapacity?.toSignifcantDigits(3)} {underwritingToken?.symbol}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {riskAversionText}
          <ToolTip tooltipText={VaultDetails.RA} />
        </Text>
        <SmallText>{riskAversion}</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          <FormattedMessage id="network" defaultMessage="NETWORK" />
          <ToolTip tooltipText={VaultDetails.NET} />
        </Text>
        <SmallText>{network}</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          <FormattedMessage
            id="exp-date-text"
            defaultMessage="EXPIRATION DATE"
          />
          <ToolTip tooltipText={VaultDetails.EXP} />
        </Text>
        <SmallText>
          {moment(expirationDate?.toUTCString()).format('MMM. D, YYYY')}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
