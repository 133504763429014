import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, Stack } from '@chakra-ui/react';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';

const Image = styled.img`
  width: 1.75rem;

  @media only screen and (max-width: 499px) {
    width: 1.5rem;
  }
`;

export const Logo: React.FC = () => {
  return (
    <Link to="/">
      <Stack direction="row" align="center" _hover={{ cursor: 'pointer' }}>
        <Image src={logo} alt="logo" />
        <Heading
          as="h1"
          size="lg"
          textColor="white"
          fontFamily="Poppins"
          fontWeight="600"
          className="header-text"
        >
          Risk Harbor | Core
        </Heading>
      </Stack>
    </Link>
  );
};
