import React from 'react';
import { useIntl } from 'react-intl';
import { ToolTip } from 'src/components/ToolTip/ToolTip';
import { PoolDetails } from 'src/constants/constants';
import { Optional } from 'src/types/Optional';
import styled from 'styled-components';
import moment from 'moment';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ethers } from 'ethers';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-column-gap: 2rem;
  grid-row-gap: 2.5rem;

  align-content: space-evenly;
  width: 100%;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

interface Props {
  protectedToken: Optional<string>;
  underwritingToken: Optional<string>;
  expirationDate: Optional<Date>;
}

export const PoolDetailsGrid: React.FC<Props> = ({
  protectedToken,
  underwritingToken,
  expirationDate,
}) => {
  const intl = useIntl();
  const [session] = useLocalStorage('session', '{}');

  const protectedTokenText = intl.formatMessage({
    id: 'protected-token-text',
    defaultMessage: 'PROTECTED TOKEN',
  });
  const underlyingTokenText = intl.formatMessage({
    id: 'underlying-token-text',
    defaultMessage: 'UNDERLYING TOKEN',
  });
  const underwrtTokenText = intl.formatMessage({
    id: 'underwrt-token-text',
    defaultMessage: 'UNDERWRITING TOKEN',
  });
  const defaultRtText = intl.formatMessage({
    id: 'default-rt-text',
    defaultMessage: 'DEFAULT RATIO',
  });
  const payoutRtText = intl.formatMessage({
    id: 'payout-rt-text',
    defaultMessage: 'PAYOUT RATIO',
  });
  const expDateText = intl.formatMessage({
    id: 'exp-date-text',
    defaultMessage: 'EXPIRATION DATE',
  });
  const getDefaultRatio = () => {
    try {
      const sessionData = JSON.parse(session);
      const ratio = ethers.utils.formatUnits(sessionData.defaultRatio, 'ether');
      return ratio;
    } catch (Err) {
      return null;
    }
  };
  const getPayoutRatio = () => {
    try {
      const sessionData = JSON.parse(session);
      const ratio = ethers.utils.formatUnits(sessionData.payoutRatio, 'ether');
      return ratio;
    } catch (Err) {
      return null;
    }
  };

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          {protectedTokenText}
          <ToolTip tooltipText={PoolDetails.PT} />
        </Text>
        <SmallText>
          {protectedToken === 'ePyvCurve-alUSD-29APR22'
            ? protectedToken.substring(0, 15)
            : protectedToken}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underlyingTokenText}
          <ToolTip tooltipText={PoolDetails.ULT} />
        </Text>
        <SmallText>{underwritingToken}</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underwrtTokenText}
          <ToolTip tooltipText={PoolDetails.UWT} />
        </Text>
        <SmallText>{underwritingToken}</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {defaultRtText}
          <ToolTip tooltipText={PoolDetails.DR} />
        </Text>
        <SmallText>1 Protected : {getDefaultRatio()} Underlying</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {payoutRtText}
          <ToolTip tooltipText={PoolDetails.PR} />
        </Text>
        <SmallText>1 Protected : {getPayoutRatio()} underwriting</SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {expDateText}
          <ToolTip tooltipText={PoolDetails.EXP} />
        </Text>
        <SmallText>
          {moment(expirationDate?.toUTCString()).format('MMM. D, YYYY')}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
