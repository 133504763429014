/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Optional } from 'src/types/Optional';

export function useDispatchableCallback<
  AnyActionCreator extends (...args: any[]) => AnyAction
>(
  actionCreator: Optional<AnyActionCreator>
): (...args: Parameters<AnyActionCreator>) => void {
  const dispatch = useDispatch();

  function wrappedCallback(...args: Parameters<AnyActionCreator>) {
    if (!actionCreator) return undefined;

    return dispatch(actionCreator(...args));
  }

  return wrappedCallback;
}
