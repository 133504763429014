/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
// TODO(jason): Consider moving this into the redux flow somehow (if needed)

import { ContractTransaction, Signer } from 'ethers';
import { useCallback, useMemo } from 'react';
import { NetworkAddress } from 'src/models/NetworkAddress';
import { TokenAmount } from 'src/models/TokenAmount';
import { ERC20__factory } from 'src/types/contracts/factories/ERC20__factory';
import { TokenWithAddress } from 'src/types/contracts/Token';
import { Optional } from 'src/types/Optional';
import { useWallet } from 'src/viewmodels/wallet';

export function useERC20Functions(token: Optional<TokenWithAddress>): Optional<{
  getAllowance: (spender: NetworkAddress) => Promise<TokenAmount>;
  approve: (
    spender: NetworkAddress,
    amount: TokenAmount
  ) => Promise<ContractTransaction>;
}> {
  const tokenAddress = token?.address?.address?.toString?.();
  const tokenNetwork = token?.address?.network;

  const { wallet } = useWallet();

  const tokenContract = useMemo(
    () =>
      tokenAddress && tokenNetwork && wallet
        ? ERC20__factory.connect(
            tokenAddress,
            wallet.provider.getSigner() as Signer
          )
        : undefined,
    [tokenAddress, tokenNetwork, wallet]
  );

  const getAllowance = useCallback(
    async (spender: NetworkAddress) => {
      const allowance = await tokenContract!.allowance(
        wallet!.account.toString(),
        spender.address.toString()
      );

      return TokenAmount.from(token!, allowance);
    },
    [token, tokenContract, wallet]
  );

  const approve = useCallback(
    async (spender: NetworkAddress, amount: TokenAmount) => {
      return tokenContract!.approve(
        spender.address.toString(),
        amount.toBigNumber()
      );
    },
    [tokenContract]
  );

  return tokenContract && wallet && token
    ? {
        getAllowance,
        approve,
      }
    : undefined;
}
