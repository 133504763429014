import { keccak256, toUtf8Bytes } from 'ethers/lib/utils';

export enum TabStatus {
  PROTECT = 'protect',
  CLAIM = 'claim',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

export enum MenuStatus {
  BUY = 'buy',
  PROVIDE = 'provide',
  PORTFOLIO = 'portfolio',
}

export enum PoolDetails {
  PT = 'The token that is being protected.',
  ULT = 'The token that the claim token redeems into.',
  UWT = 'The token that the protection is underwritten in.',
  DR = 'The ratio for which a default event is considered.',
  PR = 'The ratio for which the user will be paid per each token covered.',
  EXP = 'The date when the pool expires.',
}

export enum VaultDetails {
  UWA = 'The asset that underwrites the entire vault.',
  VC = 'The capacity of the vault.',
  RA = 'The willingness of a vault to underwrite additional risk.',
  NET = 'The blockchain on which the vault is hosted.',
  LVG = 'The ratio of underwriting capital to outstanding protection liability.',
  EXP = 'The date when the vault expires.',
  REW = 'Staking rewards per term (does not include premiums) split among all depositors.',
  DESC = 'Enter the amount that you want to underwrite.',
}

export enum ProtectionDetails {
  PA = 'The amount protected.',
  NET = 'The blockchain on which the pool is hosted.',
  STATUS = 'The market status of the pool.',
  EXP = 'The date when the pool expires.',
  DESCP = 'First, enter the amount that you want to protect. Next, choose your preferred slippage tolerance' +
    ', which is the highest percentage difference that you’ll tolerate between the quoted' +
    ' price and the actual price when the transaction processes. The “Payout”' +
    ' value demonstrates how much you would get paid out if a default event affects the pool. ' +
    'The “Premium” value is the price of protection.',
  DESCC = 'File a claim to receive a payout.',
}

export enum InvestingDetails {
  UND = 'The underwriting asset.',
  SHARES = 'The amount of shares you have within the deposited vault.',
  NET = 'The blockchain on which the vault is hosted.',
  NAME = 'The name of the vault.',
  STATUS = 'The market status of the vault.',
  EXP = 'The date when the vault expires.',
}

export enum PoolBreakdownDetails {
  PT = 'The token that is being protected.',
  RISK = 'The risk assessment score of the pool.',
  STATUS = 'The status of the pool.',
}

export const status = {
  0: 'Open',
  1: 'Paused',
  2: 'Hacked',
  3: 'Closed',
};

export const PERMIT_TYPEHASH = keccak256(
  toUtf8Bytes(
    'Permit(address owner,address spender,uint256 value,uint256 nonce,uint256 deadline)'
  )
);

export const GTM_ID = 'GTM-PP3X2BG';

export enum GOOGLE_EVENTS {
  NETWORK_CHANGE = 'network_change',
  WALLET_CONNECT = 'wallet_connect',
  BUY_PROTECTION_INITIATED = 'buy_protection_initiated',
  BUY_PROTECTION_SUCCESS = 'buy_protection_success',
  BUY_PROTECTION_FAILED = 'buy_protection_failed',
  PROVIDE_PROTECTION_STARTED = 'provide_protection_started',
  PROVIDE_PROTECTION_SUCCESS = 'provide_protection_success',
  PROVIDE_PROTECTION_FAILED = 'provide_protection_failed',
}

export enum SIMULATION_FORM {
  PAYOUT_RATIO = 'This is the ratio for which the user will be paid per each covered token.',
  DEFAULT_RATION = 'This is the ratio for which a default event is considered.',
}
