import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { useSimulator } from 'src/viewmodels/simulator';
import UseToaster from 'src/hooks/useToast';

export const HackPool: React.FC = () => {
  const { toggleHack } = useSimulator();
  const { vaultId } = useParams<{ vaultId: string }>();
  const toast = UseToaster();
  const history = useHistory();

  const [isUpdatingFlag, setIsUpdatingFlag] = useState<boolean>(false);
  const updateHackFlag = async () => {
    setIsUpdatingFlag(true);
    const hash = await toggleHack();
    setIsUpdatingFlag(false);
    if (hash) {
      toast.success({
        title: `Hacked!`,
        message: `You can now claim!`,
      });
      history.push(`/pool/maticmum/${vaultId}/1`);
    }
  };
  return (
    <Box
      sx={{
        background: 'rgba(255, 255, 255, 0.03)',
        boxShadow: '0px 0px 50px 12px rgba(17, 17, 17, 0.15)',
        borderRadius: '15px',
        padding: '3rem',
        maxWidth: '58%',
        marginLeft: '21%',
      }}
    >
      <Button
        isLoading={isUpdatingFlag}
        loadingText="Hacking..."
        size="md"
        height="48px"
        width="100%"
        border="2px"
        borderColor="#2288FF"
        color="#FFF"
        bg="#2288FF"
        _hover={{ bg: '#2288FF' }}
        onClick={updateHackFlag}
      >
        Hack the pool
      </Button>
    </Box>
  );
};
