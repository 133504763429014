import { BigNumber } from 'ethers';
import { Vault } from 'src/state/contracts';

// MathLib.sol undrippedPremiums(...)
export function undrippedPremiums(vault: Vault) {
  const {
    config: { expiration, start },
    state: { premiums },
  } = vault;
  const vaultDuration = BigNumber.from(expiration - start);
  const avgStartTime = vault.state.averageStartTime;
  const currentTime = Math.floor(Date.now() / 1000);
  let timeRemaining = BigNumber.from(0);
  if (currentTime < expiration) {
    timeRemaining = BigNumber.from(expiration).sub(avgStartTime);
  }

  return vaultDuration.gt(0)
    ? premiums.mul(timeRemaining).div(vaultDuration)
    : BigNumber.from(0);
}

// MathLib.sol withdrawableAmount(...)
export function withdrawableAmount(
  positionShares: BigNumber,
  totalShares: BigNumber,
  tokensAvailable: BigNumber
): BigNumber {
  return totalShares.gt(0)
    ? positionShares.mul(tokensAvailable).div(totalShares)
    : tokensAvailable;
}

// MathLib.sol rewards(...)
export function rewards(
  positionShares: BigNumber,
  vaultTotalRewards: BigNumber,
  vaultTotalAmortizedShares: BigNumber,
  vaultStart: BigNumber,
  positionStart: BigNumber,
  vaultExpiration: BigNumber
): BigNumber {
  // WARNING: This only works because users can ONLY withdraw after expiry
  const amortizedShares = positionShares
    .mul(vaultExpiration.sub(positionStart))
    .div(vaultExpiration.sub(vaultStart));

  return vaultTotalAmortizedShares.gt(0)
    ? vaultTotalRewards.mul(amortizedShares).div(vaultTotalAmortizedShares)
    : BigNumber.from(0);
}
