import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SimulatorState {
  stage: number;
}

const initialState: SimulatorState = {
  stage: 0,
};

const simulatorSlice = createSlice({
  name: 'simulator',
  initialState,
  reducers: {
    updateSimulator: (
      state,
      action: PayloadAction<typeof initialState.stage>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.stage = action.payload;
    },
  },
});

export const simulatorState = {
  ...simulatorSlice.actions,
  reducer: simulatorSlice.reducer,
};
