export const triggerEvent = (eventName: string, source?: string) => {
  // @ts-ignore
  window.dataLayer.push(
    {
      event: eventName,
    },
    {
      source,
    }
  );
};
