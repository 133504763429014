/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { NetworkID } from 'src/models/Network';
import { Wallet } from 'src/models/Wallet';
import { PoolService, PoolSubgraphResult } from 'src/services/pool';
import { PortfolioService, UserPortfolio } from 'src/services/portfolio';
import { PositionService } from 'src/services/position';
import { VaultService, VaultSubgraphResult } from 'src/services/vault';
import {
  loadUserVaultPolicyholderPositions,
  loadUserVaultUnderwritingPositions,
} from 'src/state/actions';
import { PositionID, VaultID } from 'src/state/contracts';
import { subgraphState } from 'src/state/subgraph';
import { walletState } from 'src/state/wallet';
import { Optional } from 'src/types/Optional';
import { resolveNetworkFromName } from 'src/utils/network';

function* loadUserVaultUnderwritingPositionsWorker({
  payload: { vaultAddress },
}: ReturnType<typeof loadUserVaultUnderwritingPositions>) {
  const wallet: Optional<Wallet> = yield select((state) => state.wallet.value);

  if (!wallet) {
    return;
  }

  const positionService = PositionService.fromNetwork(vaultAddress.network);
  const positionIds: PositionID[] =
    yield positionService.findUnderwriterPositionsForUserInVault(
      wallet.account,
      vaultAddress.address
    );
  yield put(
    subgraphState.loadUnderwritingPositions({ vaultAddress, positionIds })
  );
}

function* loadUserVaultPolicyholderPositionsWorker({
  payload: { vaultAddress },
}: ReturnType<typeof loadUserVaultPolicyholderPositions>) {
  const wallet: Optional<Wallet> = yield select((state) => state.wallet.value);

  if (!wallet) {
    return;
  }

  const positionService = PositionService.fromNetwork(vaultAddress.network);
  const positionIds: PositionID[] =
    yield positionService.findPolicyholderPositionsForUserInVault(
      wallet.account,
      vaultAddress.address
    );
  yield put(
    subgraphState.loadPolicyholderPositions({ vaultAddress, positionIds })
  );
}

function* refreshWorker(networkId: NetworkID, loadedWallet?: Optional<Wallet>) {
  const network = resolveNetworkFromName(networkId);
  const wallet: Optional<Wallet> =
    loadedWallet || (yield select((state) => state.wallet.value));

  const vaultService = VaultService.fromNetwork(network);
  const vaults: VaultSubgraphResult[] = yield vaultService.getVaults();
  yield put(subgraphState.loadVaults({ networkId, vaults }));

  const poolService = PoolService.fromNetwork(network);
  const pools: PoolSubgraphResult[] = yield poolService.getPools();
  yield put(subgraphState.loadPools({ networkId, pools }));

  if (wallet) {
    const positionService = PositionService.fromNetwork(network);
    const vaultIds: VaultID[] = yield select((state) =>
      Object.keys(state.subgraph.underwritingPositions[networkId])
    );

    const vaultIdToUnderwritingPositionIdsMap: {
      [vaultId: VaultID]: PositionID[];
    } = yield positionService.findUnderwriterPositionsForUserInVaultBatch(
      wallet.account,
      vaultIds
    );

    const vaultIdToPolicyholderPositionIdsMap: {
      [vaultId: VaultID]: PositionID[];
    } = yield positionService.findPolicyholderPositionsForUserInVaultBatch(
      wallet.account,
      vaultIds
    );

    yield put(
      subgraphState.loadUnderwritingPositionsBatch({
        vaultIdToPositionIdsMap: vaultIdToUnderwritingPositionIdsMap,
        networkId,
      })
    );

    yield put(
      subgraphState.loadPolicyholderPositionsBatch({
        vaultIdToPositionIdsMap: vaultIdToPolicyholderPositionIdsMap,
        networkId,
      })
    );

    const portfolioService = PortfolioService.fromNetwork(network);
    const portfolio: UserPortfolio = yield portfolioService.getUserPortfolio(
      wallet.account
    );

    yield put(
      subgraphState.loadUserPortfolio({
        networkId,
        portfolio,
      })
    );
  }
}

function* refreshEvery(ms: number, networkId: NetworkID) {
  let loadedWallet: Optional<Wallet>;

  yield delay(200);

  while (true) {
    // If refresh fails, don't stop it (could be because of API key throttling)
    try {
      yield call(refreshWorker, networkId, loadedWallet);
    } catch (err) {
      // TODO(jason): Handle this better and bubble failure up IF NOT API key throttling
      // logger.error((err as Error).message, new UncaughtError(err as Error));
    }
    loadedWallet = undefined;

    const {
      walletConnected,
      walletAccountUpdated,
    }: {
      walletConnected: ReturnType<typeof walletState.loadWallet>;
      walletAccountUpdated: ReturnType<typeof walletState.updateAccount>;
    } = yield race({
      delay: delay(ms),
      walletConnected: take(walletState.loadWallet),
      walletAccountUpdated: take(walletState.updateAccount),
    });

    if (walletConnected) {
      loadedWallet = walletConnected.payload;
    }

    if (walletAccountUpdated) {
      const wallet: Optional<Wallet> = yield select(
        (state) => state.wallet.value
      );
      loadedWallet = wallet
        ? { ...wallet, account: walletAccountUpdated.payload }
        : undefined;
    }
  }
}

export function* subgraphSaga() {
  // yield all([takeEvery(querySubgraph.type, queryWorker)]);
  yield all([
    takeEvery(
      loadUserVaultUnderwritingPositions.type,
      loadUserVaultUnderwritingPositionsWorker
    ),
    takeEvery(
      loadUserVaultPolicyholderPositions.type,
      loadUserVaultPolicyholderPositionsWorker
    ),
    refreshEvery(10_000, NetworkID.mainnet),
    refreshEvery(10_000, NetworkID.ropsten),
    refreshEvery(10_000, NetworkID.arbitrum),
    refreshEvery(10_000, NetworkID.arbitrumRinkeby),
    refreshEvery(10_000, NetworkID.optimism),
    refreshEvery(10_000, NetworkID.optimismKovan),
    refreshEvery(10_000, NetworkID.polygon),
    refreshEvery(10_000, NetworkID.polygonMumbai),
    refreshEvery(10_000, NetworkID.avalanche),
    refreshEvery(10_000, NetworkID.avalancheFuji),
  ]);
}
