import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useWallet } from 'src/viewmodels/wallet';
import { WalletConnected } from 'src/components/Header/Connect/WalletConnected';
import { ButtonConnect } from './Connect/ButtonConnect';
import { Logo } from './Logo/Logo';
import { NetworkModal } from '../Modal/NetworkModal';

const FlexBox = styled.div`
  display: flex;

  @media only screen and (max-width: 960px) {
    margin-top: 2.5rem;
    flex-direction: column;
    row-gap: 1rem;
    width: 200px;
    margin: 1.5rem auto auto auto;
  }
`;

const Desktop = styled.div`
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: block;
  }
`;

const Content = styled(ModalContent)`
  max-width: 300px !important;
  background-color: var(--modal-blue);
`;

const CloseButton = styled(ModalCloseButton)`
  color: white;

  &:hover {
    filter: brightness(65%);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const Header: React.FC = () => {
  const { wallet } = useWallet();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth > 960) {
        onClose();
      }
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [onClose]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={2}
      py={10}
      px={0}
      bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
      color={['white', 'white', 'white', 'white']}
    >
      <Logo />
      <Desktop>
        {wallet ? (
          <>
            <WalletConnected />
          </>
        ) : (
          <FlexBox>
            <NetworkModal />
            <ButtonConnect />
          </FlexBox>
        )}
      </Desktop>
      <Mobile>
        <Box as="button" onClick={onOpen}>
          <HamburgerIcon w={6} h={6} />
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg="var(--chakra-colors-blackAlpha-700)" />
          <Content bg="var(--components-secondary-blue)">
            <CloseButton />
            <ModalBody marginBottom={5}>
              {wallet ? (
                <>
                  <WalletConnected />
                </>
              ) : (
                <FlexBox>
                  <NetworkModal />
                  <ButtonConnect />
                </FlexBox>
              )}
            </ModalBody>
          </Content>
        </Modal>
      </Mobile>
    </Flex>
  );
};
