import { utils } from 'ethers';
import { InvalidAddress } from 'src/errors';

export class Address {
  static Zero = new Address('0x0000000000000000000000000000000000000000');

  private readonly address: string;

  private constructor(addressString: string) {
    if (!utils.isAddress(addressString)) {
      throw new InvalidAddress(addressString);
    }

    this.address = utils.getAddress(addressString);
  }

  toLowercaseString(): string {
    return this.address.toLowerCase();
  }

  toString(): string {
    return this.address;
  }

  public static fromString(addressString: string): Address {
    return new Address(addressString);
  }

  equals(other: Address): boolean {
    return this.toLowercaseString() === other.toLowercaseString();
  }

  isZeroAddress(): boolean {
    return this.equals(Address.Zero);
  }
}
