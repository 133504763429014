import axios from 'axios';
import { useEffect, useState } from 'react';
import { Pool, Vault, AggregateCMSResponse } from 'src/config/cms';
import { Address } from 'src/models/Address';
import { Optional } from 'src/types/Optional';

const cmsUrl =
  process.env.REACT_APP_CMS_URL || 'https://cms.riskharbor.com/api';

axios.defaults.baseURL = cmsUrl;

// todo(drew) non urgent but refactor to graphql
export const getAllVaults: () => Promise<AggregateCMSResponse<Vault[]>> =
  async () => {
    const cmsVaultResponse = await axios.get('/vaults');
    return cmsVaultResponse.data;
  };

export const getAllPools: () => Promise<AggregateCMSResponse<Pool[]>> =
  async () => {
    const cmsPoolsResponse = await axios.get('/pools');
    return cmsPoolsResponse.data;
  };

export const useCMSVaults = () => {
  const [vaults, setVaults] = useState<Vault[]>();

  useEffect(() => {
    const fetchVaultData = async () => {
      const cmsVaultResponse = await getAllVaults();
      setVaults(cmsVaultResponse.data);
    };

    fetchVaultData();
  }, []);

  return vaults;
};

export const useCMSPools = (vaultAddress?: Optional<Address>) => {
  const [pools, setPools] = useState<Pool[]>();

  useEffect(() => {
    const fetchPoolData = async () => {
      const cmsPoolsResponse = (await getAllPools()).data;
      if (!vaultAddress) {
        setPools(cmsPoolsResponse);
        return;
      }
      const res = cmsPoolsResponse.filter(
        (thisPool) =>
          thisPool.attributes.vaultAddress === vaultAddress.toString()
      );
      if (res) setPools(res);
    };

    fetchPoolData();
  }, [vaultAddress]);

  return pools;
};

export const useCMSVault = (vaultAddress: Optional<Address>) => {
  const [vault, setVault] = useState<Vault | null>(null);
  useEffect(() => {
    const fetchVaultData = async () => {
      if (!vaultAddress) return;
      const cmsVaultsResponse = (await getAllVaults()).data;
      const res = cmsVaultsResponse.find(
        (thisVault) => thisVault.attributes.address === vaultAddress.toString()
      );
      if (res) setVault(res);
    };

    fetchVaultData();
  }, [vaultAddress]);

  return vault;
};

export const useCMSPool = (
  vaultAddress: Optional<Address>,
  poolId: Optional<string>
) => {
  const [pool, setPool] = useState<Pool | null>(null);
  useEffect(() => {
    const fetchPoolData = async () => {
      if (!vaultAddress || !poolId) return;
      const cmsPoolsResponse = (await getAllPools()).data;
      const res = cmsPoolsResponse.find(
        (thisPool) =>
          thisPool.attributes.poolId === Number(poolId) &&
          thisPool.attributes.vaultAddress === vaultAddress.toString()
      );
      if (res) setPool(res);
    };

    fetchPoolData();
  }, [vaultAddress, poolId]);
  return pool;
};
