import React from 'react';
import { IntlProvider } from 'react-intl';
import 'react-step-progress-bar/styles.css';
import './App.css';
import styled from 'styled-components';
// This works for now, but we'll have to go for a more dynamic approach later
// When we transition to the dynamic approach, we wouldn't have to worry about the rest of the app
import EN_STRINGS from 'src/compiled-lang/en.json';
import RU_STRINGS from 'src/compiled-lang/ru.json';
import ZH_STRINGS from 'src/compiled-lang/zh.json';
import KO_STRINGS from 'src/compiled-lang/ko.json';
import { SimulatorHead } from 'src/components/SimulatorHead/SimulatorHead';
import { Header } from './components/Header/Header';
import { Menu } from './components/Menu/Menu';
import { useLocalStorage } from './hooks/useLocalStorage';

const Main = styled.div``;
const Container = styled.div``;
const Page = styled.div`
  display: flex;
  margin-bottom: 5rem;

  @media only screen and (max-width: 796px) {
    flex-direction: column;
  }
`;

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'zh':
      return ZH_STRINGS;
    case 'ru':
      return RU_STRINGS;
    case 'ko':
      return KO_STRINGS;
    default:
      return EN_STRINGS;
  }
}

export const App = (): JSX.Element => {
  const [locale] = useLocalStorage('locale', 'en');

  return (
    <IntlProvider
      messages={loadLocaleData(locale)}
      locale={locale}
      defaultLocale="en"
    >
      <Main className="main">
        <Container className="container">
          <Header />
          <SimulatorHead />
          <Page>
            <Menu />
          </Page>
        </Container>
      </Main>
    </IntlProvider>
  );
};
